/* eslint-disable */

export const protobufPackage = 'permissions';

/**
 * Permission represents a permission that can be assigned to a role.
 * The first part of the prefix is the top level domain of the permissions group e.g. account, users, transactions, etc.
 * buf:lint:ignore ENUM_VALUE_PREFIX
 */
export enum Permission {
  /** INVALID - buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX */
  INVALID = 0,
  /** ACCOUNT_USERS_LIST - users */
  ACCOUNT_USERS_LIST = 10001,
  ACCOUNT_USERS_CREATE = 10002,
  ACCOUNT_USERS_EDIT = 10003,
  ACCOUNT_USERS_DELETE = 10004,
  /** ACCOUNT_TRANSACTIONS_LIST - transactions */
  ACCOUNT_TRANSACTIONS_LIST = 20005,
  ACCOUNT_TRANSACTIONS_DISBURSEMENT_CREATE = 20006,
  ACCOUNT_TRANSACTIONS_COLLECTION_CREATE = 20007,
  ACCOUNT_TRANSACTIONS_CONVERSION_CREATE = 20008,
  ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE = 20009,
  ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE_OWN = 20010,
  ACCOUNT_TRANSACTIONS_APPROVAL_TOGGLE = 20011,
  /** ACCOUNT_WALLETS_LIST - wallets */
  ACCOUNT_WALLETS_LIST = 30009,
  ACCOUNT_WALLETS_CREATE = 30010,
  ACCOUNT_WALLETS_STATEMENT_EXPORT = 30011,
  /** ACCOUNT_RECIPIENTS_LIST - recipients */
  ACCOUNT_RECIPIENTS_LIST = 40012,
  ACCOUNT_RECIPIENTS_CREATE = 40013,
  ACCOUNT_RECIPIENTS_DELETE = 40014,
  ACCOUNT_RECIPIENTS_EDIT = 40015,
  /** USERS_STAFF_TEAM_LIST - staff team */
  USERS_STAFF_TEAM_LIST = 50016,
  USERS_STAFF_TEAM_CREATE = 50017,
  USERS_STAFF_TEAM_EDIT = 50018,
  USERS_STAFF_TEAM_DELETE = 50019,
  USERS_STAFF_TEAM_ROLES_LIST = 50020,
  /** USERS_STAFF_TEAM_MEMBERS_LIST - staff team members */
  USERS_STAFF_TEAM_MEMBERS_LIST = 60021,
  USERS_STAFF_TEAM_MEMBERS_CREATE = 60022,
  USERS_STAFF_TEAM_MEMBERS_EDIT = 60023,
  USERS_STAFF_TEAM_MEMBERS_DELETE = 60024,
  UNRECOGNIZED = -1,
}

export function permissionFromJSON(object: any): Permission {
  switch (object) {
    case 0:
    case 'INVALID':
      return Permission.INVALID;
    case 10001:
    case 'ACCOUNT_USERS_LIST':
      return Permission.ACCOUNT_USERS_LIST;
    case 10002:
    case 'ACCOUNT_USERS_CREATE':
      return Permission.ACCOUNT_USERS_CREATE;
    case 10003:
    case 'ACCOUNT_USERS_EDIT':
      return Permission.ACCOUNT_USERS_EDIT;
    case 10004:
    case 'ACCOUNT_USERS_DELETE':
      return Permission.ACCOUNT_USERS_DELETE;
    case 20005:
    case 'ACCOUNT_TRANSACTIONS_LIST':
      return Permission.ACCOUNT_TRANSACTIONS_LIST;
    case 20006:
    case 'ACCOUNT_TRANSACTIONS_DISBURSEMENT_CREATE':
      return Permission.ACCOUNT_TRANSACTIONS_DISBURSEMENT_CREATE;
    case 20007:
    case 'ACCOUNT_TRANSACTIONS_COLLECTION_CREATE':
      return Permission.ACCOUNT_TRANSACTIONS_COLLECTION_CREATE;
    case 20008:
    case 'ACCOUNT_TRANSACTIONS_CONVERSION_CREATE':
      return Permission.ACCOUNT_TRANSACTIONS_CONVERSION_CREATE;
    case 20009:
    case 'ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE':
      return Permission.ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE;
    case 20010:
    case 'ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE_OWN':
      return Permission.ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE_OWN;
    case 20011:
    case 'ACCOUNT_TRANSACTIONS_APPROVAL_TOGGLE':
      return Permission.ACCOUNT_TRANSACTIONS_APPROVAL_TOGGLE;
    case 30009:
    case 'ACCOUNT_WALLETS_LIST':
      return Permission.ACCOUNT_WALLETS_LIST;
    case 30010:
    case 'ACCOUNT_WALLETS_CREATE':
      return Permission.ACCOUNT_WALLETS_CREATE;
    case 30011:
    case 'ACCOUNT_WALLETS_STATEMENT_EXPORT':
      return Permission.ACCOUNT_WALLETS_STATEMENT_EXPORT;
    case 40012:
    case 'ACCOUNT_RECIPIENTS_LIST':
      return Permission.ACCOUNT_RECIPIENTS_LIST;
    case 40013:
    case 'ACCOUNT_RECIPIENTS_CREATE':
      return Permission.ACCOUNT_RECIPIENTS_CREATE;
    case 40014:
    case 'ACCOUNT_RECIPIENTS_DELETE':
      return Permission.ACCOUNT_RECIPIENTS_DELETE;
    case 40015:
    case 'ACCOUNT_RECIPIENTS_EDIT':
      return Permission.ACCOUNT_RECIPIENTS_EDIT;
    case 50016:
    case 'USERS_STAFF_TEAM_LIST':
      return Permission.USERS_STAFF_TEAM_LIST;
    case 50017:
    case 'USERS_STAFF_TEAM_CREATE':
      return Permission.USERS_STAFF_TEAM_CREATE;
    case 50018:
    case 'USERS_STAFF_TEAM_EDIT':
      return Permission.USERS_STAFF_TEAM_EDIT;
    case 50019:
    case 'USERS_STAFF_TEAM_DELETE':
      return Permission.USERS_STAFF_TEAM_DELETE;
    case 50020:
    case 'USERS_STAFF_TEAM_ROLES_LIST':
      return Permission.USERS_STAFF_TEAM_ROLES_LIST;
    case 60021:
    case 'USERS_STAFF_TEAM_MEMBERS_LIST':
      return Permission.USERS_STAFF_TEAM_MEMBERS_LIST;
    case 60022:
    case 'USERS_STAFF_TEAM_MEMBERS_CREATE':
      return Permission.USERS_STAFF_TEAM_MEMBERS_CREATE;
    case 60023:
    case 'USERS_STAFF_TEAM_MEMBERS_EDIT':
      return Permission.USERS_STAFF_TEAM_MEMBERS_EDIT;
    case 60024:
    case 'USERS_STAFF_TEAM_MEMBERS_DELETE':
      return Permission.USERS_STAFF_TEAM_MEMBERS_DELETE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Permission.UNRECOGNIZED;
  }
}

export function permissionToJSON(object: Permission): string {
  switch (object) {
    case Permission.INVALID:
      return 'INVALID';
    case Permission.ACCOUNT_USERS_LIST:
      return 'ACCOUNT_USERS_LIST';
    case Permission.ACCOUNT_USERS_CREATE:
      return 'ACCOUNT_USERS_CREATE';
    case Permission.ACCOUNT_USERS_EDIT:
      return 'ACCOUNT_USERS_EDIT';
    case Permission.ACCOUNT_USERS_DELETE:
      return 'ACCOUNT_USERS_DELETE';
    case Permission.ACCOUNT_TRANSACTIONS_LIST:
      return 'ACCOUNT_TRANSACTIONS_LIST';
    case Permission.ACCOUNT_TRANSACTIONS_DISBURSEMENT_CREATE:
      return 'ACCOUNT_TRANSACTIONS_DISBURSEMENT_CREATE';
    case Permission.ACCOUNT_TRANSACTIONS_COLLECTION_CREATE:
      return 'ACCOUNT_TRANSACTIONS_COLLECTION_CREATE';
    case Permission.ACCOUNT_TRANSACTIONS_CONVERSION_CREATE:
      return 'ACCOUNT_TRANSACTIONS_CONVERSION_CREATE';
    case Permission.ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE:
      return 'ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE';
    case Permission.ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE_OWN:
      return 'ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE_OWN';
    case Permission.ACCOUNT_TRANSACTIONS_APPROVAL_TOGGLE:
      return 'ACCOUNT_TRANSACTIONS_APPROVAL_TOGGLE';
    case Permission.ACCOUNT_WALLETS_LIST:
      return 'ACCOUNT_WALLETS_LIST';
    case Permission.ACCOUNT_WALLETS_CREATE:
      return 'ACCOUNT_WALLETS_CREATE';
    case Permission.ACCOUNT_WALLETS_STATEMENT_EXPORT:
      return 'ACCOUNT_WALLETS_STATEMENT_EXPORT';
    case Permission.ACCOUNT_RECIPIENTS_LIST:
      return 'ACCOUNT_RECIPIENTS_LIST';
    case Permission.ACCOUNT_RECIPIENTS_CREATE:
      return 'ACCOUNT_RECIPIENTS_CREATE';
    case Permission.ACCOUNT_RECIPIENTS_DELETE:
      return 'ACCOUNT_RECIPIENTS_DELETE';
    case Permission.ACCOUNT_RECIPIENTS_EDIT:
      return 'ACCOUNT_RECIPIENTS_EDIT';
    case Permission.USERS_STAFF_TEAM_LIST:
      return 'USERS_STAFF_TEAM_LIST';
    case Permission.USERS_STAFF_TEAM_CREATE:
      return 'USERS_STAFF_TEAM_CREATE';
    case Permission.USERS_STAFF_TEAM_EDIT:
      return 'USERS_STAFF_TEAM_EDIT';
    case Permission.USERS_STAFF_TEAM_DELETE:
      return 'USERS_STAFF_TEAM_DELETE';
    case Permission.USERS_STAFF_TEAM_ROLES_LIST:
      return 'USERS_STAFF_TEAM_ROLES_LIST';
    case Permission.USERS_STAFF_TEAM_MEMBERS_LIST:
      return 'USERS_STAFF_TEAM_MEMBERS_LIST';
    case Permission.USERS_STAFF_TEAM_MEMBERS_CREATE:
      return 'USERS_STAFF_TEAM_MEMBERS_CREATE';
    case Permission.USERS_STAFF_TEAM_MEMBERS_EDIT:
      return 'USERS_STAFF_TEAM_MEMBERS_EDIT';
    case Permission.USERS_STAFF_TEAM_MEMBERS_DELETE:
      return 'USERS_STAFF_TEAM_MEMBERS_DELETE';
    case Permission.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}
