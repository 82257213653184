/* eslint-disable */
import _m0 from 'protobufjs/minimal';
import { Any } from '../../../google/protobuf/any';

export const protobufPackage = 'errors';

export enum ErrorType {
  ERROR_TYPE_INVALID = 0,
  ERROR_TYPE_MFA_SESSION_REQUIRED = 1,
  UNRECOGNIZED = -1,
}

export function errorTypeFromJSON(object: any): ErrorType {
  switch (object) {
    case 0:
    case 'ERROR_TYPE_INVALID':
      return ErrorType.ERROR_TYPE_INVALID;
    case 1:
    case 'ERROR_TYPE_MFA_SESSION_REQUIRED':
      return ErrorType.ERROR_TYPE_MFA_SESSION_REQUIRED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ErrorType.UNRECOGNIZED;
  }
}

export function errorTypeToJSON(object: ErrorType): string {
  switch (object) {
    case ErrorType.ERROR_TYPE_INVALID:
      return 'ERROR_TYPE_INVALID';
    case ErrorType.ERROR_TYPE_MFA_SESSION_REQUIRED:
      return 'ERROR_TYPE_MFA_SESSION_REQUIRED';
    case ErrorType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface Status {
  /** The status code, which should be an enum value of [google.rpc.Code][google.rpc.Code]. */
  code: number;
  /**
   * A developer-facing error message, which should be in English. Any
   * user-facing error message should be localized and sent in the
   * [google.rpc.Status.details][google.rpc.Status.details] field, or localized by the client.
   */
  message: string;
  /**
   * A list of messages that carry the error details.  There will be a
   * common set of message types for APIs to use.
   */
  details: Any[];
}

export interface ErrorDetails {
  errorType: ErrorType;
}

function createBaseStatus(): Status {
  return { code: 0, message: '', details: [] };
}

export const Status = {
  encode(
    message: Status,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.details) {
      Any.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Status {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.details.push(Any.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Status {
    return {
      code: isSet(object.code) ? Number(object.code) : 0,
      message: isSet(object.message) ? String(object.message) : '',
      details: Array.isArray(object?.details)
        ? object.details.map((e: any) => Any.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Status): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = Math.round(message.code));
    message.message !== undefined && (obj.message = message.message);
    if (message.details) {
      obj.details = message.details.map((e) => (e ? Any.toJSON(e) : undefined));
    } else {
      obj.details = [];
    }
    return obj;
  },

  create(base?: DeepPartial<Status>): Status {
    return Status.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Status>): Status {
    const message = createBaseStatus();
    message.code = object.code ?? 0;
    message.message = object.message ?? '';
    message.details = object.details?.map((e) => Any.fromPartial(e)) || [];
    return message;
  },
};

function createBaseErrorDetails(): ErrorDetails {
  return { errorType: 0 };
}

export const ErrorDetails = {
  encode(
    message: ErrorDetails,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.errorType !== 0) {
      writer.uint32(8).int32(message.errorType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ErrorDetails {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseErrorDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.errorType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ErrorDetails {
    return {
      errorType: isSet(object.errorType)
        ? errorTypeFromJSON(object.errorType)
        : 0,
    };
  },

  toJSON(message: ErrorDetails): unknown {
    const obj: any = {};
    message.errorType !== undefined &&
      (obj.errorType = errorTypeToJSON(message.errorType));
    return obj;
  },

  create(base?: DeepPartial<ErrorDetails>): ErrorDetails {
    return ErrorDetails.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ErrorDetails>): ErrorDetails {
    const message = createBaseErrorDetails();
    message.errorType = object.errorType ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
