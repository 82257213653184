/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Timestamp } from '../../../google/protobuf/timestamp';
import {
  PhoneOperator,
  phoneOperatorFromJSON,
  phoneOperatorToJSON,
} from '../disbursements/types';
import { Currency } from '../fx/types';

export const protobufPackage = 'config';

/** buf:lint:ignore COMMENT_ENUM */
export enum SendOptionAvailability {
  SEND_OPTION_AVAILABILITY_INVALID = 0,
  SEND_OPTION_AVAILABILITY_COMING_SOON = 1,
  SEND_OPTION_AVAILABILITY_NEW = 2,
  SEND_OPTION_AVAILABILITY_AVAILABLE = 3,
  UNRECOGNIZED = -1,
}

export function sendOptionAvailabilityFromJSON(
  object: any,
): SendOptionAvailability {
  switch (object) {
    case 0:
    case 'SEND_OPTION_AVAILABILITY_INVALID':
      return SendOptionAvailability.SEND_OPTION_AVAILABILITY_INVALID;
    case 1:
    case 'SEND_OPTION_AVAILABILITY_COMING_SOON':
      return SendOptionAvailability.SEND_OPTION_AVAILABILITY_COMING_SOON;
    case 2:
    case 'SEND_OPTION_AVAILABILITY_NEW':
      return SendOptionAvailability.SEND_OPTION_AVAILABILITY_NEW;
    case 3:
    case 'SEND_OPTION_AVAILABILITY_AVAILABLE':
      return SendOptionAvailability.SEND_OPTION_AVAILABILITY_AVAILABLE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SendOptionAvailability.UNRECOGNIZED;
  }
}

export function sendOptionAvailabilityToJSON(
  object: SendOptionAvailability,
): string {
  switch (object) {
    case SendOptionAvailability.SEND_OPTION_AVAILABILITY_INVALID:
      return 'SEND_OPTION_AVAILABILITY_INVALID';
    case SendOptionAvailability.SEND_OPTION_AVAILABILITY_COMING_SOON:
      return 'SEND_OPTION_AVAILABILITY_COMING_SOON';
    case SendOptionAvailability.SEND_OPTION_AVAILABILITY_NEW:
      return 'SEND_OPTION_AVAILABILITY_NEW';
    case SendOptionAvailability.SEND_OPTION_AVAILABILITY_AVAILABLE:
      return 'SEND_OPTION_AVAILABILITY_AVAILABLE';
    case SendOptionAvailability.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum SendOptionStatus {
  SEND_OPTION_STATUS_INVALID = 0,
  SEND_OPTION_STATUS_DISABLED = 1,
  SEND_OPTION_STATUS_TEMPORARILY_DISABLED = 2,
  SEND_OPTION_STATUS_ENABLED = 3,
  UNRECOGNIZED = -1,
}

export function sendOptionStatusFromJSON(object: any): SendOptionStatus {
  switch (object) {
    case 0:
    case 'SEND_OPTION_STATUS_INVALID':
      return SendOptionStatus.SEND_OPTION_STATUS_INVALID;
    case 1:
    case 'SEND_OPTION_STATUS_DISABLED':
      return SendOptionStatus.SEND_OPTION_STATUS_DISABLED;
    case 2:
    case 'SEND_OPTION_STATUS_TEMPORARILY_DISABLED':
      return SendOptionStatus.SEND_OPTION_STATUS_TEMPORARILY_DISABLED;
    case 3:
    case 'SEND_OPTION_STATUS_ENABLED':
      return SendOptionStatus.SEND_OPTION_STATUS_ENABLED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SendOptionStatus.UNRECOGNIZED;
  }
}

export function sendOptionStatusToJSON(object: SendOptionStatus): string {
  switch (object) {
    case SendOptionStatus.SEND_OPTION_STATUS_INVALID:
      return 'SEND_OPTION_STATUS_INVALID';
    case SendOptionStatus.SEND_OPTION_STATUS_DISABLED:
      return 'SEND_OPTION_STATUS_DISABLED';
    case SendOptionStatus.SEND_OPTION_STATUS_TEMPORARILY_DISABLED:
      return 'SEND_OPTION_STATUS_TEMPORARILY_DISABLED';
    case SendOptionStatus.SEND_OPTION_STATUS_ENABLED:
      return 'SEND_OPTION_STATUS_ENABLED';
    case SendOptionStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum SendOptionType {
  SEND_OPTION_TYPE_INVALID = 0,
  SEND_OPTION_TYPE_MOBILE_MONEY = 1,
  SEND_OPTION_TYPE_BANK_ACCOUNT = 2,
  SEND_OPTION_TYPE_BUY_GOODS = 3,
  SEND_OPTION_TYPE_PAY_BILL = 4,
  SEND_OPTION_TYPE_P2P = 5,
  SEND_OPTION_TYPE_AIRTIME = 6,
  SEND_OPTION_TYPE_BANK_ACCOUNT_WITH_REFERENCE = 7,
  SEND_OPTION_TYPE_OWN_BANK_ACCOUNT = 8,
  SEND_OPTION_TYPE_EXTERNAL_ACCOUNT_WAVE = 9,
  UNRECOGNIZED = -1,
}

export function sendOptionTypeFromJSON(object: any): SendOptionType {
  switch (object) {
    case 0:
    case 'SEND_OPTION_TYPE_INVALID':
      return SendOptionType.SEND_OPTION_TYPE_INVALID;
    case 1:
    case 'SEND_OPTION_TYPE_MOBILE_MONEY':
      return SendOptionType.SEND_OPTION_TYPE_MOBILE_MONEY;
    case 2:
    case 'SEND_OPTION_TYPE_BANK_ACCOUNT':
      return SendOptionType.SEND_OPTION_TYPE_BANK_ACCOUNT;
    case 3:
    case 'SEND_OPTION_TYPE_BUY_GOODS':
      return SendOptionType.SEND_OPTION_TYPE_BUY_GOODS;
    case 4:
    case 'SEND_OPTION_TYPE_PAY_BILL':
      return SendOptionType.SEND_OPTION_TYPE_PAY_BILL;
    case 5:
    case 'SEND_OPTION_TYPE_P2P':
      return SendOptionType.SEND_OPTION_TYPE_P2P;
    case 6:
    case 'SEND_OPTION_TYPE_AIRTIME':
      return SendOptionType.SEND_OPTION_TYPE_AIRTIME;
    case 7:
    case 'SEND_OPTION_TYPE_BANK_ACCOUNT_WITH_REFERENCE':
      return SendOptionType.SEND_OPTION_TYPE_BANK_ACCOUNT_WITH_REFERENCE;
    case 8:
    case 'SEND_OPTION_TYPE_OWN_BANK_ACCOUNT':
      return SendOptionType.SEND_OPTION_TYPE_OWN_BANK_ACCOUNT;
    case 9:
    case 'SEND_OPTION_TYPE_EXTERNAL_ACCOUNT_WAVE':
      return SendOptionType.SEND_OPTION_TYPE_EXTERNAL_ACCOUNT_WAVE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SendOptionType.UNRECOGNIZED;
  }
}

export function sendOptionTypeToJSON(object: SendOptionType): string {
  switch (object) {
    case SendOptionType.SEND_OPTION_TYPE_INVALID:
      return 'SEND_OPTION_TYPE_INVALID';
    case SendOptionType.SEND_OPTION_TYPE_MOBILE_MONEY:
      return 'SEND_OPTION_TYPE_MOBILE_MONEY';
    case SendOptionType.SEND_OPTION_TYPE_BANK_ACCOUNT:
      return 'SEND_OPTION_TYPE_BANK_ACCOUNT';
    case SendOptionType.SEND_OPTION_TYPE_BUY_GOODS:
      return 'SEND_OPTION_TYPE_BUY_GOODS';
    case SendOptionType.SEND_OPTION_TYPE_PAY_BILL:
      return 'SEND_OPTION_TYPE_PAY_BILL';
    case SendOptionType.SEND_OPTION_TYPE_P2P:
      return 'SEND_OPTION_TYPE_P2P';
    case SendOptionType.SEND_OPTION_TYPE_AIRTIME:
      return 'SEND_OPTION_TYPE_AIRTIME';
    case SendOptionType.SEND_OPTION_TYPE_BANK_ACCOUNT_WITH_REFERENCE:
      return 'SEND_OPTION_TYPE_BANK_ACCOUNT_WITH_REFERENCE';
    case SendOptionType.SEND_OPTION_TYPE_OWN_BANK_ACCOUNT:
      return 'SEND_OPTION_TYPE_OWN_BANK_ACCOUNT';
    case SendOptionType.SEND_OPTION_TYPE_EXTERNAL_ACCOUNT_WAVE:
      return 'SEND_OPTION_TYPE_EXTERNAL_ACCOUNT_WAVE';
    case SendOptionType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface ConfigValue {
  id: number;
  key: string;
  value: string;
  staffId: string;
  createdAt: Timestamp | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface PhoneOperatorPrefixes {
  prefixes: { [key: string]: PhoneOperator };
}

export interface PhoneOperatorPrefixes_PrefixesEntry {
  key: string;
  value: PhoneOperator;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface EnabledOperators {
  operators: PhoneOperator[];
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface AdditionalRecipientData {
  data: string[];
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface RecipientCountries {
  countries: string[];
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Currencies {
  currencies: string[];
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface CountriesConfig {
  /**
   * Legacy
   *
   * @deprecated
   */
  deprecatedDefaultSenderCountry: string;
  /** @deprecated */
  deprecatedDefaultRecipientCountry: string;
  /** @deprecated */
  deprecatedSenderCountries: string[];
  /** @deprecated */
  deprecatedRecipientCountries: string[];
  /** @deprecated */
  deprecatedPhoneOperatorPrefixes: { [key: string]: PhoneOperatorPrefixes };
  /** @deprecated */
  deprecatedEnabledPhoneOperators: { [key: string]: EnabledOperators };
  /** @deprecated */
  deprecatedAdditionalRecipientData: { [key: string]: AdditionalRecipientData };
  /** @deprecated */
  deprecatedBankTransfersEnabled: { [key: string]: boolean };
  /** New config */
  senderCountries: SenderCountry[];
  recipientCountries: RecipientCountry[];
  countries: { [key: string]: Country };
  currencies: { [key: string]: Currency };
  /**
   * the list of countries mapped up with each operator
   * supported by gRPC lookup.GetPhoneNumberMetadata
   */
  enabledForPhoneLookups: { [key: string]: EnabledOperators };
  defaultRecipientCountry: string;
}

export interface CountriesConfig_DeprecatedPhoneOperatorPrefixesEntry {
  key: string;
  value: PhoneOperatorPrefixes | undefined;
}

export interface CountriesConfig_DeprecatedEnabledPhoneOperatorsEntry {
  key: string;
  value: EnabledOperators | undefined;
}

export interface CountriesConfig_DeprecatedAdditionalRecipientDataEntry {
  key: string;
  value: AdditionalRecipientData | undefined;
}

export interface CountriesConfig_DeprecatedBankTransfersEnabledEntry {
  key: string;
  value: boolean;
}

export interface CountriesConfig_CountriesEntry {
  key: string;
  value: Country | undefined;
}

export interface CountriesConfig_CurrenciesEntry {
  key: string;
  value: Currency | undefined;
}

export interface CountriesConfig_EnabledForPhoneLookupsEntry {
  key: string;
  value: EnabledOperators | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface SenderCountry {
  /** Alpha-2 code */
  code: string;
  currencies: string[];
  recipientCountries: string[];
  allowedPhoneNumberCountries: string[];
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface RecipientCountry {
  /** Alpha-2 code */
  code: string;
  currency: string;
  additionalRecipientData: string[];
  /** Bank Accounts */
  bankTransfersEnabled: boolean;
  /** Mobile Money */
  phoneOperatorPrefixes: { [key: string]: PhoneOperator };
  enabledPhoneOperators: PhoneOperator[];
  bankTransfersSupportCustomReferences: boolean;
  /**
   * payout_factor returns the required payout factor for recipient currency e.g some countries that only accept amounts in
   * multiple of 5 we set payout_factor value to 5.
   */
  payoutFactor: number;
}

export interface RecipientCountry_PhoneOperatorPrefixesEntry {
  key: string;
  value: PhoneOperator;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Country {
  phoneCode: string;
  defaultCurrency: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface ABTestingCampaign {
  feature: string;
  ratioConfig: ABTestingRatioConfig | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface ABTestingRatioConfig {
  isDynamic: boolean;
  percentage: number;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface SendOption {
  name: string;
  description: string;
  type: SendOptionType;
  availability: SendOptionAvailability;
  status: SendOptionStatus;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface SendOptions {
  sendOptions: SendOption[];
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface SendOptionBanner {
  title: string;
  subTitle: string;
  action: SendOptionBanner_Action;
  modal?: SendOptionBanner_Modal | undefined;
  url?: string | undefined;
}

/** buf:lint:ignore COMMENT_ENUM */
export enum SendOptionBanner_Action {
  ACTION_INVALID = 0,
  ACTION_DESCRIPTION_MODAL = 1,
  ACTION_OPEN_URL = 2,
  UNRECOGNIZED = -1,
}

export function sendOptionBanner_ActionFromJSON(
  object: any,
): SendOptionBanner_Action {
  switch (object) {
    case 0:
    case 'ACTION_INVALID':
      return SendOptionBanner_Action.ACTION_INVALID;
    case 1:
    case 'ACTION_DESCRIPTION_MODAL':
      return SendOptionBanner_Action.ACTION_DESCRIPTION_MODAL;
    case 2:
    case 'ACTION_OPEN_URL':
      return SendOptionBanner_Action.ACTION_OPEN_URL;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SendOptionBanner_Action.UNRECOGNIZED;
  }
}

export function sendOptionBanner_ActionToJSON(
  object: SendOptionBanner_Action,
): string {
  switch (object) {
    case SendOptionBanner_Action.ACTION_INVALID:
      return 'ACTION_INVALID';
    case SendOptionBanner_Action.ACTION_DESCRIPTION_MODAL:
      return 'ACTION_DESCRIPTION_MODAL';
    case SendOptionBanner_Action.ACTION_OPEN_URL:
      return 'ACTION_OPEN_URL';
    case SendOptionBanner_Action.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface SendOptionBanner_Modal {
  title: string;
  content: string;
  mamaNalaLink: boolean;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface SendOptionBanners {
  banners: SendOptionBanner[];
}

function createBaseConfigValue(): ConfigValue {
  return { id: 0, key: '', value: '', staffId: '', createdAt: undefined };
}

export const ConfigValue = {
  encode(
    message: ConfigValue,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.key !== '') {
      writer.uint32(18).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(26).string(message.value);
    }
    if (message.staffId !== '') {
      writer.uint32(34).string(message.staffId);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConfigValue {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.key = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.staffId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfigValue {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
      staffId: isSet(object.staffId) ? String(object.staffId) : '',
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
    };
  },

  toJSON(message: ConfigValue): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    message.staffId !== undefined && (obj.staffId = message.staffId);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    return obj;
  },

  create(base?: DeepPartial<ConfigValue>): ConfigValue {
    return ConfigValue.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ConfigValue>): ConfigValue {
    const message = createBaseConfigValue();
    message.id = object.id ?? 0;
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    message.staffId = object.staffId ?? '';
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    return message;
  },
};

function createBasePhoneOperatorPrefixes(): PhoneOperatorPrefixes {
  return { prefixes: {} };
}

export const PhoneOperatorPrefixes = {
  encode(
    message: PhoneOperatorPrefixes,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    Object.entries(message.prefixes).forEach(([key, value]) => {
      PhoneOperatorPrefixes_PrefixesEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PhoneOperatorPrefixes {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePhoneOperatorPrefixes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = PhoneOperatorPrefixes_PrefixesEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry1.value !== undefined) {
            message.prefixes[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PhoneOperatorPrefixes {
    return {
      prefixes: isObject(object.prefixes)
        ? Object.entries(object.prefixes).reduce<{
            [key: string]: PhoneOperator;
          }>((acc, [key, value]) => {
            acc[key] = phoneOperatorFromJSON(value);
            return acc;
          }, {})
        : {},
    };
  },

  toJSON(message: PhoneOperatorPrefixes): unknown {
    const obj: any = {};
    obj.prefixes = {};
    if (message.prefixes) {
      Object.entries(message.prefixes).forEach(([k, v]) => {
        obj.prefixes[k] = phoneOperatorToJSON(v);
      });
    }
    return obj;
  },

  create(base?: DeepPartial<PhoneOperatorPrefixes>): PhoneOperatorPrefixes {
    return PhoneOperatorPrefixes.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<PhoneOperatorPrefixes>,
  ): PhoneOperatorPrefixes {
    const message = createBasePhoneOperatorPrefixes();
    message.prefixes = Object.entries(object.prefixes ?? {}).reduce<{
      [key: string]: PhoneOperator;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value as PhoneOperator;
      }
      return acc;
    }, {});
    return message;
  },
};

function createBasePhoneOperatorPrefixes_PrefixesEntry(): PhoneOperatorPrefixes_PrefixesEntry {
  return { key: '', value: 0 };
}

export const PhoneOperatorPrefixes_PrefixesEntry = {
  encode(
    message: PhoneOperatorPrefixes_PrefixesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int32(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PhoneOperatorPrefixes_PrefixesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePhoneOperatorPrefixes_PrefixesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PhoneOperatorPrefixes_PrefixesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? phoneOperatorFromJSON(object.value) : 0,
    };
  },

  toJSON(message: PhoneOperatorPrefixes_PrefixesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = phoneOperatorToJSON(message.value));
    return obj;
  },

  create(
    base?: DeepPartial<PhoneOperatorPrefixes_PrefixesEntry>,
  ): PhoneOperatorPrefixes_PrefixesEntry {
    return PhoneOperatorPrefixes_PrefixesEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<PhoneOperatorPrefixes_PrefixesEntry>,
  ): PhoneOperatorPrefixes_PrefixesEntry {
    const message = createBasePhoneOperatorPrefixes_PrefixesEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseEnabledOperators(): EnabledOperators {
  return { operators: [] };
}

export const EnabledOperators = {
  encode(
    message: EnabledOperators,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.operators) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnabledOperators {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnabledOperators();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.operators.push(reader.int32() as any);

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.operators.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnabledOperators {
    return {
      operators: Array.isArray(object?.operators)
        ? object.operators.map((e: any) => phoneOperatorFromJSON(e))
        : [],
    };
  },

  toJSON(message: EnabledOperators): unknown {
    const obj: any = {};
    if (message.operators) {
      obj.operators = message.operators.map((e) => phoneOperatorToJSON(e));
    } else {
      obj.operators = [];
    }
    return obj;
  },

  create(base?: DeepPartial<EnabledOperators>): EnabledOperators {
    return EnabledOperators.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<EnabledOperators>): EnabledOperators {
    const message = createBaseEnabledOperators();
    message.operators = object.operators?.map((e) => e) || [];
    return message;
  },
};

function createBaseAdditionalRecipientData(): AdditionalRecipientData {
  return { data: [] };
}

export const AdditionalRecipientData = {
  encode(
    message: AdditionalRecipientData,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.data) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AdditionalRecipientData {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdditionalRecipientData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdditionalRecipientData {
    return {
      data: Array.isArray(object?.data)
        ? object.data.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: AdditionalRecipientData): unknown {
    const obj: any = {};
    if (message.data) {
      obj.data = message.data.map((e) => e);
    } else {
      obj.data = [];
    }
    return obj;
  },

  create(base?: DeepPartial<AdditionalRecipientData>): AdditionalRecipientData {
    return AdditionalRecipientData.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<AdditionalRecipientData>,
  ): AdditionalRecipientData {
    const message = createBaseAdditionalRecipientData();
    message.data = object.data?.map((e) => e) || [];
    return message;
  },
};

function createBaseRecipientCountries(): RecipientCountries {
  return { countries: [] };
}

export const RecipientCountries = {
  encode(
    message: RecipientCountries,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.countries) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RecipientCountries {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecipientCountries();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.countries.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecipientCountries {
    return {
      countries: Array.isArray(object?.countries)
        ? object.countries.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: RecipientCountries): unknown {
    const obj: any = {};
    if (message.countries) {
      obj.countries = message.countries.map((e) => e);
    } else {
      obj.countries = [];
    }
    return obj;
  },

  create(base?: DeepPartial<RecipientCountries>): RecipientCountries {
    return RecipientCountries.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<RecipientCountries>): RecipientCountries {
    const message = createBaseRecipientCountries();
    message.countries = object.countries?.map((e) => e) || [];
    return message;
  },
};

function createBaseCurrencies(): Currencies {
  return { currencies: [] };
}

export const Currencies = {
  encode(
    message: Currencies,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.currencies) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Currencies {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencies();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.currencies.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Currencies {
    return {
      currencies: Array.isArray(object?.currencies)
        ? object.currencies.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: Currencies): unknown {
    const obj: any = {};
    if (message.currencies) {
      obj.currencies = message.currencies.map((e) => e);
    } else {
      obj.currencies = [];
    }
    return obj;
  },

  create(base?: DeepPartial<Currencies>): Currencies {
    return Currencies.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Currencies>): Currencies {
    const message = createBaseCurrencies();
    message.currencies = object.currencies?.map((e) => e) || [];
    return message;
  },
};

function createBaseCountriesConfig(): CountriesConfig {
  return {
    deprecatedDefaultSenderCountry: '',
    deprecatedDefaultRecipientCountry: '',
    deprecatedSenderCountries: [],
    deprecatedRecipientCountries: [],
    deprecatedPhoneOperatorPrefixes: {},
    deprecatedEnabledPhoneOperators: {},
    deprecatedAdditionalRecipientData: {},
    deprecatedBankTransfersEnabled: {},
    senderCountries: [],
    recipientCountries: [],
    countries: {},
    currencies: {},
    enabledForPhoneLookups: {},
    defaultRecipientCountry: '',
  };
}

export const CountriesConfig = {
  encode(
    message: CountriesConfig,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.deprecatedDefaultSenderCountry !== '') {
      writer.uint32(10).string(message.deprecatedDefaultSenderCountry);
    }
    if (message.deprecatedDefaultRecipientCountry !== '') {
      writer.uint32(18).string(message.deprecatedDefaultRecipientCountry);
    }
    for (const v of message.deprecatedSenderCountries) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.deprecatedRecipientCountries) {
      writer.uint32(34).string(v!);
    }
    Object.entries(message.deprecatedPhoneOperatorPrefixes).forEach(
      ([key, value]) => {
        CountriesConfig_DeprecatedPhoneOperatorPrefixesEntry.encode(
          { key: key as any, value },
          writer.uint32(42).fork(),
        ).ldelim();
      },
    );
    Object.entries(message.deprecatedEnabledPhoneOperators).forEach(
      ([key, value]) => {
        CountriesConfig_DeprecatedEnabledPhoneOperatorsEntry.encode(
          { key: key as any, value },
          writer.uint32(50).fork(),
        ).ldelim();
      },
    );
    Object.entries(message.deprecatedAdditionalRecipientData).forEach(
      ([key, value]) => {
        CountriesConfig_DeprecatedAdditionalRecipientDataEntry.encode(
          { key: key as any, value },
          writer.uint32(58).fork(),
        ).ldelim();
      },
    );
    Object.entries(message.deprecatedBankTransfersEnabled).forEach(
      ([key, value]) => {
        CountriesConfig_DeprecatedBankTransfersEnabledEntry.encode(
          { key: key as any, value },
          writer.uint32(66).fork(),
        ).ldelim();
      },
    );
    for (const v of message.senderCountries) {
      SenderCountry.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.recipientCountries) {
      RecipientCountry.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    Object.entries(message.countries).forEach(([key, value]) => {
      CountriesConfig_CountriesEntry.encode(
        { key: key as any, value },
        writer.uint32(90).fork(),
      ).ldelim();
    });
    Object.entries(message.currencies).forEach(([key, value]) => {
      CountriesConfig_CurrenciesEntry.encode(
        { key: key as any, value },
        writer.uint32(98).fork(),
      ).ldelim();
    });
    Object.entries(message.enabledForPhoneLookups).forEach(([key, value]) => {
      CountriesConfig_EnabledForPhoneLookupsEntry.encode(
        { key: key as any, value },
        writer.uint32(106).fork(),
      ).ldelim();
    });
    if (message.defaultRecipientCountry !== '') {
      writer.uint32(114).string(message.defaultRecipientCountry);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CountriesConfig {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountriesConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deprecatedDefaultSenderCountry = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deprecatedDefaultRecipientCountry = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deprecatedSenderCountries.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deprecatedRecipientCountries.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 =
            CountriesConfig_DeprecatedPhoneOperatorPrefixesEntry.decode(
              reader,
              reader.uint32(),
            );
          if (entry5.value !== undefined) {
            message.deprecatedPhoneOperatorPrefixes[entry5.key] = entry5.value;
          }
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          const entry6 =
            CountriesConfig_DeprecatedEnabledPhoneOperatorsEntry.decode(
              reader,
              reader.uint32(),
            );
          if (entry6.value !== undefined) {
            message.deprecatedEnabledPhoneOperators[entry6.key] = entry6.value;
          }
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 =
            CountriesConfig_DeprecatedAdditionalRecipientDataEntry.decode(
              reader,
              reader.uint32(),
            );
          if (entry7.value !== undefined) {
            message.deprecatedAdditionalRecipientData[entry7.key] =
              entry7.value;
          }
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 =
            CountriesConfig_DeprecatedBankTransfersEnabledEntry.decode(
              reader,
              reader.uint32(),
            );
          if (entry8.value !== undefined) {
            message.deprecatedBankTransfersEnabled[entry8.key] = entry8.value;
          }
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.senderCountries.push(
            SenderCountry.decode(reader, reader.uint32()),
          );
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.recipientCountries.push(
            RecipientCountry.decode(reader, reader.uint32()),
          );
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          const entry11 = CountriesConfig_CountriesEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry11.value !== undefined) {
            message.countries[entry11.key] = entry11.value;
          }
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          const entry12 = CountriesConfig_CurrenciesEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry12.value !== undefined) {
            message.currencies[entry12.key] = entry12.value;
          }
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          const entry13 = CountriesConfig_EnabledForPhoneLookupsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry13.value !== undefined) {
            message.enabledForPhoneLookups[entry13.key] = entry13.value;
          }
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.defaultRecipientCountry = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountriesConfig {
    return {
      deprecatedDefaultSenderCountry: isSet(
        object.deprecatedDefaultSenderCountry,
      )
        ? String(object.deprecatedDefaultSenderCountry)
        : '',
      deprecatedDefaultRecipientCountry: isSet(
        object.deprecatedDefaultRecipientCountry,
      )
        ? String(object.deprecatedDefaultRecipientCountry)
        : '',
      deprecatedSenderCountries: Array.isArray(
        object?.deprecatedSenderCountries,
      )
        ? object.deprecatedSenderCountries.map((e: any) => String(e))
        : [],
      deprecatedRecipientCountries: Array.isArray(
        object?.deprecatedRecipientCountries,
      )
        ? object.deprecatedRecipientCountries.map((e: any) => String(e))
        : [],
      deprecatedPhoneOperatorPrefixes: isObject(
        object.deprecatedPhoneOperatorPrefixes,
      )
        ? Object.entries(object.deprecatedPhoneOperatorPrefixes).reduce<{
            [key: string]: PhoneOperatorPrefixes;
          }>((acc, [key, value]) => {
            acc[key] = PhoneOperatorPrefixes.fromJSON(value);
            return acc;
          }, {})
        : {},
      deprecatedEnabledPhoneOperators: isObject(
        object.deprecatedEnabledPhoneOperators,
      )
        ? Object.entries(object.deprecatedEnabledPhoneOperators).reduce<{
            [key: string]: EnabledOperators;
          }>((acc, [key, value]) => {
            acc[key] = EnabledOperators.fromJSON(value);
            return acc;
          }, {})
        : {},
      deprecatedAdditionalRecipientData: isObject(
        object.deprecatedAdditionalRecipientData,
      )
        ? Object.entries(object.deprecatedAdditionalRecipientData).reduce<{
            [key: string]: AdditionalRecipientData;
          }>((acc, [key, value]) => {
            acc[key] = AdditionalRecipientData.fromJSON(value);
            return acc;
          }, {})
        : {},
      deprecatedBankTransfersEnabled: isObject(
        object.deprecatedBankTransfersEnabled,
      )
        ? Object.entries(object.deprecatedBankTransfersEnabled).reduce<{
            [key: string]: boolean;
          }>((acc, [key, value]) => {
            acc[key] = Boolean(value);
            return acc;
          }, {})
        : {},
      senderCountries: Array.isArray(object?.senderCountries)
        ? object.senderCountries.map((e: any) => SenderCountry.fromJSON(e))
        : [],
      recipientCountries: Array.isArray(object?.recipientCountries)
        ? object.recipientCountries.map((e: any) =>
            RecipientCountry.fromJSON(e),
          )
        : [],
      countries: isObject(object.countries)
        ? Object.entries(object.countries).reduce<{ [key: string]: Country }>(
            (acc, [key, value]) => {
              acc[key] = Country.fromJSON(value);
              return acc;
            },
            {},
          )
        : {},
      currencies: isObject(object.currencies)
        ? Object.entries(object.currencies).reduce<{ [key: string]: Currency }>(
            (acc, [key, value]) => {
              acc[key] = Currency.fromJSON(value);
              return acc;
            },
            {},
          )
        : {},
      enabledForPhoneLookups: isObject(object.enabledForPhoneLookups)
        ? Object.entries(object.enabledForPhoneLookups).reduce<{
            [key: string]: EnabledOperators;
          }>((acc, [key, value]) => {
            acc[key] = EnabledOperators.fromJSON(value);
            return acc;
          }, {})
        : {},
      defaultRecipientCountry: isSet(object.defaultRecipientCountry)
        ? String(object.defaultRecipientCountry)
        : '',
    };
  },

  toJSON(message: CountriesConfig): unknown {
    const obj: any = {};
    message.deprecatedDefaultSenderCountry !== undefined &&
      (obj.deprecatedDefaultSenderCountry =
        message.deprecatedDefaultSenderCountry);
    message.deprecatedDefaultRecipientCountry !== undefined &&
      (obj.deprecatedDefaultRecipientCountry =
        message.deprecatedDefaultRecipientCountry);
    if (message.deprecatedSenderCountries) {
      obj.deprecatedSenderCountries = message.deprecatedSenderCountries.map(
        (e) => e,
      );
    } else {
      obj.deprecatedSenderCountries = [];
    }
    if (message.deprecatedRecipientCountries) {
      obj.deprecatedRecipientCountries =
        message.deprecatedRecipientCountries.map((e) => e);
    } else {
      obj.deprecatedRecipientCountries = [];
    }
    obj.deprecatedPhoneOperatorPrefixes = {};
    if (message.deprecatedPhoneOperatorPrefixes) {
      Object.entries(message.deprecatedPhoneOperatorPrefixes).forEach(
        ([k, v]) => {
          obj.deprecatedPhoneOperatorPrefixes[k] =
            PhoneOperatorPrefixes.toJSON(v);
        },
      );
    }
    obj.deprecatedEnabledPhoneOperators = {};
    if (message.deprecatedEnabledPhoneOperators) {
      Object.entries(message.deprecatedEnabledPhoneOperators).forEach(
        ([k, v]) => {
          obj.deprecatedEnabledPhoneOperators[k] = EnabledOperators.toJSON(v);
        },
      );
    }
    obj.deprecatedAdditionalRecipientData = {};
    if (message.deprecatedAdditionalRecipientData) {
      Object.entries(message.deprecatedAdditionalRecipientData).forEach(
        ([k, v]) => {
          obj.deprecatedAdditionalRecipientData[k] =
            AdditionalRecipientData.toJSON(v);
        },
      );
    }
    obj.deprecatedBankTransfersEnabled = {};
    if (message.deprecatedBankTransfersEnabled) {
      Object.entries(message.deprecatedBankTransfersEnabled).forEach(
        ([k, v]) => {
          obj.deprecatedBankTransfersEnabled[k] = v;
        },
      );
    }
    if (message.senderCountries) {
      obj.senderCountries = message.senderCountries.map((e) =>
        e ? SenderCountry.toJSON(e) : undefined,
      );
    } else {
      obj.senderCountries = [];
    }
    if (message.recipientCountries) {
      obj.recipientCountries = message.recipientCountries.map((e) =>
        e ? RecipientCountry.toJSON(e) : undefined,
      );
    } else {
      obj.recipientCountries = [];
    }
    obj.countries = {};
    if (message.countries) {
      Object.entries(message.countries).forEach(([k, v]) => {
        obj.countries[k] = Country.toJSON(v);
      });
    }
    obj.currencies = {};
    if (message.currencies) {
      Object.entries(message.currencies).forEach(([k, v]) => {
        obj.currencies[k] = Currency.toJSON(v);
      });
    }
    obj.enabledForPhoneLookups = {};
    if (message.enabledForPhoneLookups) {
      Object.entries(message.enabledForPhoneLookups).forEach(([k, v]) => {
        obj.enabledForPhoneLookups[k] = EnabledOperators.toJSON(v);
      });
    }
    message.defaultRecipientCountry !== undefined &&
      (obj.defaultRecipientCountry = message.defaultRecipientCountry);
    return obj;
  },

  create(base?: DeepPartial<CountriesConfig>): CountriesConfig {
    return CountriesConfig.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<CountriesConfig>): CountriesConfig {
    const message = createBaseCountriesConfig();
    message.deprecatedDefaultSenderCountry =
      object.deprecatedDefaultSenderCountry ?? '';
    message.deprecatedDefaultRecipientCountry =
      object.deprecatedDefaultRecipientCountry ?? '';
    message.deprecatedSenderCountries =
      object.deprecatedSenderCountries?.map((e) => e) || [];
    message.deprecatedRecipientCountries =
      object.deprecatedRecipientCountries?.map((e) => e) || [];
    message.deprecatedPhoneOperatorPrefixes = Object.entries(
      object.deprecatedPhoneOperatorPrefixes ?? {},
    ).reduce<{ [key: string]: PhoneOperatorPrefixes }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = PhoneOperatorPrefixes.fromPartial(value);
      }
      return acc;
    }, {});
    message.deprecatedEnabledPhoneOperators = Object.entries(
      object.deprecatedEnabledPhoneOperators ?? {},
    ).reduce<{ [key: string]: EnabledOperators }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = EnabledOperators.fromPartial(value);
      }
      return acc;
    }, {});
    message.deprecatedAdditionalRecipientData = Object.entries(
      object.deprecatedAdditionalRecipientData ?? {},
    ).reduce<{ [key: string]: AdditionalRecipientData }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = AdditionalRecipientData.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.deprecatedBankTransfersEnabled = Object.entries(
      object.deprecatedBankTransfersEnabled ?? {},
    ).reduce<{ [key: string]: boolean }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Boolean(value);
      }
      return acc;
    }, {});
    message.senderCountries =
      object.senderCountries?.map((e) => SenderCountry.fromPartial(e)) || [];
    message.recipientCountries =
      object.recipientCountries?.map((e) => RecipientCountry.fromPartial(e)) ||
      [];
    message.countries = Object.entries(object.countries ?? {}).reduce<{
      [key: string]: Country;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Country.fromPartial(value);
      }
      return acc;
    }, {});
    message.currencies = Object.entries(object.currencies ?? {}).reduce<{
      [key: string]: Currency;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Currency.fromPartial(value);
      }
      return acc;
    }, {});
    message.enabledForPhoneLookups = Object.entries(
      object.enabledForPhoneLookups ?? {},
    ).reduce<{ [key: string]: EnabledOperators }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = EnabledOperators.fromPartial(value);
      }
      return acc;
    }, {});
    message.defaultRecipientCountry = object.defaultRecipientCountry ?? '';
    return message;
  },
};

function createBaseCountriesConfig_DeprecatedPhoneOperatorPrefixesEntry(): CountriesConfig_DeprecatedPhoneOperatorPrefixesEntry {
  return { key: '', value: undefined };
}

export const CountriesConfig_DeprecatedPhoneOperatorPrefixesEntry = {
  encode(
    message: CountriesConfig_DeprecatedPhoneOperatorPrefixesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      PhoneOperatorPrefixes.encode(
        message.value,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CountriesConfig_DeprecatedPhoneOperatorPrefixesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseCountriesConfig_DeprecatedPhoneOperatorPrefixesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = PhoneOperatorPrefixes.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountriesConfig_DeprecatedPhoneOperatorPrefixesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value)
        ? PhoneOperatorPrefixes.fromJSON(object.value)
        : undefined,
    };
  },

  toJSON(
    message: CountriesConfig_DeprecatedPhoneOperatorPrefixesEntry,
  ): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value
        ? PhoneOperatorPrefixes.toJSON(message.value)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<CountriesConfig_DeprecatedPhoneOperatorPrefixesEntry>,
  ): CountriesConfig_DeprecatedPhoneOperatorPrefixesEntry {
    return CountriesConfig_DeprecatedPhoneOperatorPrefixesEntry.fromPartial(
      base ?? {},
    );
  },

  fromPartial(
    object: DeepPartial<CountriesConfig_DeprecatedPhoneOperatorPrefixesEntry>,
  ): CountriesConfig_DeprecatedPhoneOperatorPrefixesEntry {
    const message =
      createBaseCountriesConfig_DeprecatedPhoneOperatorPrefixesEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? PhoneOperatorPrefixes.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseCountriesConfig_DeprecatedEnabledPhoneOperatorsEntry(): CountriesConfig_DeprecatedEnabledPhoneOperatorsEntry {
  return { key: '', value: undefined };
}

export const CountriesConfig_DeprecatedEnabledPhoneOperatorsEntry = {
  encode(
    message: CountriesConfig_DeprecatedEnabledPhoneOperatorsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      EnabledOperators.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CountriesConfig_DeprecatedEnabledPhoneOperatorsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseCountriesConfig_DeprecatedEnabledPhoneOperatorsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = EnabledOperators.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountriesConfig_DeprecatedEnabledPhoneOperatorsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value)
        ? EnabledOperators.fromJSON(object.value)
        : undefined,
    };
  },

  toJSON(
    message: CountriesConfig_DeprecatedEnabledPhoneOperatorsEntry,
  ): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value
        ? EnabledOperators.toJSON(message.value)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<CountriesConfig_DeprecatedEnabledPhoneOperatorsEntry>,
  ): CountriesConfig_DeprecatedEnabledPhoneOperatorsEntry {
    return CountriesConfig_DeprecatedEnabledPhoneOperatorsEntry.fromPartial(
      base ?? {},
    );
  },

  fromPartial(
    object: DeepPartial<CountriesConfig_DeprecatedEnabledPhoneOperatorsEntry>,
  ): CountriesConfig_DeprecatedEnabledPhoneOperatorsEntry {
    const message =
      createBaseCountriesConfig_DeprecatedEnabledPhoneOperatorsEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? EnabledOperators.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseCountriesConfig_DeprecatedAdditionalRecipientDataEntry(): CountriesConfig_DeprecatedAdditionalRecipientDataEntry {
  return { key: '', value: undefined };
}

export const CountriesConfig_DeprecatedAdditionalRecipientDataEntry = {
  encode(
    message: CountriesConfig_DeprecatedAdditionalRecipientDataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      AdditionalRecipientData.encode(
        message.value,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CountriesConfig_DeprecatedAdditionalRecipientDataEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseCountriesConfig_DeprecatedAdditionalRecipientDataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = AdditionalRecipientData.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(
    object: any,
  ): CountriesConfig_DeprecatedAdditionalRecipientDataEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value)
        ? AdditionalRecipientData.fromJSON(object.value)
        : undefined,
    };
  },

  toJSON(
    message: CountriesConfig_DeprecatedAdditionalRecipientDataEntry,
  ): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value
        ? AdditionalRecipientData.toJSON(message.value)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<CountriesConfig_DeprecatedAdditionalRecipientDataEntry>,
  ): CountriesConfig_DeprecatedAdditionalRecipientDataEntry {
    return CountriesConfig_DeprecatedAdditionalRecipientDataEntry.fromPartial(
      base ?? {},
    );
  },

  fromPartial(
    object: DeepPartial<CountriesConfig_DeprecatedAdditionalRecipientDataEntry>,
  ): CountriesConfig_DeprecatedAdditionalRecipientDataEntry {
    const message =
      createBaseCountriesConfig_DeprecatedAdditionalRecipientDataEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? AdditionalRecipientData.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseCountriesConfig_DeprecatedBankTransfersEnabledEntry(): CountriesConfig_DeprecatedBankTransfersEnabledEntry {
  return { key: '', value: false };
}

export const CountriesConfig_DeprecatedBankTransfersEnabledEntry = {
  encode(
    message: CountriesConfig_DeprecatedBankTransfersEnabledEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value === true) {
      writer.uint32(16).bool(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CountriesConfig_DeprecatedBankTransfersEnabledEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseCountriesConfig_DeprecatedBankTransfersEnabledEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountriesConfig_DeprecatedBankTransfersEnabledEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? Boolean(object.value) : false,
    };
  },

  toJSON(
    message: CountriesConfig_DeprecatedBankTransfersEnabledEntry,
  ): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<CountriesConfig_DeprecatedBankTransfersEnabledEntry>,
  ): CountriesConfig_DeprecatedBankTransfersEnabledEntry {
    return CountriesConfig_DeprecatedBankTransfersEnabledEntry.fromPartial(
      base ?? {},
    );
  },

  fromPartial(
    object: DeepPartial<CountriesConfig_DeprecatedBankTransfersEnabledEntry>,
  ): CountriesConfig_DeprecatedBankTransfersEnabledEntry {
    const message =
      createBaseCountriesConfig_DeprecatedBankTransfersEnabledEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseCountriesConfig_CountriesEntry(): CountriesConfig_CountriesEntry {
  return { key: '', value: undefined };
}

export const CountriesConfig_CountriesEntry = {
  encode(
    message: CountriesConfig_CountriesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Country.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CountriesConfig_CountriesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountriesConfig_CountriesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Country.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountriesConfig_CountriesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? Country.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: CountriesConfig_CountriesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? Country.toJSON(message.value) : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<CountriesConfig_CountriesEntry>,
  ): CountriesConfig_CountriesEntry {
    return CountriesConfig_CountriesEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CountriesConfig_CountriesEntry>,
  ): CountriesConfig_CountriesEntry {
    const message = createBaseCountriesConfig_CountriesEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? Country.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseCountriesConfig_CurrenciesEntry(): CountriesConfig_CurrenciesEntry {
  return { key: '', value: undefined };
}

export const CountriesConfig_CurrenciesEntry = {
  encode(
    message: CountriesConfig_CurrenciesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Currency.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CountriesConfig_CurrenciesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountriesConfig_CurrenciesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Currency.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountriesConfig_CurrenciesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? Currency.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: CountriesConfig_CurrenciesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? Currency.toJSON(message.value) : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<CountriesConfig_CurrenciesEntry>,
  ): CountriesConfig_CurrenciesEntry {
    return CountriesConfig_CurrenciesEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CountriesConfig_CurrenciesEntry>,
  ): CountriesConfig_CurrenciesEntry {
    const message = createBaseCountriesConfig_CurrenciesEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? Currency.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseCountriesConfig_EnabledForPhoneLookupsEntry(): CountriesConfig_EnabledForPhoneLookupsEntry {
  return { key: '', value: undefined };
}

export const CountriesConfig_EnabledForPhoneLookupsEntry = {
  encode(
    message: CountriesConfig_EnabledForPhoneLookupsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      EnabledOperators.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CountriesConfig_EnabledForPhoneLookupsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountriesConfig_EnabledForPhoneLookupsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = EnabledOperators.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountriesConfig_EnabledForPhoneLookupsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value)
        ? EnabledOperators.fromJSON(object.value)
        : undefined,
    };
  },

  toJSON(message: CountriesConfig_EnabledForPhoneLookupsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value
        ? EnabledOperators.toJSON(message.value)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<CountriesConfig_EnabledForPhoneLookupsEntry>,
  ): CountriesConfig_EnabledForPhoneLookupsEntry {
    return CountriesConfig_EnabledForPhoneLookupsEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CountriesConfig_EnabledForPhoneLookupsEntry>,
  ): CountriesConfig_EnabledForPhoneLookupsEntry {
    const message = createBaseCountriesConfig_EnabledForPhoneLookupsEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? EnabledOperators.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseSenderCountry(): SenderCountry {
  return {
    code: '',
    currencies: [],
    recipientCountries: [],
    allowedPhoneNumberCountries: [],
  };
}

export const SenderCountry = {
  encode(
    message: SenderCountry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.code !== '') {
      writer.uint32(10).string(message.code);
    }
    for (const v of message.currencies) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.recipientCountries) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.allowedPhoneNumberCountries) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SenderCountry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSenderCountry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.currencies.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.recipientCountries.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.allowedPhoneNumberCountries.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SenderCountry {
    return {
      code: isSet(object.code) ? String(object.code) : '',
      currencies: Array.isArray(object?.currencies)
        ? object.currencies.map((e: any) => String(e))
        : [],
      recipientCountries: Array.isArray(object?.recipientCountries)
        ? object.recipientCountries.map((e: any) => String(e))
        : [],
      allowedPhoneNumberCountries: Array.isArray(
        object?.allowedPhoneNumberCountries,
      )
        ? object.allowedPhoneNumberCountries.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: SenderCountry): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    if (message.currencies) {
      obj.currencies = message.currencies.map((e) => e);
    } else {
      obj.currencies = [];
    }
    if (message.recipientCountries) {
      obj.recipientCountries = message.recipientCountries.map((e) => e);
    } else {
      obj.recipientCountries = [];
    }
    if (message.allowedPhoneNumberCountries) {
      obj.allowedPhoneNumberCountries = message.allowedPhoneNumberCountries.map(
        (e) => e,
      );
    } else {
      obj.allowedPhoneNumberCountries = [];
    }
    return obj;
  },

  create(base?: DeepPartial<SenderCountry>): SenderCountry {
    return SenderCountry.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SenderCountry>): SenderCountry {
    const message = createBaseSenderCountry();
    message.code = object.code ?? '';
    message.currencies = object.currencies?.map((e) => e) || [];
    message.recipientCountries = object.recipientCountries?.map((e) => e) || [];
    message.allowedPhoneNumberCountries =
      object.allowedPhoneNumberCountries?.map((e) => e) || [];
    return message;
  },
};

function createBaseRecipientCountry(): RecipientCountry {
  return {
    code: '',
    currency: '',
    additionalRecipientData: [],
    bankTransfersEnabled: false,
    phoneOperatorPrefixes: {},
    enabledPhoneOperators: [],
    bankTransfersSupportCustomReferences: false,
    payoutFactor: 0,
  };
}

export const RecipientCountry = {
  encode(
    message: RecipientCountry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.code !== '') {
      writer.uint32(10).string(message.code);
    }
    if (message.currency !== '') {
      writer.uint32(18).string(message.currency);
    }
    for (const v of message.additionalRecipientData) {
      writer.uint32(26).string(v!);
    }
    if (message.bankTransfersEnabled === true) {
      writer.uint32(32).bool(message.bankTransfersEnabled);
    }
    Object.entries(message.phoneOperatorPrefixes).forEach(([key, value]) => {
      RecipientCountry_PhoneOperatorPrefixesEntry.encode(
        { key: key as any, value },
        writer.uint32(42).fork(),
      ).ldelim();
    });
    writer.uint32(50).fork();
    for (const v of message.enabledPhoneOperators) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.bankTransfersSupportCustomReferences === true) {
      writer.uint32(56).bool(message.bankTransfersSupportCustomReferences);
    }
    if (message.payoutFactor !== 0) {
      writer.uint32(64).int32(message.payoutFactor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RecipientCountry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecipientCountry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.additionalRecipientData.push(reader.string());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bankTransfersEnabled = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 = RecipientCountry_PhoneOperatorPrefixesEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry5.value !== undefined) {
            message.phoneOperatorPrefixes[entry5.key] = entry5.value;
          }
          continue;
        case 6:
          if (tag === 48) {
            message.enabledPhoneOperators.push(reader.int32() as any);

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.enabledPhoneOperators.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.bankTransfersSupportCustomReferences = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.payoutFactor = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecipientCountry {
    return {
      code: isSet(object.code) ? String(object.code) : '',
      currency: isSet(object.currency) ? String(object.currency) : '',
      additionalRecipientData: Array.isArray(object?.additionalRecipientData)
        ? object.additionalRecipientData.map((e: any) => String(e))
        : [],
      bankTransfersEnabled: isSet(object.bankTransfersEnabled)
        ? Boolean(object.bankTransfersEnabled)
        : false,
      phoneOperatorPrefixes: isObject(object.phoneOperatorPrefixes)
        ? Object.entries(object.phoneOperatorPrefixes).reduce<{
            [key: string]: PhoneOperator;
          }>((acc, [key, value]) => {
            acc[key] = phoneOperatorFromJSON(value);
            return acc;
          }, {})
        : {},
      enabledPhoneOperators: Array.isArray(object?.enabledPhoneOperators)
        ? object.enabledPhoneOperators.map((e: any) => phoneOperatorFromJSON(e))
        : [],
      bankTransfersSupportCustomReferences: isSet(
        object.bankTransfersSupportCustomReferences,
      )
        ? Boolean(object.bankTransfersSupportCustomReferences)
        : false,
      payoutFactor: isSet(object.payoutFactor)
        ? Number(object.payoutFactor)
        : 0,
    };
  },

  toJSON(message: RecipientCountry): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    message.currency !== undefined && (obj.currency = message.currency);
    if (message.additionalRecipientData) {
      obj.additionalRecipientData = message.additionalRecipientData.map(
        (e) => e,
      );
    } else {
      obj.additionalRecipientData = [];
    }
    message.bankTransfersEnabled !== undefined &&
      (obj.bankTransfersEnabled = message.bankTransfersEnabled);
    obj.phoneOperatorPrefixes = {};
    if (message.phoneOperatorPrefixes) {
      Object.entries(message.phoneOperatorPrefixes).forEach(([k, v]) => {
        obj.phoneOperatorPrefixes[k] = phoneOperatorToJSON(v);
      });
    }
    if (message.enabledPhoneOperators) {
      obj.enabledPhoneOperators = message.enabledPhoneOperators.map((e) =>
        phoneOperatorToJSON(e),
      );
    } else {
      obj.enabledPhoneOperators = [];
    }
    message.bankTransfersSupportCustomReferences !== undefined &&
      (obj.bankTransfersSupportCustomReferences =
        message.bankTransfersSupportCustomReferences);
    message.payoutFactor !== undefined &&
      (obj.payoutFactor = Math.round(message.payoutFactor));
    return obj;
  },

  create(base?: DeepPartial<RecipientCountry>): RecipientCountry {
    return RecipientCountry.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<RecipientCountry>): RecipientCountry {
    const message = createBaseRecipientCountry();
    message.code = object.code ?? '';
    message.currency = object.currency ?? '';
    message.additionalRecipientData =
      object.additionalRecipientData?.map((e) => e) || [];
    message.bankTransfersEnabled = object.bankTransfersEnabled ?? false;
    message.phoneOperatorPrefixes = Object.entries(
      object.phoneOperatorPrefixes ?? {},
    ).reduce<{ [key: string]: PhoneOperator }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value as PhoneOperator;
      }
      return acc;
    }, {});
    message.enabledPhoneOperators =
      object.enabledPhoneOperators?.map((e) => e) || [];
    message.bankTransfersSupportCustomReferences =
      object.bankTransfersSupportCustomReferences ?? false;
    message.payoutFactor = object.payoutFactor ?? 0;
    return message;
  },
};

function createBaseRecipientCountry_PhoneOperatorPrefixesEntry(): RecipientCountry_PhoneOperatorPrefixesEntry {
  return { key: '', value: 0 };
}

export const RecipientCountry_PhoneOperatorPrefixesEntry = {
  encode(
    message: RecipientCountry_PhoneOperatorPrefixesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int32(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RecipientCountry_PhoneOperatorPrefixesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecipientCountry_PhoneOperatorPrefixesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecipientCountry_PhoneOperatorPrefixesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? phoneOperatorFromJSON(object.value) : 0,
    };
  },

  toJSON(message: RecipientCountry_PhoneOperatorPrefixesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = phoneOperatorToJSON(message.value));
    return obj;
  },

  create(
    base?: DeepPartial<RecipientCountry_PhoneOperatorPrefixesEntry>,
  ): RecipientCountry_PhoneOperatorPrefixesEntry {
    return RecipientCountry_PhoneOperatorPrefixesEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<RecipientCountry_PhoneOperatorPrefixesEntry>,
  ): RecipientCountry_PhoneOperatorPrefixesEntry {
    const message = createBaseRecipientCountry_PhoneOperatorPrefixesEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseCountry(): Country {
  return { phoneCode: '', defaultCurrency: '' };
}

export const Country = {
  encode(
    message: Country,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.phoneCode !== '') {
      writer.uint32(10).string(message.phoneCode);
    }
    if (message.defaultCurrency !== '') {
      writer.uint32(18).string(message.defaultCurrency);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Country {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phoneCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.defaultCurrency = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Country {
    return {
      phoneCode: isSet(object.phoneCode) ? String(object.phoneCode) : '',
      defaultCurrency: isSet(object.defaultCurrency)
        ? String(object.defaultCurrency)
        : '',
    };
  },

  toJSON(message: Country): unknown {
    const obj: any = {};
    message.phoneCode !== undefined && (obj.phoneCode = message.phoneCode);
    message.defaultCurrency !== undefined &&
      (obj.defaultCurrency = message.defaultCurrency);
    return obj;
  },

  create(base?: DeepPartial<Country>): Country {
    return Country.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Country>): Country {
    const message = createBaseCountry();
    message.phoneCode = object.phoneCode ?? '';
    message.defaultCurrency = object.defaultCurrency ?? '';
    return message;
  },
};

function createBaseABTestingCampaign(): ABTestingCampaign {
  return { feature: '', ratioConfig: undefined };
}

export const ABTestingCampaign = {
  encode(
    message: ABTestingCampaign,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.feature !== '') {
      writer.uint32(10).string(message.feature);
    }
    if (message.ratioConfig !== undefined) {
      ABTestingRatioConfig.encode(
        message.ratioConfig,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ABTestingCampaign {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseABTestingCampaign();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.feature = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ratioConfig = ABTestingRatioConfig.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ABTestingCampaign {
    return {
      feature: isSet(object.feature) ? String(object.feature) : '',
      ratioConfig: isSet(object.ratioConfig)
        ? ABTestingRatioConfig.fromJSON(object.ratioConfig)
        : undefined,
    };
  },

  toJSON(message: ABTestingCampaign): unknown {
    const obj: any = {};
    message.feature !== undefined && (obj.feature = message.feature);
    message.ratioConfig !== undefined &&
      (obj.ratioConfig = message.ratioConfig
        ? ABTestingRatioConfig.toJSON(message.ratioConfig)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<ABTestingCampaign>): ABTestingCampaign {
    return ABTestingCampaign.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ABTestingCampaign>): ABTestingCampaign {
    const message = createBaseABTestingCampaign();
    message.feature = object.feature ?? '';
    message.ratioConfig =
      object.ratioConfig !== undefined && object.ratioConfig !== null
        ? ABTestingRatioConfig.fromPartial(object.ratioConfig)
        : undefined;
    return message;
  },
};

function createBaseABTestingRatioConfig(): ABTestingRatioConfig {
  return { isDynamic: false, percentage: 0 };
}

export const ABTestingRatioConfig = {
  encode(
    message: ABTestingRatioConfig,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.isDynamic === true) {
      writer.uint32(8).bool(message.isDynamic);
    }
    if (message.percentage !== 0) {
      writer.uint32(16).int32(message.percentage);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ABTestingRatioConfig {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseABTestingRatioConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isDynamic = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.percentage = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ABTestingRatioConfig {
    return {
      isDynamic: isSet(object.isDynamic) ? Boolean(object.isDynamic) : false,
      percentage: isSet(object.percentage) ? Number(object.percentage) : 0,
    };
  },

  toJSON(message: ABTestingRatioConfig): unknown {
    const obj: any = {};
    message.isDynamic !== undefined && (obj.isDynamic = message.isDynamic);
    message.percentage !== undefined &&
      (obj.percentage = Math.round(message.percentage));
    return obj;
  },

  create(base?: DeepPartial<ABTestingRatioConfig>): ABTestingRatioConfig {
    return ABTestingRatioConfig.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ABTestingRatioConfig>): ABTestingRatioConfig {
    const message = createBaseABTestingRatioConfig();
    message.isDynamic = object.isDynamic ?? false;
    message.percentage = object.percentage ?? 0;
    return message;
  },
};

function createBaseSendOption(): SendOption {
  return { name: '', description: '', type: 0, availability: 0, status: 0 };
}

export const SendOption = {
  encode(
    message: SendOption,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.name !== '') {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== '') {
      writer.uint32(18).string(message.description);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.availability !== 0) {
      writer.uint32(32).int32(message.availability);
    }
    if (message.status !== 0) {
      writer.uint32(40).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendOption {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendOption();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.availability = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendOption {
    return {
      name: isSet(object.name) ? String(object.name) : '',
      description: isSet(object.description) ? String(object.description) : '',
      type: isSet(object.type) ? sendOptionTypeFromJSON(object.type) : 0,
      availability: isSet(object.availability)
        ? sendOptionAvailabilityFromJSON(object.availability)
        : 0,
      status: isSet(object.status)
        ? sendOptionStatusFromJSON(object.status)
        : 0,
    };
  },

  toJSON(message: SendOption): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined &&
      (obj.description = message.description);
    message.type !== undefined &&
      (obj.type = sendOptionTypeToJSON(message.type));
    message.availability !== undefined &&
      (obj.availability = sendOptionAvailabilityToJSON(message.availability));
    message.status !== undefined &&
      (obj.status = sendOptionStatusToJSON(message.status));
    return obj;
  },

  create(base?: DeepPartial<SendOption>): SendOption {
    return SendOption.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SendOption>): SendOption {
    const message = createBaseSendOption();
    message.name = object.name ?? '';
    message.description = object.description ?? '';
    message.type = object.type ?? 0;
    message.availability = object.availability ?? 0;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseSendOptions(): SendOptions {
  return { sendOptions: [] };
}

export const SendOptions = {
  encode(
    message: SendOptions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.sendOptions) {
      SendOption.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendOptions {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sendOptions.push(SendOption.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendOptions {
    return {
      sendOptions: Array.isArray(object?.sendOptions)
        ? object.sendOptions.map((e: any) => SendOption.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SendOptions): unknown {
    const obj: any = {};
    if (message.sendOptions) {
      obj.sendOptions = message.sendOptions.map((e) =>
        e ? SendOption.toJSON(e) : undefined,
      );
    } else {
      obj.sendOptions = [];
    }
    return obj;
  },

  create(base?: DeepPartial<SendOptions>): SendOptions {
    return SendOptions.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SendOptions>): SendOptions {
    const message = createBaseSendOptions();
    message.sendOptions =
      object.sendOptions?.map((e) => SendOption.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSendOptionBanner(): SendOptionBanner {
  return {
    title: '',
    subTitle: '',
    action: 0,
    modal: undefined,
    url: undefined,
  };
}

export const SendOptionBanner = {
  encode(
    message: SendOptionBanner,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.title !== '') {
      writer.uint32(10).string(message.title);
    }
    if (message.subTitle !== '') {
      writer.uint32(18).string(message.subTitle);
    }
    if (message.action !== 0) {
      writer.uint32(24).int32(message.action);
    }
    if (message.modal !== undefined) {
      SendOptionBanner_Modal.encode(
        message.modal,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.url !== undefined) {
      writer.uint32(42).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendOptionBanner {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendOptionBanner();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.subTitle = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.action = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.modal = SendOptionBanner_Modal.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendOptionBanner {
    return {
      title: isSet(object.title) ? String(object.title) : '',
      subTitle: isSet(object.subTitle) ? String(object.subTitle) : '',
      action: isSet(object.action)
        ? sendOptionBanner_ActionFromJSON(object.action)
        : 0,
      modal: isSet(object.modal)
        ? SendOptionBanner_Modal.fromJSON(object.modal)
        : undefined,
      url: isSet(object.url) ? String(object.url) : undefined,
    };
  },

  toJSON(message: SendOptionBanner): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.subTitle !== undefined && (obj.subTitle = message.subTitle);
    message.action !== undefined &&
      (obj.action = sendOptionBanner_ActionToJSON(message.action));
    message.modal !== undefined &&
      (obj.modal = message.modal
        ? SendOptionBanner_Modal.toJSON(message.modal)
        : undefined);
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create(base?: DeepPartial<SendOptionBanner>): SendOptionBanner {
    return SendOptionBanner.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SendOptionBanner>): SendOptionBanner {
    const message = createBaseSendOptionBanner();
    message.title = object.title ?? '';
    message.subTitle = object.subTitle ?? '';
    message.action = object.action ?? 0;
    message.modal =
      object.modal !== undefined && object.modal !== null
        ? SendOptionBanner_Modal.fromPartial(object.modal)
        : undefined;
    message.url = object.url ?? undefined;
    return message;
  },
};

function createBaseSendOptionBanner_Modal(): SendOptionBanner_Modal {
  return { title: '', content: '', mamaNalaLink: false };
}

export const SendOptionBanner_Modal = {
  encode(
    message: SendOptionBanner_Modal,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.title !== '') {
      writer.uint32(10).string(message.title);
    }
    if (message.content !== '') {
      writer.uint32(18).string(message.content);
    }
    if (message.mamaNalaLink === true) {
      writer.uint32(24).bool(message.mamaNalaLink);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SendOptionBanner_Modal {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendOptionBanner_Modal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.content = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.mamaNalaLink = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendOptionBanner_Modal {
    return {
      title: isSet(object.title) ? String(object.title) : '',
      content: isSet(object.content) ? String(object.content) : '',
      mamaNalaLink: isSet(object.mamaNalaLink)
        ? Boolean(object.mamaNalaLink)
        : false,
    };
  },

  toJSON(message: SendOptionBanner_Modal): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.content !== undefined && (obj.content = message.content);
    message.mamaNalaLink !== undefined &&
      (obj.mamaNalaLink = message.mamaNalaLink);
    return obj;
  },

  create(base?: DeepPartial<SendOptionBanner_Modal>): SendOptionBanner_Modal {
    return SendOptionBanner_Modal.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<SendOptionBanner_Modal>,
  ): SendOptionBanner_Modal {
    const message = createBaseSendOptionBanner_Modal();
    message.title = object.title ?? '';
    message.content = object.content ?? '';
    message.mamaNalaLink = object.mamaNalaLink ?? false;
    return message;
  },
};

function createBaseSendOptionBanners(): SendOptionBanners {
  return { banners: [] };
}

export const SendOptionBanners = {
  encode(
    message: SendOptionBanners,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.banners) {
      SendOptionBanner.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendOptionBanners {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendOptionBanners();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.banners.push(
            SendOptionBanner.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendOptionBanners {
    return {
      banners: Array.isArray(object?.banners)
        ? object.banners.map((e: any) => SendOptionBanner.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SendOptionBanners): unknown {
    const obj: any = {};
    if (message.banners) {
      obj.banners = message.banners.map((e) =>
        e ? SendOptionBanner.toJSON(e) : undefined,
      );
    } else {
      obj.banners = [];
    }
    return obj;
  },

  create(base?: DeepPartial<SendOptionBanners>): SendOptionBanners {
    return SendOptionBanners.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SendOptionBanners>): SendOptionBanners {
    const message = createBaseSendOptionBanners();
    message.banners =
      object.banners?.map((e) => SendOptionBanner.fromPartial(e)) || [];
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Timestamp {
  if (o instanceof Date) {
    return toTimestamp(o);
  } else if (typeof o === 'string') {
    return toTimestamp(new Date(o));
  } else {
    return Timestamp.fromJSON(o);
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error(
      'Value is larger than Number.MAX_SAFE_INTEGER',
    );
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
