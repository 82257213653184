/* eslint-disable */
import { grpc } from '@improbable-eng/grpc-web';
import { BrowserHeaders } from 'browser-headers';
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import {
  SendOptionBanners,
  SendOptions,
} from '../../../common/proto/config/types';
import { Name } from '../../../common/proto/kyc/types';
import {
  Account,
  Action,
  BannerItem,
  ContactDetailType,
  contactDetailTypeFromJSON,
  contactDetailTypeToJSON,
  Feature,
  InvitationCode,
  Member,
  Membership,
  MembershipInvitation,
  MembershipRole,
  membershipRoleFromJSON,
  membershipRoleToJSON,
  MembershipRoleWithPermissions,
  MembershipStatus,
  membershipStatusFromJSON,
  membershipStatusToJSON,
  PostLoginAction,
  Source,
  TermsType,
  termsTypeFromJSON,
  termsTypeToJSON,
  Usage,
  User,
} from '../../../common/proto/users/types';

export const protobufPackage = 'public';

export interface SignUpWithExternalAuthRequest {
  userId: string;
}

export interface SignUpReply {
  user: User | undefined;
  name: Name | undefined;
}

/** session */
export interface GetSessionTokenRequest {}

export interface GetSessionTokenReply {
  /** Returns the name of the auth provider used to generate the session token */
  providerName: string;
  /** The JWT token, should be treated as a black box to sent back in the Authorization header */
  providerToken: string;
}

/** get profile */
export interface GetProfileRequest {}

/** get default profile */
export interface GetDefaultProfileRequest {}

export interface GetProfileReply {
  user: User | undefined;
  name: Name | undefined;
  invitationCode: InvitationCode | undefined;
  usedInvitationCode: string;
  country: string;
  account: Account | undefined;
  membership: Membership | undefined;
}

/** refresh profile */
export interface RefreshProfileRequest {}

export interface RefreshProfileReply {
  user: User | undefined;
}

/** request verification email or SMS */
export interface SendContactDetailVerificationRequest {
  type: ContactDetailType;
  value: string;
  appSignature: string;
  country: string;
}

export interface SendContactDetailVerificationReply {
  alreadyVerifiedForAnotherUser: boolean;
  resendWaitSeconds: number;
  alreadyVerifiedForCurrentUser: boolean;
}

/** verify code from SMS */
export interface VerifyContactDetailRequest {
  type: ContactDetailType;
  value: string;
  code: string;
  country: string;
  providerName: string;
}

export interface VerifyContactDetailReply {
  verified: boolean;
}

/** get banner items */
export interface GetBannerItemsRequest {}

export interface GetBannerItemsReply {
  items: BannerItem[];
}

/** set usage */
export interface SetUsageRequest {
  usage: Usage | undefined;
}

export interface SetUsageReply {
  allowed: boolean;
}

/** use invitation code */
export interface UseInvitationCodeRequest {
  code: string;
  attribution: boolean;
}

export interface UseInvitationCodeReply {}

/** skip invitation code */
export interface SkipInvitationCodeRequest {}

export interface SkipInvitationCodeReply {}

/** set source */
export interface SetSourceRequest {
  source: Source | undefined;
}

export interface SetSourceReply {}

/** list accounts */
export interface ListAccountsRequest {}

export interface ListAccountsReply {
  accounts: Account[];
}

export interface GetPostLoginActionRequest {}

export interface GetPostLoginActionReply {
  action: PostLoginAction | undefined;
}

export interface GetPostLoginActionsRequest {}

export interface GetPostLoginActionsReply {
  actions: PostLoginAction[];
}

export interface UpdateAccountRequest {
  profilePicture: string;
}

export interface UpdateAccountReply {
  account: Account | undefined;
}

export interface GetSendOptionsRequest {}

export interface GetSendOptionsReply {
  sendOptions: { [key: string]: SendOptions };
  banners: { [key: string]: SendOptionBanners };
}

export interface GetSendOptionsReply_SendOptionsEntry {
  key: string;
  value: SendOptions | undefined;
}

export interface GetSendOptionsReply_BannersEntry {
  key: string;
  value: SendOptionBanners | undefined;
}

export interface ListMembersRequest {
  includeDeleted: boolean;
}

export interface ListMembersReply {
  members: Member[];
}

export interface ListRolesRequest {}

export interface ListRolesReply {
  roles: MembershipRoleWithPermissions[];
}

export interface InviteMemberRequest {
  email: string;
  phoneNumber: string;
  roles: MembershipRole[];
}

export interface InviteMemberReply {
  invitation: MembershipInvitation | undefined;
}

export interface JoinAccountRequest {
  invitationId: string;
  accountId: string;
}

export interface JoinAccountReply {}

export interface UpdateMemberRequest {
  userId: string;
  roles: MembershipRole[];
  status: MembershipStatus;
}

export interface UpdateMemberReply {
  membership: Membership | undefined;
}

export interface RemoveMemberRequest {
  userId: string;
}

export interface RemoveMemberReply {}

export interface ListMembershipInvitationsRequest {
  includeUsed: boolean;
}

export interface ListMembershipInvitationsReply {
  invitations: MembershipInvitation[];
}

export interface DeleteMembershipInvitationRequest {
  invitationId: string;
}

export interface DeleteMembershipInvitationReply {}

export interface AcceptTermsRequest {
  type: TermsType;
}

export interface AcceptTermsReply {}

export interface GetRequiredActionsForPeerToPeerSetupRequest {}

export interface GetRequiredActionsForPeerToPeerSetupReply {
  action?: Action | undefined;
}

export interface ClaimPeerToPeerTagRequest {
  tag: string;
}

export interface ClaimPeerToPeerTagReply {}

export interface ListFeaturesRequest {}

export interface ListFeaturesReply {
  features: { [key: string]: Feature };
}

export interface ListFeaturesReply_FeaturesEntry {
  key: string;
  value: Feature | undefined;
}

export interface UpdateFeatureRequest {
  featureName: string;
  enabled: boolean;
}

export interface UpdateFeatureReply {}

function createBaseSignUpWithExternalAuthRequest(): SignUpWithExternalAuthRequest {
  return { userId: '' };
}

export const SignUpWithExternalAuthRequest = {
  encode(
    message: SignUpWithExternalAuthRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.userId !== '') {
      writer.uint32(10).string(message.userId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SignUpWithExternalAuthRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpWithExternalAuthRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignUpWithExternalAuthRequest {
    return { userId: isSet(object.userId) ? String(object.userId) : '' };
  },

  toJSON(message: SignUpWithExternalAuthRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  create(
    base?: DeepPartial<SignUpWithExternalAuthRequest>,
  ): SignUpWithExternalAuthRequest {
    return SignUpWithExternalAuthRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<SignUpWithExternalAuthRequest>,
  ): SignUpWithExternalAuthRequest {
    const message = createBaseSignUpWithExternalAuthRequest();
    message.userId = object.userId ?? '';
    return message;
  },
};

function createBaseSignUpReply(): SignUpReply {
  return { user: undefined, name: undefined };
}

export const SignUpReply = {
  encode(
    message: SignUpReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      Name.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignUpReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Name.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignUpReply {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      name: isSet(object.name) ? Name.fromJSON(object.name) : undefined,
    };
  },

  toJSON(message: SignUpReply): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    message.name !== undefined &&
      (obj.name = message.name ? Name.toJSON(message.name) : undefined);
    return obj;
  },

  create(base?: DeepPartial<SignUpReply>): SignUpReply {
    return SignUpReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SignUpReply>): SignUpReply {
    const message = createBaseSignUpReply();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    message.name =
      object.name !== undefined && object.name !== null
        ? Name.fromPartial(object.name)
        : undefined;
    return message;
  },
};

function createBaseGetSessionTokenRequest(): GetSessionTokenRequest {
  return {};
}

export const GetSessionTokenRequest = {
  encode(
    _: GetSessionTokenRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetSessionTokenRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSessionTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetSessionTokenRequest {
    return {};
  },

  toJSON(_: GetSessionTokenRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<GetSessionTokenRequest>): GetSessionTokenRequest {
    return GetSessionTokenRequest.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<GetSessionTokenRequest>): GetSessionTokenRequest {
    const message = createBaseGetSessionTokenRequest();
    return message;
  },
};

function createBaseGetSessionTokenReply(): GetSessionTokenReply {
  return { providerName: '', providerToken: '' };
}

export const GetSessionTokenReply = {
  encode(
    message: GetSessionTokenReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.providerName !== '') {
      writer.uint32(10).string(message.providerName);
    }
    if (message.providerToken !== '') {
      writer.uint32(18).string(message.providerToken);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetSessionTokenReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSessionTokenReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.providerName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.providerToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSessionTokenReply {
    return {
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
      providerToken: isSet(object.providerToken)
        ? String(object.providerToken)
        : '',
    };
  },

  toJSON(message: GetSessionTokenReply): unknown {
    const obj: any = {};
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    message.providerToken !== undefined &&
      (obj.providerToken = message.providerToken);
    return obj;
  },

  create(base?: DeepPartial<GetSessionTokenReply>): GetSessionTokenReply {
    return GetSessionTokenReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetSessionTokenReply>): GetSessionTokenReply {
    const message = createBaseGetSessionTokenReply();
    message.providerName = object.providerName ?? '';
    message.providerToken = object.providerToken ?? '';
    return message;
  },
};

function createBaseGetProfileRequest(): GetProfileRequest {
  return {};
}

export const GetProfileRequest = {
  encode(
    _: GetProfileRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProfileRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProfileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetProfileRequest {
    return {};
  },

  toJSON(_: GetProfileRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<GetProfileRequest>): GetProfileRequest {
    return GetProfileRequest.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<GetProfileRequest>): GetProfileRequest {
    const message = createBaseGetProfileRequest();
    return message;
  },
};

function createBaseGetDefaultProfileRequest(): GetDefaultProfileRequest {
  return {};
}

export const GetDefaultProfileRequest = {
  encode(
    _: GetDefaultProfileRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetDefaultProfileRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDefaultProfileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetDefaultProfileRequest {
    return {};
  },

  toJSON(_: GetDefaultProfileRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<GetDefaultProfileRequest>,
  ): GetDefaultProfileRequest {
    return GetDefaultProfileRequest.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<GetDefaultProfileRequest>,
  ): GetDefaultProfileRequest {
    const message = createBaseGetDefaultProfileRequest();
    return message;
  },
};

function createBaseGetProfileReply(): GetProfileReply {
  return {
    user: undefined,
    name: undefined,
    invitationCode: undefined,
    usedInvitationCode: '',
    country: '',
    account: undefined,
    membership: undefined,
  };
}

export const GetProfileReply = {
  encode(
    message: GetProfileReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      Name.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    if (message.invitationCode !== undefined) {
      InvitationCode.encode(
        message.invitationCode,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.usedInvitationCode !== '') {
      writer.uint32(34).string(message.usedInvitationCode);
    }
    if (message.country !== '') {
      writer.uint32(42).string(message.country);
    }
    if (message.account !== undefined) {
      Account.encode(message.account, writer.uint32(50).fork()).ldelim();
    }
    if (message.membership !== undefined) {
      Membership.encode(message.membership, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProfileReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProfileReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Name.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.invitationCode = InvitationCode.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.usedInvitationCode = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.country = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.account = Account.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.membership = Membership.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetProfileReply {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      name: isSet(object.name) ? Name.fromJSON(object.name) : undefined,
      invitationCode: isSet(object.invitationCode)
        ? InvitationCode.fromJSON(object.invitationCode)
        : undefined,
      usedInvitationCode: isSet(object.usedInvitationCode)
        ? String(object.usedInvitationCode)
        : '',
      country: isSet(object.country) ? String(object.country) : '',
      account: isSet(object.account)
        ? Account.fromJSON(object.account)
        : undefined,
      membership: isSet(object.membership)
        ? Membership.fromJSON(object.membership)
        : undefined,
    };
  },

  toJSON(message: GetProfileReply): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    message.name !== undefined &&
      (obj.name = message.name ? Name.toJSON(message.name) : undefined);
    message.invitationCode !== undefined &&
      (obj.invitationCode = message.invitationCode
        ? InvitationCode.toJSON(message.invitationCode)
        : undefined);
    message.usedInvitationCode !== undefined &&
      (obj.usedInvitationCode = message.usedInvitationCode);
    message.country !== undefined && (obj.country = message.country);
    message.account !== undefined &&
      (obj.account = message.account
        ? Account.toJSON(message.account)
        : undefined);
    message.membership !== undefined &&
      (obj.membership = message.membership
        ? Membership.toJSON(message.membership)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetProfileReply>): GetProfileReply {
    return GetProfileReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetProfileReply>): GetProfileReply {
    const message = createBaseGetProfileReply();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    message.name =
      object.name !== undefined && object.name !== null
        ? Name.fromPartial(object.name)
        : undefined;
    message.invitationCode =
      object.invitationCode !== undefined && object.invitationCode !== null
        ? InvitationCode.fromPartial(object.invitationCode)
        : undefined;
    message.usedInvitationCode = object.usedInvitationCode ?? '';
    message.country = object.country ?? '';
    message.account =
      object.account !== undefined && object.account !== null
        ? Account.fromPartial(object.account)
        : undefined;
    message.membership =
      object.membership !== undefined && object.membership !== null
        ? Membership.fromPartial(object.membership)
        : undefined;
    return message;
  },
};

function createBaseRefreshProfileRequest(): RefreshProfileRequest {
  return {};
}

export const RefreshProfileRequest = {
  encode(
    _: RefreshProfileRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RefreshProfileRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshProfileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RefreshProfileRequest {
    return {};
  },

  toJSON(_: RefreshProfileRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<RefreshProfileRequest>): RefreshProfileRequest {
    return RefreshProfileRequest.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<RefreshProfileRequest>): RefreshProfileRequest {
    const message = createBaseRefreshProfileRequest();
    return message;
  },
};

function createBaseRefreshProfileReply(): RefreshProfileReply {
  return { user: undefined };
}

export const RefreshProfileReply = {
  encode(
    message: RefreshProfileReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshProfileReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshProfileReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefreshProfileReply {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: RefreshProfileReply): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    return obj;
  },

  create(base?: DeepPartial<RefreshProfileReply>): RefreshProfileReply {
    return RefreshProfileReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<RefreshProfileReply>): RefreshProfileReply {
    const message = createBaseRefreshProfileReply();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    return message;
  },
};

function createBaseSendContactDetailVerificationRequest(): SendContactDetailVerificationRequest {
  return { type: 0, value: '', appSignature: '', country: '' };
}

export const SendContactDetailVerificationRequest = {
  encode(
    message: SendContactDetailVerificationRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    if (message.appSignature !== '') {
      writer.uint32(26).string(message.appSignature);
    }
    if (message.country !== '') {
      writer.uint32(34).string(message.country);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SendContactDetailVerificationRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendContactDetailVerificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.appSignature = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.country = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendContactDetailVerificationRequest {
    return {
      type: isSet(object.type) ? contactDetailTypeFromJSON(object.type) : 0,
      value: isSet(object.value) ? String(object.value) : '',
      appSignature: isSet(object.appSignature)
        ? String(object.appSignature)
        : '',
      country: isSet(object.country) ? String(object.country) : '',
    };
  },

  toJSON(message: SendContactDetailVerificationRequest): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = contactDetailTypeToJSON(message.type));
    message.value !== undefined && (obj.value = message.value);
    message.appSignature !== undefined &&
      (obj.appSignature = message.appSignature);
    message.country !== undefined && (obj.country = message.country);
    return obj;
  },

  create(
    base?: DeepPartial<SendContactDetailVerificationRequest>,
  ): SendContactDetailVerificationRequest {
    return SendContactDetailVerificationRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<SendContactDetailVerificationRequest>,
  ): SendContactDetailVerificationRequest {
    const message = createBaseSendContactDetailVerificationRequest();
    message.type = object.type ?? 0;
    message.value = object.value ?? '';
    message.appSignature = object.appSignature ?? '';
    message.country = object.country ?? '';
    return message;
  },
};

function createBaseSendContactDetailVerificationReply(): SendContactDetailVerificationReply {
  return {
    alreadyVerifiedForAnotherUser: false,
    resendWaitSeconds: 0,
    alreadyVerifiedForCurrentUser: false,
  };
}

export const SendContactDetailVerificationReply = {
  encode(
    message: SendContactDetailVerificationReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.alreadyVerifiedForAnotherUser === true) {
      writer.uint32(8).bool(message.alreadyVerifiedForAnotherUser);
    }
    if (message.resendWaitSeconds !== 0) {
      writer.uint32(16).int64(message.resendWaitSeconds);
    }
    if (message.alreadyVerifiedForCurrentUser === true) {
      writer.uint32(24).bool(message.alreadyVerifiedForCurrentUser);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SendContactDetailVerificationReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendContactDetailVerificationReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.alreadyVerifiedForAnotherUser = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.resendWaitSeconds = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.alreadyVerifiedForCurrentUser = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendContactDetailVerificationReply {
    return {
      alreadyVerifiedForAnotherUser: isSet(object.alreadyVerifiedForAnotherUser)
        ? Boolean(object.alreadyVerifiedForAnotherUser)
        : false,
      resendWaitSeconds: isSet(object.resendWaitSeconds)
        ? Number(object.resendWaitSeconds)
        : 0,
      alreadyVerifiedForCurrentUser: isSet(object.alreadyVerifiedForCurrentUser)
        ? Boolean(object.alreadyVerifiedForCurrentUser)
        : false,
    };
  },

  toJSON(message: SendContactDetailVerificationReply): unknown {
    const obj: any = {};
    message.alreadyVerifiedForAnotherUser !== undefined &&
      (obj.alreadyVerifiedForAnotherUser =
        message.alreadyVerifiedForAnotherUser);
    message.resendWaitSeconds !== undefined &&
      (obj.resendWaitSeconds = Math.round(message.resendWaitSeconds));
    message.alreadyVerifiedForCurrentUser !== undefined &&
      (obj.alreadyVerifiedForCurrentUser =
        message.alreadyVerifiedForCurrentUser);
    return obj;
  },

  create(
    base?: DeepPartial<SendContactDetailVerificationReply>,
  ): SendContactDetailVerificationReply {
    return SendContactDetailVerificationReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<SendContactDetailVerificationReply>,
  ): SendContactDetailVerificationReply {
    const message = createBaseSendContactDetailVerificationReply();
    message.alreadyVerifiedForAnotherUser =
      object.alreadyVerifiedForAnotherUser ?? false;
    message.resendWaitSeconds = object.resendWaitSeconds ?? 0;
    message.alreadyVerifiedForCurrentUser =
      object.alreadyVerifiedForCurrentUser ?? false;
    return message;
  },
};

function createBaseVerifyContactDetailRequest(): VerifyContactDetailRequest {
  return { type: 0, value: '', code: '', country: '', providerName: '' };
}

export const VerifyContactDetailRequest = {
  encode(
    message: VerifyContactDetailRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    if (message.code !== '') {
      writer.uint32(26).string(message.code);
    }
    if (message.country !== '') {
      writer.uint32(34).string(message.country);
    }
    if (message.providerName !== '') {
      writer.uint32(42).string(message.providerName);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): VerifyContactDetailRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyContactDetailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.code = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.country = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.providerName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VerifyContactDetailRequest {
    return {
      type: isSet(object.type) ? contactDetailTypeFromJSON(object.type) : 0,
      value: isSet(object.value) ? String(object.value) : '',
      code: isSet(object.code) ? String(object.code) : '',
      country: isSet(object.country) ? String(object.country) : '',
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
    };
  },

  toJSON(message: VerifyContactDetailRequest): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = contactDetailTypeToJSON(message.type));
    message.value !== undefined && (obj.value = message.value);
    message.code !== undefined && (obj.code = message.code);
    message.country !== undefined && (obj.country = message.country);
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    return obj;
  },

  create(
    base?: DeepPartial<VerifyContactDetailRequest>,
  ): VerifyContactDetailRequest {
    return VerifyContactDetailRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<VerifyContactDetailRequest>,
  ): VerifyContactDetailRequest {
    const message = createBaseVerifyContactDetailRequest();
    message.type = object.type ?? 0;
    message.value = object.value ?? '';
    message.code = object.code ?? '';
    message.country = object.country ?? '';
    message.providerName = object.providerName ?? '';
    return message;
  },
};

function createBaseVerifyContactDetailReply(): VerifyContactDetailReply {
  return { verified: false };
}

export const VerifyContactDetailReply = {
  encode(
    message: VerifyContactDetailReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.verified === true) {
      writer.uint32(8).bool(message.verified);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): VerifyContactDetailReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyContactDetailReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.verified = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VerifyContactDetailReply {
    return {
      verified: isSet(object.verified) ? Boolean(object.verified) : false,
    };
  },

  toJSON(message: VerifyContactDetailReply): unknown {
    const obj: any = {};
    message.verified !== undefined && (obj.verified = message.verified);
    return obj;
  },

  create(
    base?: DeepPartial<VerifyContactDetailReply>,
  ): VerifyContactDetailReply {
    return VerifyContactDetailReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<VerifyContactDetailReply>,
  ): VerifyContactDetailReply {
    const message = createBaseVerifyContactDetailReply();
    message.verified = object.verified ?? false;
    return message;
  },
};

function createBaseGetBannerItemsRequest(): GetBannerItemsRequest {
  return {};
}

export const GetBannerItemsRequest = {
  encode(
    _: GetBannerItemsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetBannerItemsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBannerItemsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetBannerItemsRequest {
    return {};
  },

  toJSON(_: GetBannerItemsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<GetBannerItemsRequest>): GetBannerItemsRequest {
    return GetBannerItemsRequest.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<GetBannerItemsRequest>): GetBannerItemsRequest {
    const message = createBaseGetBannerItemsRequest();
    return message;
  },
};

function createBaseGetBannerItemsReply(): GetBannerItemsReply {
  return { items: [] };
}

export const GetBannerItemsReply = {
  encode(
    message: GetBannerItemsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.items) {
      BannerItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBannerItemsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBannerItemsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(BannerItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBannerItemsReply {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => BannerItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetBannerItemsReply): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) =>
        e ? BannerItem.toJSON(e) : undefined,
      );
    } else {
      obj.items = [];
    }
    return obj;
  },

  create(base?: DeepPartial<GetBannerItemsReply>): GetBannerItemsReply {
    return GetBannerItemsReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetBannerItemsReply>): GetBannerItemsReply {
    const message = createBaseGetBannerItemsReply();
    message.items = object.items?.map((e) => BannerItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetUsageRequest(): SetUsageRequest {
  return { usage: undefined };
}

export const SetUsageRequest = {
  encode(
    message: SetUsageRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.usage !== undefined) {
      Usage.encode(message.usage, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUsageRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUsageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.usage = Usage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetUsageRequest {
    return {
      usage: isSet(object.usage) ? Usage.fromJSON(object.usage) : undefined,
    };
  },

  toJSON(message: SetUsageRequest): unknown {
    const obj: any = {};
    message.usage !== undefined &&
      (obj.usage = message.usage ? Usage.toJSON(message.usage) : undefined);
    return obj;
  },

  create(base?: DeepPartial<SetUsageRequest>): SetUsageRequest {
    return SetUsageRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SetUsageRequest>): SetUsageRequest {
    const message = createBaseSetUsageRequest();
    message.usage =
      object.usage !== undefined && object.usage !== null
        ? Usage.fromPartial(object.usage)
        : undefined;
    return message;
  },
};

function createBaseSetUsageReply(): SetUsageReply {
  return { allowed: false };
}

export const SetUsageReply = {
  encode(
    message: SetUsageReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.allowed === true) {
      writer.uint32(8).bool(message.allowed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUsageReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUsageReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.allowed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetUsageReply {
    return { allowed: isSet(object.allowed) ? Boolean(object.allowed) : false };
  },

  toJSON(message: SetUsageReply): unknown {
    const obj: any = {};
    message.allowed !== undefined && (obj.allowed = message.allowed);
    return obj;
  },

  create(base?: DeepPartial<SetUsageReply>): SetUsageReply {
    return SetUsageReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SetUsageReply>): SetUsageReply {
    const message = createBaseSetUsageReply();
    message.allowed = object.allowed ?? false;
    return message;
  },
};

function createBaseUseInvitationCodeRequest(): UseInvitationCodeRequest {
  return { code: '', attribution: false };
}

export const UseInvitationCodeRequest = {
  encode(
    message: UseInvitationCodeRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.code !== '') {
      writer.uint32(10).string(message.code);
    }
    if (message.attribution === true) {
      writer.uint32(16).bool(message.attribution);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UseInvitationCodeRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUseInvitationCodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.attribution = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UseInvitationCodeRequest {
    return {
      code: isSet(object.code) ? String(object.code) : '',
      attribution: isSet(object.attribution)
        ? Boolean(object.attribution)
        : false,
    };
  },

  toJSON(message: UseInvitationCodeRequest): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    message.attribution !== undefined &&
      (obj.attribution = message.attribution);
    return obj;
  },

  create(
    base?: DeepPartial<UseInvitationCodeRequest>,
  ): UseInvitationCodeRequest {
    return UseInvitationCodeRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<UseInvitationCodeRequest>,
  ): UseInvitationCodeRequest {
    const message = createBaseUseInvitationCodeRequest();
    message.code = object.code ?? '';
    message.attribution = object.attribution ?? false;
    return message;
  },
};

function createBaseUseInvitationCodeReply(): UseInvitationCodeReply {
  return {};
}

export const UseInvitationCodeReply = {
  encode(
    _: UseInvitationCodeReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UseInvitationCodeReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUseInvitationCodeReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UseInvitationCodeReply {
    return {};
  },

  toJSON(_: UseInvitationCodeReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<UseInvitationCodeReply>): UseInvitationCodeReply {
    return UseInvitationCodeReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<UseInvitationCodeReply>): UseInvitationCodeReply {
    const message = createBaseUseInvitationCodeReply();
    return message;
  },
};

function createBaseSkipInvitationCodeRequest(): SkipInvitationCodeRequest {
  return {};
}

export const SkipInvitationCodeRequest = {
  encode(
    _: SkipInvitationCodeRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SkipInvitationCodeRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSkipInvitationCodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SkipInvitationCodeRequest {
    return {};
  },

  toJSON(_: SkipInvitationCodeRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<SkipInvitationCodeRequest>,
  ): SkipInvitationCodeRequest {
    return SkipInvitationCodeRequest.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<SkipInvitationCodeRequest>,
  ): SkipInvitationCodeRequest {
    const message = createBaseSkipInvitationCodeRequest();
    return message;
  },
};

function createBaseSkipInvitationCodeReply(): SkipInvitationCodeReply {
  return {};
}

export const SkipInvitationCodeReply = {
  encode(
    _: SkipInvitationCodeReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SkipInvitationCodeReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSkipInvitationCodeReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SkipInvitationCodeReply {
    return {};
  },

  toJSON(_: SkipInvitationCodeReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<SkipInvitationCodeReply>): SkipInvitationCodeReply {
    return SkipInvitationCodeReply.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<SkipInvitationCodeReply>,
  ): SkipInvitationCodeReply {
    const message = createBaseSkipInvitationCodeReply();
    return message;
  },
};

function createBaseSetSourceRequest(): SetSourceRequest {
  return { source: undefined };
}

export const SetSourceRequest = {
  encode(
    message: SetSourceRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.source !== undefined) {
      Source.encode(message.source, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetSourceRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSourceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.source = Source.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetSourceRequest {
    return {
      source: isSet(object.source) ? Source.fromJSON(object.source) : undefined,
    };
  },

  toJSON(message: SetSourceRequest): unknown {
    const obj: any = {};
    message.source !== undefined &&
      (obj.source = message.source ? Source.toJSON(message.source) : undefined);
    return obj;
  },

  create(base?: DeepPartial<SetSourceRequest>): SetSourceRequest {
    return SetSourceRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SetSourceRequest>): SetSourceRequest {
    const message = createBaseSetSourceRequest();
    message.source =
      object.source !== undefined && object.source !== null
        ? Source.fromPartial(object.source)
        : undefined;
    return message;
  },
};

function createBaseSetSourceReply(): SetSourceReply {
  return {};
}

export const SetSourceReply = {
  encode(
    _: SetSourceReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetSourceReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSourceReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetSourceReply {
    return {};
  },

  toJSON(_: SetSourceReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<SetSourceReply>): SetSourceReply {
    return SetSourceReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<SetSourceReply>): SetSourceReply {
    const message = createBaseSetSourceReply();
    return message;
  },
};

function createBaseListAccountsRequest(): ListAccountsRequest {
  return {};
}

export const ListAccountsRequest = {
  encode(
    _: ListAccountsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAccountsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAccountsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListAccountsRequest {
    return {};
  },

  toJSON(_: ListAccountsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<ListAccountsRequest>): ListAccountsRequest {
    return ListAccountsRequest.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<ListAccountsRequest>): ListAccountsRequest {
    const message = createBaseListAccountsRequest();
    return message;
  },
};

function createBaseListAccountsReply(): ListAccountsReply {
  return { accounts: [] };
}

export const ListAccountsReply = {
  encode(
    message: ListAccountsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.accounts) {
      Account.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAccountsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAccountsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accounts.push(Account.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAccountsReply {
    return {
      accounts: Array.isArray(object?.accounts)
        ? object.accounts.map((e: any) => Account.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListAccountsReply): unknown {
    const obj: any = {};
    if (message.accounts) {
      obj.accounts = message.accounts.map((e) =>
        e ? Account.toJSON(e) : undefined,
      );
    } else {
      obj.accounts = [];
    }
    return obj;
  },

  create(base?: DeepPartial<ListAccountsReply>): ListAccountsReply {
    return ListAccountsReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ListAccountsReply>): ListAccountsReply {
    const message = createBaseListAccountsReply();
    message.accounts =
      object.accounts?.map((e) => Account.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetPostLoginActionRequest(): GetPostLoginActionRequest {
  return {};
}

export const GetPostLoginActionRequest = {
  encode(
    _: GetPostLoginActionRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetPostLoginActionRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPostLoginActionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetPostLoginActionRequest {
    return {};
  },

  toJSON(_: GetPostLoginActionRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<GetPostLoginActionRequest>,
  ): GetPostLoginActionRequest {
    return GetPostLoginActionRequest.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<GetPostLoginActionRequest>,
  ): GetPostLoginActionRequest {
    const message = createBaseGetPostLoginActionRequest();
    return message;
  },
};

function createBaseGetPostLoginActionReply(): GetPostLoginActionReply {
  return { action: undefined };
}

export const GetPostLoginActionReply = {
  encode(
    message: GetPostLoginActionReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.action !== undefined) {
      PostLoginAction.encode(message.action, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetPostLoginActionReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPostLoginActionReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.action = PostLoginAction.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPostLoginActionReply {
    return {
      action: isSet(object.action)
        ? PostLoginAction.fromJSON(object.action)
        : undefined,
    };
  },

  toJSON(message: GetPostLoginActionReply): unknown {
    const obj: any = {};
    message.action !== undefined &&
      (obj.action = message.action
        ? PostLoginAction.toJSON(message.action)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetPostLoginActionReply>): GetPostLoginActionReply {
    return GetPostLoginActionReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetPostLoginActionReply>,
  ): GetPostLoginActionReply {
    const message = createBaseGetPostLoginActionReply();
    message.action =
      object.action !== undefined && object.action !== null
        ? PostLoginAction.fromPartial(object.action)
        : undefined;
    return message;
  },
};

function createBaseGetPostLoginActionsRequest(): GetPostLoginActionsRequest {
  return {};
}

export const GetPostLoginActionsRequest = {
  encode(
    _: GetPostLoginActionsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetPostLoginActionsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPostLoginActionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetPostLoginActionsRequest {
    return {};
  },

  toJSON(_: GetPostLoginActionsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<GetPostLoginActionsRequest>,
  ): GetPostLoginActionsRequest {
    return GetPostLoginActionsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<GetPostLoginActionsRequest>,
  ): GetPostLoginActionsRequest {
    const message = createBaseGetPostLoginActionsRequest();
    return message;
  },
};

function createBaseGetPostLoginActionsReply(): GetPostLoginActionsReply {
  return { actions: [] };
}

export const GetPostLoginActionsReply = {
  encode(
    message: GetPostLoginActionsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.actions) {
      PostLoginAction.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetPostLoginActionsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPostLoginActionsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.actions.push(PostLoginAction.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPostLoginActionsReply {
    return {
      actions: Array.isArray(object?.actions)
        ? object.actions.map((e: any) => PostLoginAction.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetPostLoginActionsReply): unknown {
    const obj: any = {};
    if (message.actions) {
      obj.actions = message.actions.map((e) =>
        e ? PostLoginAction.toJSON(e) : undefined,
      );
    } else {
      obj.actions = [];
    }
    return obj;
  },

  create(
    base?: DeepPartial<GetPostLoginActionsReply>,
  ): GetPostLoginActionsReply {
    return GetPostLoginActionsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetPostLoginActionsReply>,
  ): GetPostLoginActionsReply {
    const message = createBaseGetPostLoginActionsReply();
    message.actions =
      object.actions?.map((e) => PostLoginAction.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateAccountRequest(): UpdateAccountRequest {
  return { profilePicture: '' };
}

export const UpdateAccountRequest = {
  encode(
    message: UpdateAccountRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.profilePicture !== '') {
      writer.uint32(10).string(message.profilePicture);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UpdateAccountRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.profilePicture = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountRequest {
    return {
      profilePicture: isSet(object.profilePicture)
        ? String(object.profilePicture)
        : '',
    };
  },

  toJSON(message: UpdateAccountRequest): unknown {
    const obj: any = {};
    message.profilePicture !== undefined &&
      (obj.profilePicture = message.profilePicture);
    return obj;
  },

  create(base?: DeepPartial<UpdateAccountRequest>): UpdateAccountRequest {
    return UpdateAccountRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<UpdateAccountRequest>): UpdateAccountRequest {
    const message = createBaseUpdateAccountRequest();
    message.profilePicture = object.profilePicture ?? '';
    return message;
  },
};

function createBaseUpdateAccountReply(): UpdateAccountReply {
  return { account: undefined };
}

export const UpdateAccountReply = {
  encode(
    message: UpdateAccountReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.account !== undefined) {
      Account.encode(message.account, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.account = Account.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountReply {
    return {
      account: isSet(object.account)
        ? Account.fromJSON(object.account)
        : undefined,
    };
  },

  toJSON(message: UpdateAccountReply): unknown {
    const obj: any = {};
    message.account !== undefined &&
      (obj.account = message.account
        ? Account.toJSON(message.account)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<UpdateAccountReply>): UpdateAccountReply {
    return UpdateAccountReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<UpdateAccountReply>): UpdateAccountReply {
    const message = createBaseUpdateAccountReply();
    message.account =
      object.account !== undefined && object.account !== null
        ? Account.fromPartial(object.account)
        : undefined;
    return message;
  },
};

function createBaseGetSendOptionsRequest(): GetSendOptionsRequest {
  return {};
}

export const GetSendOptionsRequest = {
  encode(
    _: GetSendOptionsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetSendOptionsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSendOptionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetSendOptionsRequest {
    return {};
  },

  toJSON(_: GetSendOptionsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<GetSendOptionsRequest>): GetSendOptionsRequest {
    return GetSendOptionsRequest.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<GetSendOptionsRequest>): GetSendOptionsRequest {
    const message = createBaseGetSendOptionsRequest();
    return message;
  },
};

function createBaseGetSendOptionsReply(): GetSendOptionsReply {
  return { sendOptions: {}, banners: {} };
}

export const GetSendOptionsReply = {
  encode(
    message: GetSendOptionsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    Object.entries(message.sendOptions).forEach(([key, value]) => {
      GetSendOptionsReply_SendOptionsEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork(),
      ).ldelim();
    });
    Object.entries(message.banners).forEach(([key, value]) => {
      GetSendOptionsReply_BannersEntry.encode(
        { key: key as any, value },
        writer.uint32(18).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSendOptionsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSendOptionsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = GetSendOptionsReply_SendOptionsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry1.value !== undefined) {
            message.sendOptions[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = GetSendOptionsReply_BannersEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry2.value !== undefined) {
            message.banners[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSendOptionsReply {
    return {
      sendOptions: isObject(object.sendOptions)
        ? Object.entries(object.sendOptions).reduce<{
            [key: string]: SendOptions;
          }>((acc, [key, value]) => {
            acc[key] = SendOptions.fromJSON(value);
            return acc;
          }, {})
        : {},
      banners: isObject(object.banners)
        ? Object.entries(object.banners).reduce<{
            [key: string]: SendOptionBanners;
          }>((acc, [key, value]) => {
            acc[key] = SendOptionBanners.fromJSON(value);
            return acc;
          }, {})
        : {},
    };
  },

  toJSON(message: GetSendOptionsReply): unknown {
    const obj: any = {};
    obj.sendOptions = {};
    if (message.sendOptions) {
      Object.entries(message.sendOptions).forEach(([k, v]) => {
        obj.sendOptions[k] = SendOptions.toJSON(v);
      });
    }
    obj.banners = {};
    if (message.banners) {
      Object.entries(message.banners).forEach(([k, v]) => {
        obj.banners[k] = SendOptionBanners.toJSON(v);
      });
    }
    return obj;
  },

  create(base?: DeepPartial<GetSendOptionsReply>): GetSendOptionsReply {
    return GetSendOptionsReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetSendOptionsReply>): GetSendOptionsReply {
    const message = createBaseGetSendOptionsReply();
    message.sendOptions = Object.entries(object.sendOptions ?? {}).reduce<{
      [key: string]: SendOptions;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = SendOptions.fromPartial(value);
      }
      return acc;
    }, {});
    message.banners = Object.entries(object.banners ?? {}).reduce<{
      [key: string]: SendOptionBanners;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = SendOptionBanners.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseGetSendOptionsReply_SendOptionsEntry(): GetSendOptionsReply_SendOptionsEntry {
  return { key: '', value: undefined };
}

export const GetSendOptionsReply_SendOptionsEntry = {
  encode(
    message: GetSendOptionsReply_SendOptionsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      SendOptions.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetSendOptionsReply_SendOptionsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSendOptionsReply_SendOptionsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = SendOptions.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSendOptionsReply_SendOptionsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value)
        ? SendOptions.fromJSON(object.value)
        : undefined,
    };
  },

  toJSON(message: GetSendOptionsReply_SendOptionsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value
        ? SendOptions.toJSON(message.value)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<GetSendOptionsReply_SendOptionsEntry>,
  ): GetSendOptionsReply_SendOptionsEntry {
    return GetSendOptionsReply_SendOptionsEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetSendOptionsReply_SendOptionsEntry>,
  ): GetSendOptionsReply_SendOptionsEntry {
    const message = createBaseGetSendOptionsReply_SendOptionsEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? SendOptions.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseGetSendOptionsReply_BannersEntry(): GetSendOptionsReply_BannersEntry {
  return { key: '', value: undefined };
}

export const GetSendOptionsReply_BannersEntry = {
  encode(
    message: GetSendOptionsReply_BannersEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      SendOptionBanners.encode(
        message.value,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetSendOptionsReply_BannersEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSendOptionsReply_BannersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = SendOptionBanners.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSendOptionsReply_BannersEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value)
        ? SendOptionBanners.fromJSON(object.value)
        : undefined,
    };
  },

  toJSON(message: GetSendOptionsReply_BannersEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value
        ? SendOptionBanners.toJSON(message.value)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<GetSendOptionsReply_BannersEntry>,
  ): GetSendOptionsReply_BannersEntry {
    return GetSendOptionsReply_BannersEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetSendOptionsReply_BannersEntry>,
  ): GetSendOptionsReply_BannersEntry {
    const message = createBaseGetSendOptionsReply_BannersEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? SendOptionBanners.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseListMembersRequest(): ListMembersRequest {
  return { includeDeleted: false };
}

export const ListMembersRequest = {
  encode(
    message: ListMembersRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.includeDeleted === true) {
      writer.uint32(8).bool(message.includeDeleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMembersRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMembersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.includeDeleted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMembersRequest {
    return {
      includeDeleted: isSet(object.includeDeleted)
        ? Boolean(object.includeDeleted)
        : false,
    };
  },

  toJSON(message: ListMembersRequest): unknown {
    const obj: any = {};
    message.includeDeleted !== undefined &&
      (obj.includeDeleted = message.includeDeleted);
    return obj;
  },

  create(base?: DeepPartial<ListMembersRequest>): ListMembersRequest {
    return ListMembersRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ListMembersRequest>): ListMembersRequest {
    const message = createBaseListMembersRequest();
    message.includeDeleted = object.includeDeleted ?? false;
    return message;
  },
};

function createBaseListMembersReply(): ListMembersReply {
  return { members: [] };
}

export const ListMembersReply = {
  encode(
    message: ListMembersReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.members) {
      Member.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMembersReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMembersReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.members.push(Member.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMembersReply {
    return {
      members: Array.isArray(object?.members)
        ? object.members.map((e: any) => Member.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListMembersReply): unknown {
    const obj: any = {};
    if (message.members) {
      obj.members = message.members.map((e) =>
        e ? Member.toJSON(e) : undefined,
      );
    } else {
      obj.members = [];
    }
    return obj;
  },

  create(base?: DeepPartial<ListMembersReply>): ListMembersReply {
    return ListMembersReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ListMembersReply>): ListMembersReply {
    const message = createBaseListMembersReply();
    message.members = object.members?.map((e) => Member.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListRolesRequest(): ListRolesRequest {
  return {};
}

export const ListRolesRequest = {
  encode(
    _: ListRolesRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRolesRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRolesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListRolesRequest {
    return {};
  },

  toJSON(_: ListRolesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<ListRolesRequest>): ListRolesRequest {
    return ListRolesRequest.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<ListRolesRequest>): ListRolesRequest {
    const message = createBaseListRolesRequest();
    return message;
  },
};

function createBaseListRolesReply(): ListRolesReply {
  return { roles: [] };
}

export const ListRolesReply = {
  encode(
    message: ListRolesReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.roles) {
      MembershipRoleWithPermissions.encode(
        v!,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRolesReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRolesReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roles.push(
            MembershipRoleWithPermissions.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRolesReply {
    return {
      roles: Array.isArray(object?.roles)
        ? object.roles.map((e: any) =>
            MembershipRoleWithPermissions.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: ListRolesReply): unknown {
    const obj: any = {};
    if (message.roles) {
      obj.roles = message.roles.map((e) =>
        e ? MembershipRoleWithPermissions.toJSON(e) : undefined,
      );
    } else {
      obj.roles = [];
    }
    return obj;
  },

  create(base?: DeepPartial<ListRolesReply>): ListRolesReply {
    return ListRolesReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ListRolesReply>): ListRolesReply {
    const message = createBaseListRolesReply();
    message.roles =
      object.roles?.map((e) => MembershipRoleWithPermissions.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseInviteMemberRequest(): InviteMemberRequest {
  return { email: '', phoneNumber: '', roles: [] };
}

export const InviteMemberRequest = {
  encode(
    message: InviteMemberRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.email !== '') {
      writer.uint32(10).string(message.email);
    }
    if (message.phoneNumber !== '') {
      writer.uint32(18).string(message.phoneNumber);
    }
    writer.uint32(26).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteMemberRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteMemberRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 3:
          if (tag === 24) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InviteMemberRequest {
    return {
      email: isSet(object.email) ? String(object.email) : '',
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : '',
      roles: Array.isArray(object?.roles)
        ? object.roles.map((e: any) => membershipRoleFromJSON(e))
        : [],
    };
  },

  toJSON(message: InviteMemberRequest): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.phoneNumber !== undefined &&
      (obj.phoneNumber = message.phoneNumber);
    if (message.roles) {
      obj.roles = message.roles.map((e) => membershipRoleToJSON(e));
    } else {
      obj.roles = [];
    }
    return obj;
  },

  create(base?: DeepPartial<InviteMemberRequest>): InviteMemberRequest {
    return InviteMemberRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<InviteMemberRequest>): InviteMemberRequest {
    const message = createBaseInviteMemberRequest();
    message.email = object.email ?? '';
    message.phoneNumber = object.phoneNumber ?? '';
    message.roles = object.roles?.map((e) => e) || [];
    return message;
  },
};

function createBaseInviteMemberReply(): InviteMemberReply {
  return { invitation: undefined };
}

export const InviteMemberReply = {
  encode(
    message: InviteMemberReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.invitation !== undefined) {
      MembershipInvitation.encode(
        message.invitation,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteMemberReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteMemberReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.invitation = MembershipInvitation.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InviteMemberReply {
    return {
      invitation: isSet(object.invitation)
        ? MembershipInvitation.fromJSON(object.invitation)
        : undefined,
    };
  },

  toJSON(message: InviteMemberReply): unknown {
    const obj: any = {};
    message.invitation !== undefined &&
      (obj.invitation = message.invitation
        ? MembershipInvitation.toJSON(message.invitation)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<InviteMemberReply>): InviteMemberReply {
    return InviteMemberReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<InviteMemberReply>): InviteMemberReply {
    const message = createBaseInviteMemberReply();
    message.invitation =
      object.invitation !== undefined && object.invitation !== null
        ? MembershipInvitation.fromPartial(object.invitation)
        : undefined;
    return message;
  },
};

function createBaseJoinAccountRequest(): JoinAccountRequest {
  return { invitationId: '', accountId: '' };
}

export const JoinAccountRequest = {
  encode(
    message: JoinAccountRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.invitationId !== '') {
      writer.uint32(10).string(message.invitationId);
    }
    if (message.accountId !== '') {
      writer.uint32(18).string(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JoinAccountRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.invitationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JoinAccountRequest {
    return {
      invitationId: isSet(object.invitationId)
        ? String(object.invitationId)
        : '',
      accountId: isSet(object.accountId) ? String(object.accountId) : '',
    };
  },

  toJSON(message: JoinAccountRequest): unknown {
    const obj: any = {};
    message.invitationId !== undefined &&
      (obj.invitationId = message.invitationId);
    message.accountId !== undefined && (obj.accountId = message.accountId);
    return obj;
  },

  create(base?: DeepPartial<JoinAccountRequest>): JoinAccountRequest {
    return JoinAccountRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<JoinAccountRequest>): JoinAccountRequest {
    const message = createBaseJoinAccountRequest();
    message.invitationId = object.invitationId ?? '';
    message.accountId = object.accountId ?? '';
    return message;
  },
};

function createBaseJoinAccountReply(): JoinAccountReply {
  return {};
}

export const JoinAccountReply = {
  encode(
    _: JoinAccountReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JoinAccountReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinAccountReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): JoinAccountReply {
    return {};
  },

  toJSON(_: JoinAccountReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<JoinAccountReply>): JoinAccountReply {
    return JoinAccountReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<JoinAccountReply>): JoinAccountReply {
    const message = createBaseJoinAccountReply();
    return message;
  },
};

function createBaseUpdateMemberRequest(): UpdateMemberRequest {
  return { userId: '', roles: [], status: 0 };
}

export const UpdateMemberRequest = {
  encode(
    message: UpdateMemberRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.userId !== '') {
      writer.uint32(10).string(message.userId);
    }
    writer.uint32(18).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMemberRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMemberRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMemberRequest {
    return {
      userId: isSet(object.userId) ? String(object.userId) : '',
      roles: Array.isArray(object?.roles)
        ? object.roles.map((e: any) => membershipRoleFromJSON(e))
        : [],
      status: isSet(object.status)
        ? membershipStatusFromJSON(object.status)
        : 0,
    };
  },

  toJSON(message: UpdateMemberRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    if (message.roles) {
      obj.roles = message.roles.map((e) => membershipRoleToJSON(e));
    } else {
      obj.roles = [];
    }
    message.status !== undefined &&
      (obj.status = membershipStatusToJSON(message.status));
    return obj;
  },

  create(base?: DeepPartial<UpdateMemberRequest>): UpdateMemberRequest {
    return UpdateMemberRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<UpdateMemberRequest>): UpdateMemberRequest {
    const message = createBaseUpdateMemberRequest();
    message.userId = object.userId ?? '';
    message.roles = object.roles?.map((e) => e) || [];
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseUpdateMemberReply(): UpdateMemberReply {
  return { membership: undefined };
}

export const UpdateMemberReply = {
  encode(
    message: UpdateMemberReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.membership !== undefined) {
      Membership.encode(message.membership, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMemberReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMemberReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.membership = Membership.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMemberReply {
    return {
      membership: isSet(object.membership)
        ? Membership.fromJSON(object.membership)
        : undefined,
    };
  },

  toJSON(message: UpdateMemberReply): unknown {
    const obj: any = {};
    message.membership !== undefined &&
      (obj.membership = message.membership
        ? Membership.toJSON(message.membership)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<UpdateMemberReply>): UpdateMemberReply {
    return UpdateMemberReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<UpdateMemberReply>): UpdateMemberReply {
    const message = createBaseUpdateMemberReply();
    message.membership =
      object.membership !== undefined && object.membership !== null
        ? Membership.fromPartial(object.membership)
        : undefined;
    return message;
  },
};

function createBaseRemoveMemberRequest(): RemoveMemberRequest {
  return { userId: '' };
}

export const RemoveMemberRequest = {
  encode(
    message: RemoveMemberRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.userId !== '') {
      writer.uint32(10).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveMemberRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveMemberRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveMemberRequest {
    return { userId: isSet(object.userId) ? String(object.userId) : '' };
  },

  toJSON(message: RemoveMemberRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  create(base?: DeepPartial<RemoveMemberRequest>): RemoveMemberRequest {
    return RemoveMemberRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<RemoveMemberRequest>): RemoveMemberRequest {
    const message = createBaseRemoveMemberRequest();
    message.userId = object.userId ?? '';
    return message;
  },
};

function createBaseRemoveMemberReply(): RemoveMemberReply {
  return {};
}

export const RemoveMemberReply = {
  encode(
    _: RemoveMemberReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveMemberReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveMemberReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RemoveMemberReply {
    return {};
  },

  toJSON(_: RemoveMemberReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<RemoveMemberReply>): RemoveMemberReply {
    return RemoveMemberReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<RemoveMemberReply>): RemoveMemberReply {
    const message = createBaseRemoveMemberReply();
    return message;
  },
};

function createBaseListMembershipInvitationsRequest(): ListMembershipInvitationsRequest {
  return { includeUsed: false };
}

export const ListMembershipInvitationsRequest = {
  encode(
    message: ListMembershipInvitationsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.includeUsed === true) {
      writer.uint32(8).bool(message.includeUsed);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListMembershipInvitationsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMembershipInvitationsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.includeUsed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMembershipInvitationsRequest {
    return {
      includeUsed: isSet(object.includeUsed)
        ? Boolean(object.includeUsed)
        : false,
    };
  },

  toJSON(message: ListMembershipInvitationsRequest): unknown {
    const obj: any = {};
    message.includeUsed !== undefined &&
      (obj.includeUsed = message.includeUsed);
    return obj;
  },

  create(
    base?: DeepPartial<ListMembershipInvitationsRequest>,
  ): ListMembershipInvitationsRequest {
    return ListMembershipInvitationsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListMembershipInvitationsRequest>,
  ): ListMembershipInvitationsRequest {
    const message = createBaseListMembershipInvitationsRequest();
    message.includeUsed = object.includeUsed ?? false;
    return message;
  },
};

function createBaseListMembershipInvitationsReply(): ListMembershipInvitationsReply {
  return { invitations: [] };
}

export const ListMembershipInvitationsReply = {
  encode(
    message: ListMembershipInvitationsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.invitations) {
      MembershipInvitation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListMembershipInvitationsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMembershipInvitationsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.invitations.push(
            MembershipInvitation.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMembershipInvitationsReply {
    return {
      invitations: Array.isArray(object?.invitations)
        ? object.invitations.map((e: any) => MembershipInvitation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListMembershipInvitationsReply): unknown {
    const obj: any = {};
    if (message.invitations) {
      obj.invitations = message.invitations.map((e) =>
        e ? MembershipInvitation.toJSON(e) : undefined,
      );
    } else {
      obj.invitations = [];
    }
    return obj;
  },

  create(
    base?: DeepPartial<ListMembershipInvitationsReply>,
  ): ListMembershipInvitationsReply {
    return ListMembershipInvitationsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListMembershipInvitationsReply>,
  ): ListMembershipInvitationsReply {
    const message = createBaseListMembershipInvitationsReply();
    message.invitations =
      object.invitations?.map((e) => MembershipInvitation.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteMembershipInvitationRequest(): DeleteMembershipInvitationRequest {
  return { invitationId: '' };
}

export const DeleteMembershipInvitationRequest = {
  encode(
    message: DeleteMembershipInvitationRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.invitationId !== '') {
      writer.uint32(10).string(message.invitationId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeleteMembershipInvitationRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteMembershipInvitationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.invitationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteMembershipInvitationRequest {
    return {
      invitationId: isSet(object.invitationId)
        ? String(object.invitationId)
        : '',
    };
  },

  toJSON(message: DeleteMembershipInvitationRequest): unknown {
    const obj: any = {};
    message.invitationId !== undefined &&
      (obj.invitationId = message.invitationId);
    return obj;
  },

  create(
    base?: DeepPartial<DeleteMembershipInvitationRequest>,
  ): DeleteMembershipInvitationRequest {
    return DeleteMembershipInvitationRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<DeleteMembershipInvitationRequest>,
  ): DeleteMembershipInvitationRequest {
    const message = createBaseDeleteMembershipInvitationRequest();
    message.invitationId = object.invitationId ?? '';
    return message;
  },
};

function createBaseDeleteMembershipInvitationReply(): DeleteMembershipInvitationReply {
  return {};
}

export const DeleteMembershipInvitationReply = {
  encode(
    _: DeleteMembershipInvitationReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeleteMembershipInvitationReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteMembershipInvitationReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeleteMembershipInvitationReply {
    return {};
  },

  toJSON(_: DeleteMembershipInvitationReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<DeleteMembershipInvitationReply>,
  ): DeleteMembershipInvitationReply {
    return DeleteMembershipInvitationReply.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<DeleteMembershipInvitationReply>,
  ): DeleteMembershipInvitationReply {
    const message = createBaseDeleteMembershipInvitationReply();
    return message;
  },
};

function createBaseAcceptTermsRequest(): AcceptTermsRequest {
  return { type: 0 };
}

export const AcceptTermsRequest = {
  encode(
    message: AcceptTermsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcceptTermsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcceptTermsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AcceptTermsRequest {
    return { type: isSet(object.type) ? termsTypeFromJSON(object.type) : 0 };
  },

  toJSON(message: AcceptTermsRequest): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = termsTypeToJSON(message.type));
    return obj;
  },

  create(base?: DeepPartial<AcceptTermsRequest>): AcceptTermsRequest {
    return AcceptTermsRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<AcceptTermsRequest>): AcceptTermsRequest {
    const message = createBaseAcceptTermsRequest();
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseAcceptTermsReply(): AcceptTermsReply {
  return {};
}

export const AcceptTermsReply = {
  encode(
    _: AcceptTermsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcceptTermsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcceptTermsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): AcceptTermsReply {
    return {};
  },

  toJSON(_: AcceptTermsReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<AcceptTermsReply>): AcceptTermsReply {
    return AcceptTermsReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<AcceptTermsReply>): AcceptTermsReply {
    const message = createBaseAcceptTermsReply();
    return message;
  },
};

function createBaseGetRequiredActionsForPeerToPeerSetupRequest(): GetRequiredActionsForPeerToPeerSetupRequest {
  return {};
}

export const GetRequiredActionsForPeerToPeerSetupRequest = {
  encode(
    _: GetRequiredActionsForPeerToPeerSetupRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetRequiredActionsForPeerToPeerSetupRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRequiredActionsForPeerToPeerSetupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetRequiredActionsForPeerToPeerSetupRequest {
    return {};
  },

  toJSON(_: GetRequiredActionsForPeerToPeerSetupRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<GetRequiredActionsForPeerToPeerSetupRequest>,
  ): GetRequiredActionsForPeerToPeerSetupRequest {
    return GetRequiredActionsForPeerToPeerSetupRequest.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<GetRequiredActionsForPeerToPeerSetupRequest>,
  ): GetRequiredActionsForPeerToPeerSetupRequest {
    const message = createBaseGetRequiredActionsForPeerToPeerSetupRequest();
    return message;
  },
};

function createBaseGetRequiredActionsForPeerToPeerSetupReply(): GetRequiredActionsForPeerToPeerSetupReply {
  return { action: undefined };
}

export const GetRequiredActionsForPeerToPeerSetupReply = {
  encode(
    message: GetRequiredActionsForPeerToPeerSetupReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.action !== undefined) {
      Action.encode(message.action, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetRequiredActionsForPeerToPeerSetupReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRequiredActionsForPeerToPeerSetupReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.action = Action.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRequiredActionsForPeerToPeerSetupReply {
    return {
      action: isSet(object.action) ? Action.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: GetRequiredActionsForPeerToPeerSetupReply): unknown {
    const obj: any = {};
    message.action !== undefined &&
      (obj.action = message.action ? Action.toJSON(message.action) : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<GetRequiredActionsForPeerToPeerSetupReply>,
  ): GetRequiredActionsForPeerToPeerSetupReply {
    return GetRequiredActionsForPeerToPeerSetupReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetRequiredActionsForPeerToPeerSetupReply>,
  ): GetRequiredActionsForPeerToPeerSetupReply {
    const message = createBaseGetRequiredActionsForPeerToPeerSetupReply();
    message.action =
      object.action !== undefined && object.action !== null
        ? Action.fromPartial(object.action)
        : undefined;
    return message;
  },
};

function createBaseClaimPeerToPeerTagRequest(): ClaimPeerToPeerTagRequest {
  return { tag: '' };
}

export const ClaimPeerToPeerTagRequest = {
  encode(
    message: ClaimPeerToPeerTagRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.tag !== '') {
      writer.uint32(10).string(message.tag);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ClaimPeerToPeerTagRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClaimPeerToPeerTagRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClaimPeerToPeerTagRequest {
    return { tag: isSet(object.tag) ? String(object.tag) : '' };
  },

  toJSON(message: ClaimPeerToPeerTagRequest): unknown {
    const obj: any = {};
    message.tag !== undefined && (obj.tag = message.tag);
    return obj;
  },

  create(
    base?: DeepPartial<ClaimPeerToPeerTagRequest>,
  ): ClaimPeerToPeerTagRequest {
    return ClaimPeerToPeerTagRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ClaimPeerToPeerTagRequest>,
  ): ClaimPeerToPeerTagRequest {
    const message = createBaseClaimPeerToPeerTagRequest();
    message.tag = object.tag ?? '';
    return message;
  },
};

function createBaseClaimPeerToPeerTagReply(): ClaimPeerToPeerTagReply {
  return {};
}

export const ClaimPeerToPeerTagReply = {
  encode(
    _: ClaimPeerToPeerTagReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ClaimPeerToPeerTagReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClaimPeerToPeerTagReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ClaimPeerToPeerTagReply {
    return {};
  },

  toJSON(_: ClaimPeerToPeerTagReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<ClaimPeerToPeerTagReply>): ClaimPeerToPeerTagReply {
    return ClaimPeerToPeerTagReply.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<ClaimPeerToPeerTagReply>,
  ): ClaimPeerToPeerTagReply {
    const message = createBaseClaimPeerToPeerTagReply();
    return message;
  },
};

function createBaseListFeaturesRequest(): ListFeaturesRequest {
  return {};
}

export const ListFeaturesRequest = {
  encode(
    _: ListFeaturesRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFeaturesRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFeaturesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListFeaturesRequest {
    return {};
  },

  toJSON(_: ListFeaturesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<ListFeaturesRequest>): ListFeaturesRequest {
    return ListFeaturesRequest.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<ListFeaturesRequest>): ListFeaturesRequest {
    const message = createBaseListFeaturesRequest();
    return message;
  },
};

function createBaseListFeaturesReply(): ListFeaturesReply {
  return { features: {} };
}

export const ListFeaturesReply = {
  encode(
    message: ListFeaturesReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    Object.entries(message.features).forEach(([key, value]) => {
      ListFeaturesReply_FeaturesEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFeaturesReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFeaturesReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = ListFeaturesReply_FeaturesEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry1.value !== undefined) {
            message.features[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListFeaturesReply {
    return {
      features: isObject(object.features)
        ? Object.entries(object.features).reduce<{ [key: string]: Feature }>(
            (acc, [key, value]) => {
              acc[key] = Feature.fromJSON(value);
              return acc;
            },
            {},
          )
        : {},
    };
  },

  toJSON(message: ListFeaturesReply): unknown {
    const obj: any = {};
    obj.features = {};
    if (message.features) {
      Object.entries(message.features).forEach(([k, v]) => {
        obj.features[k] = Feature.toJSON(v);
      });
    }
    return obj;
  },

  create(base?: DeepPartial<ListFeaturesReply>): ListFeaturesReply {
    return ListFeaturesReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ListFeaturesReply>): ListFeaturesReply {
    const message = createBaseListFeaturesReply();
    message.features = Object.entries(object.features ?? {}).reduce<{
      [key: string]: Feature;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Feature.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseListFeaturesReply_FeaturesEntry(): ListFeaturesReply_FeaturesEntry {
  return { key: '', value: undefined };
}

export const ListFeaturesReply_FeaturesEntry = {
  encode(
    message: ListFeaturesReply_FeaturesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Feature.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListFeaturesReply_FeaturesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFeaturesReply_FeaturesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Feature.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListFeaturesReply_FeaturesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? Feature.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ListFeaturesReply_FeaturesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? Feature.toJSON(message.value) : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<ListFeaturesReply_FeaturesEntry>,
  ): ListFeaturesReply_FeaturesEntry {
    return ListFeaturesReply_FeaturesEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListFeaturesReply_FeaturesEntry>,
  ): ListFeaturesReply_FeaturesEntry {
    const message = createBaseListFeaturesReply_FeaturesEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? Feature.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseUpdateFeatureRequest(): UpdateFeatureRequest {
  return { featureName: '', enabled: false };
}

export const UpdateFeatureRequest = {
  encode(
    message: UpdateFeatureRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.featureName !== '') {
      writer.uint32(10).string(message.featureName);
    }
    if (message.enabled === true) {
      writer.uint32(16).bool(message.enabled);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UpdateFeatureRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFeatureRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.featureName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateFeatureRequest {
    return {
      featureName: isSet(object.featureName) ? String(object.featureName) : '',
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : false,
    };
  },

  toJSON(message: UpdateFeatureRequest): unknown {
    const obj: any = {};
    message.featureName !== undefined &&
      (obj.featureName = message.featureName);
    message.enabled !== undefined && (obj.enabled = message.enabled);
    return obj;
  },

  create(base?: DeepPartial<UpdateFeatureRequest>): UpdateFeatureRequest {
    return UpdateFeatureRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<UpdateFeatureRequest>): UpdateFeatureRequest {
    const message = createBaseUpdateFeatureRequest();
    message.featureName = object.featureName ?? '';
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseUpdateFeatureReply(): UpdateFeatureReply {
  return {};
}

export const UpdateFeatureReply = {
  encode(
    _: UpdateFeatureReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFeatureReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFeatureReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateFeatureReply {
    return {};
  },

  toJSON(_: UpdateFeatureReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<UpdateFeatureReply>): UpdateFeatureReply {
    return UpdateFeatureReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<UpdateFeatureReply>): UpdateFeatureReply {
    const message = createBaseUpdateFeatureReply();
    return message;
  },
};

export interface Users {
  /** buf:lint:ignore COMMENT_RPC */
  SignUpWithExternalAuth(
    request: DeepPartial<SignUpWithExternalAuthRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SignUpReply>;
  /** Returns a new token that can be used for authentication for the current user */
  GetSessionToken(
    request: DeepPartial<GetSessionTokenRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetSessionTokenReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetDefaultProfile(
    request: DeepPartial<GetDefaultProfileRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetProfileReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetProfile(
    request: DeepPartial<GetProfileRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetProfileReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetBannerItems(
    request: DeepPartial<GetBannerItemsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetBannerItemsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  RefreshProfile(
    request: DeepPartial<RefreshProfileRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RefreshProfileReply>;
  /** buf:lint:ignore COMMENT_RPC */
  SendContactDetailVerification(
    request: DeepPartial<SendContactDetailVerificationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SendContactDetailVerificationReply>;
  /** buf:lint:ignore COMMENT_RPC */
  VerifyContactDetail(
    request: DeepPartial<VerifyContactDetailRequest>,
    metadata?: grpc.Metadata,
  ): Promise<VerifyContactDetailReply>;
  /** buf:lint:ignore COMMENT_RPC */
  SetUsage(
    request: DeepPartial<SetUsageRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetUsageReply>;
  /** buf:lint:ignore COMMENT_RPC */
  UseInvitationCode(
    request: DeepPartial<UseInvitationCodeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UseInvitationCodeReply>;
  /** buf:lint:ignore COMMENT_RPC */
  SkipInvitationCode(
    request: DeepPartial<SkipInvitationCodeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SkipInvitationCodeReply>;
  /** buf:lint:ignore COMMENT_RPC */
  SetSource(
    request: DeepPartial<SetSourceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetSourceReply>;
  /** buf:lint:ignore COMMENT_RPC */
  ListAccounts(
    request: DeepPartial<ListAccountsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListAccountsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  UpdateAccount(
    request: DeepPartial<UpdateAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateAccountReply>;
  /** Returns the first post login action for the user */
  GetPostLoginAction(
    request: DeepPartial<GetPostLoginActionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetPostLoginActionReply>;
  /** Returns all post login actions for the user */
  GetPostLoginActions(
    request: DeepPartial<GetPostLoginActionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetPostLoginActionsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetSendOptions(
    request: DeepPartial<GetSendOptionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetSendOptionsReply>;
  /** ListMembers lists all the members of the current account along with their roles / status. */
  ListMembers(
    request: DeepPartial<ListMembersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListMembersReply>;
  /**
   * ListRoles will return a list of roles for the account passed in metadata.
   * It also returns a list of all the available permission groups.
   */
  ListRoles(
    request: DeepPartial<ListRolesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListRolesReply>;
  /** InviteMember will create an invitation for a member to join the current account. */
  InviteMember(
    request: DeepPartial<InviteMemberRequest>,
    metadata?: grpc.Metadata,
  ): Promise<InviteMemberReply>;
  /** JoinAccount will join the account with the given invitation id. */
  JoinAccount(
    request: DeepPartial<JoinAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<JoinAccountReply>;
  /** UpdateMember will update the role of a member. Even if you only want to update the status, you must pass the roles as well. */
  UpdateMember(
    request: DeepPartial<UpdateMemberRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateMemberReply>;
  /** RemoveMember will remove a member from the account. */
  RemoveMember(
    request: DeepPartial<RemoveMemberRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveMemberReply>;
  /** ListMembeshipInvitations will list all the pending invitations for the current account. */
  ListMembershipInvitations(
    request: DeepPartial<ListMembershipInvitationsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListMembershipInvitationsReply>;
  /** DeleteMembershipInvitation will delete the invitation with the given id. */
  DeleteMembershipInvitation(
    request: DeepPartial<DeleteMembershipInvitationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteMembershipInvitationReply>;
  /** AcceptTerms will accept the terms and conditions for the current user. */
  AcceptTerms(
    request: DeepPartial<AcceptTermsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AcceptTermsReply>;
  /** GetRequiredActionsForPeerToPeerSetup will return the required actions for the current user to be able to activate peer to peer feature. */
  GetRequiredActionsForPeerToPeerSetup(
    request: DeepPartial<GetRequiredActionsForPeerToPeerSetupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetRequiredActionsForPeerToPeerSetupReply>;
  /** ClaimPeerToPeerTag will claim a tag for the current user. */
  ClaimPeerToPeerTag(
    request: DeepPartial<ClaimPeerToPeerTagRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ClaimPeerToPeerTagReply>;
  /** ListFeatures will return a list of all the features for the account. */
  ListFeatures(
    request: DeepPartial<ListFeaturesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListFeaturesReply>;
  /** UpdateFeature will update the feature for the account. */
  UpdateFeature(
    request: DeepPartial<UpdateFeatureRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateFeatureReply>;
}

export class UsersClientImpl implements Users {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.SignUpWithExternalAuth = this.SignUpWithExternalAuth.bind(this);
    this.GetSessionToken = this.GetSessionToken.bind(this);
    this.GetDefaultProfile = this.GetDefaultProfile.bind(this);
    this.GetProfile = this.GetProfile.bind(this);
    this.GetBannerItems = this.GetBannerItems.bind(this);
    this.RefreshProfile = this.RefreshProfile.bind(this);
    this.SendContactDetailVerification =
      this.SendContactDetailVerification.bind(this);
    this.VerifyContactDetail = this.VerifyContactDetail.bind(this);
    this.SetUsage = this.SetUsage.bind(this);
    this.UseInvitationCode = this.UseInvitationCode.bind(this);
    this.SkipInvitationCode = this.SkipInvitationCode.bind(this);
    this.SetSource = this.SetSource.bind(this);
    this.ListAccounts = this.ListAccounts.bind(this);
    this.UpdateAccount = this.UpdateAccount.bind(this);
    this.GetPostLoginAction = this.GetPostLoginAction.bind(this);
    this.GetPostLoginActions = this.GetPostLoginActions.bind(this);
    this.GetSendOptions = this.GetSendOptions.bind(this);
    this.ListMembers = this.ListMembers.bind(this);
    this.ListRoles = this.ListRoles.bind(this);
    this.InviteMember = this.InviteMember.bind(this);
    this.JoinAccount = this.JoinAccount.bind(this);
    this.UpdateMember = this.UpdateMember.bind(this);
    this.RemoveMember = this.RemoveMember.bind(this);
    this.ListMembershipInvitations = this.ListMembershipInvitations.bind(this);
    this.DeleteMembershipInvitation =
      this.DeleteMembershipInvitation.bind(this);
    this.AcceptTerms = this.AcceptTerms.bind(this);
    this.GetRequiredActionsForPeerToPeerSetup =
      this.GetRequiredActionsForPeerToPeerSetup.bind(this);
    this.ClaimPeerToPeerTag = this.ClaimPeerToPeerTag.bind(this);
    this.ListFeatures = this.ListFeatures.bind(this);
    this.UpdateFeature = this.UpdateFeature.bind(this);
  }

  SignUpWithExternalAuth(
    request: DeepPartial<SignUpWithExternalAuthRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SignUpReply> {
    return this.rpc.unary(
      UsersSignUpWithExternalAuthDesc,
      SignUpWithExternalAuthRequest.fromPartial(request),
      metadata,
    );
  }

  GetSessionToken(
    request: DeepPartial<GetSessionTokenRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetSessionTokenReply> {
    return this.rpc.unary(
      UsersGetSessionTokenDesc,
      GetSessionTokenRequest.fromPartial(request),
      metadata,
    );
  }

  GetDefaultProfile(
    request: DeepPartial<GetDefaultProfileRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetProfileReply> {
    return this.rpc.unary(
      UsersGetDefaultProfileDesc,
      GetDefaultProfileRequest.fromPartial(request),
      metadata,
    );
  }

  GetProfile(
    request: DeepPartial<GetProfileRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetProfileReply> {
    return this.rpc.unary(
      UsersGetProfileDesc,
      GetProfileRequest.fromPartial(request),
      metadata,
    );
  }

  GetBannerItems(
    request: DeepPartial<GetBannerItemsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetBannerItemsReply> {
    return this.rpc.unary(
      UsersGetBannerItemsDesc,
      GetBannerItemsRequest.fromPartial(request),
      metadata,
    );
  }

  RefreshProfile(
    request: DeepPartial<RefreshProfileRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RefreshProfileReply> {
    return this.rpc.unary(
      UsersRefreshProfileDesc,
      RefreshProfileRequest.fromPartial(request),
      metadata,
    );
  }

  SendContactDetailVerification(
    request: DeepPartial<SendContactDetailVerificationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SendContactDetailVerificationReply> {
    return this.rpc.unary(
      UsersSendContactDetailVerificationDesc,
      SendContactDetailVerificationRequest.fromPartial(request),
      metadata,
    );
  }

  VerifyContactDetail(
    request: DeepPartial<VerifyContactDetailRequest>,
    metadata?: grpc.Metadata,
  ): Promise<VerifyContactDetailReply> {
    return this.rpc.unary(
      UsersVerifyContactDetailDesc,
      VerifyContactDetailRequest.fromPartial(request),
      metadata,
    );
  }

  SetUsage(
    request: DeepPartial<SetUsageRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetUsageReply> {
    return this.rpc.unary(
      UsersSetUsageDesc,
      SetUsageRequest.fromPartial(request),
      metadata,
    );
  }

  UseInvitationCode(
    request: DeepPartial<UseInvitationCodeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UseInvitationCodeReply> {
    return this.rpc.unary(
      UsersUseInvitationCodeDesc,
      UseInvitationCodeRequest.fromPartial(request),
      metadata,
    );
  }

  SkipInvitationCode(
    request: DeepPartial<SkipInvitationCodeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SkipInvitationCodeReply> {
    return this.rpc.unary(
      UsersSkipInvitationCodeDesc,
      SkipInvitationCodeRequest.fromPartial(request),
      metadata,
    );
  }

  SetSource(
    request: DeepPartial<SetSourceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetSourceReply> {
    return this.rpc.unary(
      UsersSetSourceDesc,
      SetSourceRequest.fromPartial(request),
      metadata,
    );
  }

  ListAccounts(
    request: DeepPartial<ListAccountsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListAccountsReply> {
    return this.rpc.unary(
      UsersListAccountsDesc,
      ListAccountsRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateAccount(
    request: DeepPartial<UpdateAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateAccountReply> {
    return this.rpc.unary(
      UsersUpdateAccountDesc,
      UpdateAccountRequest.fromPartial(request),
      metadata,
    );
  }

  GetPostLoginAction(
    request: DeepPartial<GetPostLoginActionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetPostLoginActionReply> {
    return this.rpc.unary(
      UsersGetPostLoginActionDesc,
      GetPostLoginActionRequest.fromPartial(request),
      metadata,
    );
  }

  GetPostLoginActions(
    request: DeepPartial<GetPostLoginActionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetPostLoginActionsReply> {
    return this.rpc.unary(
      UsersGetPostLoginActionsDesc,
      GetPostLoginActionsRequest.fromPartial(request),
      metadata,
    );
  }

  GetSendOptions(
    request: DeepPartial<GetSendOptionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetSendOptionsReply> {
    return this.rpc.unary(
      UsersGetSendOptionsDesc,
      GetSendOptionsRequest.fromPartial(request),
      metadata,
    );
  }

  ListMembers(
    request: DeepPartial<ListMembersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListMembersReply> {
    return this.rpc.unary(
      UsersListMembersDesc,
      ListMembersRequest.fromPartial(request),
      metadata,
    );
  }

  ListRoles(
    request: DeepPartial<ListRolesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListRolesReply> {
    return this.rpc.unary(
      UsersListRolesDesc,
      ListRolesRequest.fromPartial(request),
      metadata,
    );
  }

  InviteMember(
    request: DeepPartial<InviteMemberRequest>,
    metadata?: grpc.Metadata,
  ): Promise<InviteMemberReply> {
    return this.rpc.unary(
      UsersInviteMemberDesc,
      InviteMemberRequest.fromPartial(request),
      metadata,
    );
  }

  JoinAccount(
    request: DeepPartial<JoinAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<JoinAccountReply> {
    return this.rpc.unary(
      UsersJoinAccountDesc,
      JoinAccountRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateMember(
    request: DeepPartial<UpdateMemberRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateMemberReply> {
    return this.rpc.unary(
      UsersUpdateMemberDesc,
      UpdateMemberRequest.fromPartial(request),
      metadata,
    );
  }

  RemoveMember(
    request: DeepPartial<RemoveMemberRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveMemberReply> {
    return this.rpc.unary(
      UsersRemoveMemberDesc,
      RemoveMemberRequest.fromPartial(request),
      metadata,
    );
  }

  ListMembershipInvitations(
    request: DeepPartial<ListMembershipInvitationsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListMembershipInvitationsReply> {
    return this.rpc.unary(
      UsersListMembershipInvitationsDesc,
      ListMembershipInvitationsRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteMembershipInvitation(
    request: DeepPartial<DeleteMembershipInvitationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteMembershipInvitationReply> {
    return this.rpc.unary(
      UsersDeleteMembershipInvitationDesc,
      DeleteMembershipInvitationRequest.fromPartial(request),
      metadata,
    );
  }

  AcceptTerms(
    request: DeepPartial<AcceptTermsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AcceptTermsReply> {
    return this.rpc.unary(
      UsersAcceptTermsDesc,
      AcceptTermsRequest.fromPartial(request),
      metadata,
    );
  }

  GetRequiredActionsForPeerToPeerSetup(
    request: DeepPartial<GetRequiredActionsForPeerToPeerSetupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetRequiredActionsForPeerToPeerSetupReply> {
    return this.rpc.unary(
      UsersGetRequiredActionsForPeerToPeerSetupDesc,
      GetRequiredActionsForPeerToPeerSetupRequest.fromPartial(request),
      metadata,
    );
  }

  ClaimPeerToPeerTag(
    request: DeepPartial<ClaimPeerToPeerTagRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ClaimPeerToPeerTagReply> {
    return this.rpc.unary(
      UsersClaimPeerToPeerTagDesc,
      ClaimPeerToPeerTagRequest.fromPartial(request),
      metadata,
    );
  }

  ListFeatures(
    request: DeepPartial<ListFeaturesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListFeaturesReply> {
    return this.rpc.unary(
      UsersListFeaturesDesc,
      ListFeaturesRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateFeature(
    request: DeepPartial<UpdateFeatureRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateFeatureReply> {
    return this.rpc.unary(
      UsersUpdateFeatureDesc,
      UpdateFeatureRequest.fromPartial(request),
      metadata,
    );
  }
}

export const UsersDesc = { serviceName: 'public.Users' };

export const UsersSignUpWithExternalAuthDesc: UnaryMethodDefinitionish = {
  methodName: 'SignUpWithExternalAuth',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SignUpWithExternalAuthRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SignUpReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersGetSessionTokenDesc: UnaryMethodDefinitionish = {
  methodName: 'GetSessionToken',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSessionTokenRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetSessionTokenReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersGetDefaultProfileDesc: UnaryMethodDefinitionish = {
  methodName: 'GetDefaultProfile',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDefaultProfileRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetProfileReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersGetProfileDesc: UnaryMethodDefinitionish = {
  methodName: 'GetProfile',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetProfileRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetProfileReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersGetBannerItemsDesc: UnaryMethodDefinitionish = {
  methodName: 'GetBannerItems',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetBannerItemsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetBannerItemsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersRefreshProfileDesc: UnaryMethodDefinitionish = {
  methodName: 'RefreshProfile',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RefreshProfileRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RefreshProfileReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersSendContactDetailVerificationDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SendContactDetailVerification',
    service: UsersDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SendContactDetailVerificationRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = SendContactDetailVerificationReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const UsersVerifyContactDetailDesc: UnaryMethodDefinitionish = {
  methodName: 'VerifyContactDetail',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return VerifyContactDetailRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = VerifyContactDetailReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersSetUsageDesc: UnaryMethodDefinitionish = {
  methodName: 'SetUsage',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetUsageRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SetUsageReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersUseInvitationCodeDesc: UnaryMethodDefinitionish = {
  methodName: 'UseInvitationCode',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UseInvitationCodeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UseInvitationCodeReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersSkipInvitationCodeDesc: UnaryMethodDefinitionish = {
  methodName: 'SkipInvitationCode',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SkipInvitationCodeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SkipInvitationCodeReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersSetSourceDesc: UnaryMethodDefinitionish = {
  methodName: 'SetSource',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetSourceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SetSourceReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersListAccountsDesc: UnaryMethodDefinitionish = {
  methodName: 'ListAccounts',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListAccountsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListAccountsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersUpdateAccountDesc: UnaryMethodDefinitionish = {
  methodName: 'UpdateAccount',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateAccountReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersGetPostLoginActionDesc: UnaryMethodDefinitionish = {
  methodName: 'GetPostLoginAction',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetPostLoginActionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetPostLoginActionReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersGetPostLoginActionsDesc: UnaryMethodDefinitionish = {
  methodName: 'GetPostLoginActions',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetPostLoginActionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetPostLoginActionsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersGetSendOptionsDesc: UnaryMethodDefinitionish = {
  methodName: 'GetSendOptions',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSendOptionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetSendOptionsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersListMembersDesc: UnaryMethodDefinitionish = {
  methodName: 'ListMembers',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListMembersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListMembersReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersListRolesDesc: UnaryMethodDefinitionish = {
  methodName: 'ListRoles',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListRolesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListRolesReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersInviteMemberDesc: UnaryMethodDefinitionish = {
  methodName: 'InviteMember',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return InviteMemberRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = InviteMemberReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersJoinAccountDesc: UnaryMethodDefinitionish = {
  methodName: 'JoinAccount',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return JoinAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = JoinAccountReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersUpdateMemberDesc: UnaryMethodDefinitionish = {
  methodName: 'UpdateMember',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateMemberRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateMemberReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersRemoveMemberDesc: UnaryMethodDefinitionish = {
  methodName: 'RemoveMember',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveMemberRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RemoveMemberReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersListMembershipInvitationsDesc: UnaryMethodDefinitionish = {
  methodName: 'ListMembershipInvitations',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListMembershipInvitationsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListMembershipInvitationsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersDeleteMembershipInvitationDesc: UnaryMethodDefinitionish = {
  methodName: 'DeleteMembershipInvitation',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteMembershipInvitationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = DeleteMembershipInvitationReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersAcceptTermsDesc: UnaryMethodDefinitionish = {
  methodName: 'AcceptTerms',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AcceptTermsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AcceptTermsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersGetRequiredActionsForPeerToPeerSetupDesc: UnaryMethodDefinitionish =
  {
    methodName: 'GetRequiredActionsForPeerToPeerSetup',
    service: UsersDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return GetRequiredActionsForPeerToPeerSetupRequest.encode(
          this,
        ).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = GetRequiredActionsForPeerToPeerSetupReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const UsersClaimPeerToPeerTagDesc: UnaryMethodDefinitionish = {
  methodName: 'ClaimPeerToPeerTag',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ClaimPeerToPeerTagRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ClaimPeerToPeerTagReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersListFeaturesDesc: UnaryMethodDefinitionish = {
  methodName: 'ListFeatures',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListFeaturesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListFeaturesReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UsersUpdateFeatureDesc: UnaryMethodDefinitionish = {
  methodName: 'UpdateFeature',
  service: UsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateFeatureRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateFeatureReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(
              response.statusMessage,
              response.status,
              response.trailers,
            );
            reject(err);
          }
        },
      });
    });
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error(
      'Value is larger than Number.MAX_SAFE_INTEGER',
    );
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(
    message: string,
    public code: grpc.Code,
    public metadata: grpc.Metadata,
  ) {
    super(message);
  }
}
