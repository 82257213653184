import { OAuthError } from '@auth0/auth0-react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Sentry from '@sentry/browser';
import { BrowserHeaders } from 'browser-headers';
import {
  ErrorDetails,
  ErrorType,
  Status,
} from 'grpc-web/common/proto/errors/errors';
import Router from 'next/router';

import { auth, routeRequiresAuth } from '../components/AuthProvider';
import { GetCountriesConfigReply } from '../grpc-web/anonymousapi/config/proto/anonymousconfig';
import {
  LinkExternalAuthReply,
  LinkExternalAuthRequest,
  StartExternalAuthLinkingReply,
  StartExternalAuthLinkingRequest,
} from '../grpc-web/anonymousapi/users/proto/anonymoususers';
import {
  GetFXAllDefaultQuotesRequest,
  GetFXAllQuotesReply,
  GetFXQuoteByIDReply,
} from '../grpc-web/api/fx/proto/fx';
import {
  GetActionsRequiredForSetupReply,
  GetActionsRequiredForSetupRequest,
  GetActionsRequiredForTransactionReply,
  GetActionsRequiredForTransactionRequest,
  GetUploadDocumentURLReply,
  GetUploadDocumentURLRequest,
  SetPersonalDetailsReply,
  SetPersonalDetailsRequest,
  UpdateKYCDataReply,
  UpdateKYCDataRequest,
} from '../grpc-web/api/kyc/proto/kyc';
import {
  CreateRecipientAccountReply,
  CreateRecipientAccountRequest,
  CreateTransactionReply,
  GetBanksReply,
  GetRecipientAccountByIDReply,
  GetTransactionDetailsReply,
  ListRecipientsReply,
  PayWithWalletReply,
  PayWithWalletRequest,
  CreateTransactionRequest,
  SetTransactionDetailsReply,
  SetTransactionDetailsRequest,
  ExportStatementToFileRequest,
  DeleteRecipientReply,
  DeleteRecipientRequest,
  GetSummaryReply,
  GetSummaryRequest,
  UpdateRecipientAccountReply,
  UpdateRecipientAccountRequest,
  GetStatementDownloadURLReply,
  GetStatementDownloadURLRequest,
  GetETAReply,
  GetETARequest,
  ProcessBulkPaymentFileReply,
  ProcessBulkPaymentFileRequest,
  CreateBulkPaymentFromReviewsReply,
  CreateBulkPaymentFromReviewsRequest,
  ListTransactionsRequest,
  ListTransactionsReply,
  GetTransactionReply,
  ListTransactionUpdatesReply,
  GetFundingDetailsReply,
  GetFundingDetailsRequest,
  CancelTransactionReply,
  CancelTransactionRequest,
  MarkTransactionAsPaidReply,
  MarkTransactionAsPaidRequest,
  ListPaymentSourcesReply,
  ListOffPlatformFundingOptionsReply,
  ListOffPlatformFundingOptionsRequest,
  ListTransactionPurposesReply,
  GetAccountLimitsReply,
  GetAccountLimitsRequest,
  SubmitPurposeProofReply,
  SubmitPurposeProofRequest,
  ApproveTransactionReply,
  ApproveTransactionRequest,
  RejectTransactionReply,
  RejectTransactionRequest,
} from '../grpc-web/api/transactions/proto/transactions';
import {
  AcceptTermsReply,
  AcceptTermsRequest,
  DeleteMembershipInvitationReply,
  DeleteMembershipInvitationRequest,
  GetPostLoginActionsReply,
  GetProfileReply,
  InviteMemberReply,
  InviteMemberRequest,
  JoinAccountReply,
  JoinAccountRequest,
  ListAccountsReply,
  ListFeaturesReply,
  ListMembershipInvitationsReply,
  ListMembersReply,
  ListRolesReply,
  SendContactDetailVerificationReply,
  SendContactDetailVerificationRequest,
  SignUpReply,
  SignUpWithExternalAuthRequest,
  UpdateFeatureReply,
  UpdateFeatureRequest,
  UpdateMemberReply,
  UpdateMemberRequest,
  VerifyContactDetailReply,
  VerifyContactDetailRequest,
} from '../grpc-web/api/users/proto/users';
import {
  CreateDisbursementReply,
  CreateDisbursementRequest,
  GetWalletByIDReply,
  ListWalletsReply,
} from '../grpc-web/api/wallets/proto/wallets';
import { StatementOutputFormat } from '../grpc-web/common/proto/transactions/types';
import { DeepPartial } from '../grpc-web/google/protobuf/timestamp';
import { CountryCode } from './countries';
import grpc, { GrpcError } from './grpc';

export enum LocalErrors {
  OAUTH_LOGIN_REQUIRED = 'OAUTH_LOGIN_REQUIRED',
  OAUTH_LOGIN_CANCELLED = 'OAUTH_LOGIN_CANCELLED',
  CLIENT = 'CLIENT',
}

const GrpcErrorHandler = (e: any) => {
  if (e?.code) {
    const error = e as unknown as GrpcError;
    return {
      error: {
        status: 422,
        code: error.code,
        message: String(e),
        data: null,
      },
    } as const;
  }

  if (e?.error === 'login_required') {
    return {
      error: {
        status: 401,
        code: LocalErrors.OAUTH_LOGIN_REQUIRED,
        message: String(e),
        data: null,
      },
    } as const;
  }

  if (e?.error === 'cancelled') {
    return {
      error: {
        status: 401,
        code: LocalErrors.OAUTH_LOGIN_CANCELLED,
        message: 'You must authenticate to continue',
        data: null,
      },
    } as const;
  }

  Sentry.captureException(e);

  return {
    error: {
      status: 0, // Client failed, no error code
      error: LocalErrors.CLIENT,
      message: 'Client failed to connect to server',
      data: null,
    },
  } as const;
};

export type ApiGrpcError = ReturnType<typeof GrpcErrorHandler>['error'];

/**
 * This grpc generator includes a non-serializable toObject method on every response.
 * Redux is not happy about this.
 */
const stripOffToObject = <T extends object>(obj: T): Omit<T, 'toObject'> => {
  const response = {
    ...obj,
  };

  delete (response as any).toObject;

  return response;
};

type GenericGrpc<Req, Resp> = (
  args: Req,
  metadata: BrowserHeaders,
) => Promise<Resp>;

function decodeErrorDetails(error: GrpcError): ErrorDetails | null {
  const details = error.metadata.get('grpc-status-details-bin');
  if (details.length > 0) {
    const detailsUint8Array = Uint8Array.from(atob(details[0]), (c) =>
      c.charCodeAt(0),
    );
    const status = Status.decode(detailsUint8Array);

    if (status.details.length > 0) {
      const detail = status.details[0].value;
      const errorDetails = ErrorDetails.decode(detail);
      return errorDetails;
    }
  }

  return null;
}

/** A uniform grpc query function that returns cleaned grpc data or a standardized error */
const grpcQueryFn = async <Req, Res extends Object>(
  grpcCall: GenericGrpc<Req, Res>,
  args: Req,
): Promise<
  | {
      data: Omit<Res, 'toObject'>;
    }
  | ReturnType<typeof GrpcErrorHandler>
> => {
  if (auth.ready === false) {
    return GrpcErrorHandler(new Error('Auth0 not ready'));
  }

  let token;

  try {
    token = await auth.getAccessTokenSilently();
  } catch (e) {
    if (e instanceof OAuthError && e.error === 'login_required') {
      if (routeRequiresAuth(Router.pathname)) {
        auth.logout({ redirect: true });
      }
      return GrpcErrorHandler(e);
    }

    if (e instanceof OAuthError && e.error === 'mfa_required') {
      try {
        token = auth.getAccessTokenWithPopup({}, { timeoutInSeconds: 900 });
      } catch (err) {
        return GrpcErrorHandler(err);
      }
    }

    Sentry.captureException(e);
    return GrpcErrorHandler(e);
  }

  try {
    const data = await grpcCall(
      args,
      new BrowserHeaders({
        authorization: `Auth0 ${token}`,
      }),
    );

    return {
      data: stripOffToObject(data),
    };
  } catch (e) {
    const errorDetails = decodeErrorDetails(e as GrpcError);

    if (errorDetails?.errorType === ErrorType.ERROR_TYPE_MFA_SESSION_REQUIRED) {
      try {
        await auth.getAccessTokenWithPopup({}, { timeoutInSeconds: 900 });
      } catch (err) {
        return GrpcErrorHandler(err);
      }
      return grpcQueryFn(grpcCall, args);
    }
    return GrpcErrorHandler(e);
  }
};

const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  reducerPath: 'api',
  tagTypes: [
    'Profile',
    'Recipient',
    'Transaction',
    'Wallet',
    'Account',
    'Member',
    'Invitation',
    'FundingSources',
    'PostLoginActions',
    'AccountLimits',
    'Features',
  ],
  refetchOnMountOrArgChange: 180,
  endpoints: (builder) => ({
    getDefaultProfile: builder.query<GetProfileReply, void>({
      queryFn: () => grpcQueryFn(grpc.users.GetDefaultProfile, {}),
      providesTags: ['Profile'],
    }),
    listAccounts: builder.query<ListAccountsReply, void>({
      queryFn: () => grpcQueryFn(grpc.users.ListAccounts, {}),
      providesTags: (response) => [
        ...(response?.accounts.map(
          (account) =>
            ({
              type: 'Account',
              id: account.id,
            } as const),
        ) ?? []),
        { type: 'Account' },
      ],
    }),
    listWallets: builder.query<ListWalletsReply, void>({
      queryFn: () => grpcQueryFn(grpc.wallet.ListWallets, {}),
      providesTags: (response) => [
        ...(response?.wallets.map(
          (wallet) =>
            ({
              type: 'Wallet',
              id: wallet.id,
            } as const),
        ) ?? []),
        { type: 'Wallet' },
      ],
    }),
    getWalletById: builder.query<GetWalletByIDReply, string>({
      queryFn: (walletId) =>
        grpcQueryFn(grpc.wallet.GetWalletByID, { walletId }),
      providesTags: (response) => {
        if (response?.wallet) {
          return [
            { type: 'Wallet', id: response.wallet.id },
            { type: 'Wallet' },
          ];
        }
        return [];
      },
    }),
    getTransactions: builder.query<
      ListTransactionsReply,
      DeepPartial<ListTransactionsRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.transactions.ListTransactions, args),
      providesTags: (response) => [
        ...(response?.transactions.map(
          (transactionItem) =>
            ({
              type: 'Transaction',
              id: transactionItem.id,
            } as const),
        ) ?? []),
        { type: 'Transaction' },
      ],
    }),
    getTransaction: builder.query<GetTransactionReply, string>({
      queryFn: (transactionId) =>
        grpcQueryFn(grpc.transactions.GetTransaction, { transactionId }),
      providesTags: (response) => [
        { type: 'Transaction', id: response?.transaction?.id },
      ],
    }),
    ListTransactionUpdates: builder.query<ListTransactionUpdatesReply, string>({
      queryFn: (transactionId) =>
        grpcQueryFn(grpc.transactions.ListTransactionUpdates, {
          transactionId,
        }),
    }),
    getRecipients: builder.query<ListRecipientsReply, void>({
      queryFn: () => grpcQueryFn(grpc.transactions.ListRecipients, {}),
      providesTags: (response) => {
        return [
          ...(response?.recipients.map(
            (recipient) => ({ type: 'Recipient', id: recipient.id } as const),
          ) ?? []),
          { type: 'Recipient' },
        ];
      },
    }),
    getRecipientAccountById: builder.query<
      GetRecipientAccountByIDReply,
      string
    >({
      queryFn: (recipientAccountId) =>
        grpcQueryFn(grpc.transactions.GetRecipientAccountByID, {
          recipientAccountId,
        }),
    }),
    createRecipientAccount: builder.mutation<
      CreateRecipientAccountReply,
      DeepPartial<CreateRecipientAccountRequest>
    >({
      queryFn: (recipientAccount) =>
        grpcQueryFn(
          grpc.transactions.CreateRecipientAccount,
          CreateRecipientAccountRequest.fromJSON(recipientAccount),
        ),
      invalidatesTags: () => [{ type: 'Recipient' }],
    }),
    updateRecipientAccount: builder.mutation<
      UpdateRecipientAccountReply,
      DeepPartial<UpdateRecipientAccountRequest>
    >({
      queryFn: (args) =>
        grpcQueryFn(grpc.transactions.UpdateRecipientAccount, args),
      invalidatesTags: (_, __, context) => [
        { type: 'Recipient' },
        { type: 'Recipient', id: context.recipientAccountId },
      ],
    }),
    getCountriesConfig: builder.query<GetCountriesConfigReply, void>({
      queryFn: () => grpcQueryFn(grpc.config.GetCountriesConfig, {}),
    }),
    getBanksByCountry: builder.query<GetBanksReply, CountryCode>({
      queryFn: (country) =>
        grpcQueryFn(grpc.transactions.GetBanks, { country }),
    }),
    getAllFxQuotes: builder.query<
      GetFXAllQuotesReply,
      DeepPartial<GetFXAllDefaultQuotesRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.fx.GetFXAllDefaultQuotes, args),
    }),
    getFxQuoteById: builder.query<GetFXQuoteByIDReply, string>({
      queryFn: (quoteId) => grpcQueryFn(grpc.fx.GetFXQuoteByID, { quoteId }),
    }),
    createTransaction: builder.mutation<
      CreateTransactionReply,
      DeepPartial<CreateTransactionRequest>
    >({
      queryFn: (transaction) =>
        grpcQueryFn(grpc.transactions.CreateTransaction, transaction),
      invalidatesTags: () => [{ type: 'Transaction' }],
    }),
    getTransactionDetails: builder.query<GetTransactionDetailsReply, string>({
      queryFn: (transactionId) =>
        grpcQueryFn(grpc.transactions.GetTransactionDetails, { transactionId }),
      providesTags: (response) => [
        { type: 'Transaction', id: response?.transaction?.id },
      ],
    }),
    payWithWallet: builder.mutation<
      PayWithWalletReply,
      DeepPartial<PayWithWalletRequest>
    >({
      queryFn: ({ transactionId, walletId }) =>
        grpcQueryFn(grpc.transactions.PayWithWallet, {
          transactionId,
          walletId,
        }),
      invalidatesTags: () => [{ type: 'Wallet' }],
    }),
    updateTransactionDetails: builder.mutation<
      SetTransactionDetailsReply,
      DeepPartial<SetTransactionDetailsRequest>
    >({
      queryFn: ({ transactionId, memo }) =>
        grpcQueryFn(grpc.transactions.SetTransactionDetails, {
          transactionId,
          memo,
        }),
      invalidatesTags: (_, __, context) => [
        { type: 'Transaction', id: context.transactionId },
        { type: 'Transaction' },
      ],
    }),
    exportStatement: builder.mutation<
      { statementFile: string },
      DeepPartial<ExportStatementToFileRequest>
    >({
      queryFn: async (args) => {
        const rawResponse = await grpcQueryFn(
          grpc.transactions.ExportStatementToFile,
          args,
        );

        if ('data' in rawResponse) {
          const mimeType = (() => {
            switch (args.outputFormat) {
              case StatementOutputFormat.STATEMENT_OUTPUT_FORMAT_PDF:
                return 'application/pdf';
              case StatementOutputFormat.STATEMENT_OUTPUT_FORMAT_CSV:
                return 'text/csv';
              case StatementOutputFormat.STATEMENT_OUTPUT_FORMAT_XLS:
                return 'application/vnd.ms-excel';
              default:
                return 'application/octet-stream';
            }
          })();

          const base64EncodedExport = await new Promise<string>((resolve) => {
            const blob = new Blob([rawResponse.data.statementFile], {
              type: mimeType,
            });
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(blob);
          });

          return {
            data: {
              statementFile: base64EncodedExport,
            },
          };
        }

        return rawResponse;
      },
    }),
    createDisbursement: builder.mutation<
      CreateDisbursementReply,
      DeepPartial<CreateDisbursementRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.wallet.CreateDisbursement, args),
      invalidatesTags: () => [{ type: 'Wallet' }, { type: 'Transaction' }],
    }),
    getTransactionSummary: builder.query<
      GetSummaryReply,
      DeepPartial<GetSummaryRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.transactions.GetSummary, args),
    }),
    deleteRecipient: builder.mutation<
      DeleteRecipientReply,
      DeepPartial<DeleteRecipientRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.transactions.DeleteRecipient, args),
      invalidatesTags: (_, __, context) => {
        return [
          { type: 'Recipient' },
          { type: 'Recipient', id: context.recipientId },
        ];
      },
    }),
    getStatementDownloadURL: builder.query<
      GetStatementDownloadURLReply,
      DeepPartial<GetStatementDownloadURLRequest>
    >({
      queryFn: (args) =>
        grpcQueryFn(grpc.transactions.GetStatementDownloadURL, args),
    }),
    linkExternalAuth: builder.mutation<
      LinkExternalAuthReply,
      DeepPartial<LinkExternalAuthRequest>
    >({
      queryFn: (args) =>
        grpcQueryFn(grpc.anonymousUsers.LinkExternalAuth, args),
    }),
    listMembers: builder.query<ListMembersReply, void>({
      queryFn: () => grpcQueryFn(grpc.users.ListMembers, {}),
      providesTags: (response) => [
        ...(response?.members?.map(
          (member) => ({ type: 'Member', id: member.email } as const),
        ) ?? []),
        { type: 'Member' } as const,
      ],
    }),
    listMembershipInvitations: builder.query<
      ListMembershipInvitationsReply,
      void
    >({
      queryFn: () => grpcQueryFn(grpc.users.ListMembershipInvitations, {}),
      providesTags: (response) => [
        ...(response?.invitations?.map(
          (invitation) => ({ type: 'Invitation', id: invitation.id } as const),
        ) ?? []),
        { type: 'Invitation' } as const,
      ],
    }),
    deleteMembershipInvitation: builder.mutation<
      DeleteMembershipInvitationReply,
      DeepPartial<DeleteMembershipInvitationRequest>
    >({
      queryFn: (args) =>
        grpcQueryFn(grpc.users.DeleteMembershipInvitation, args),
      invalidatesTags: (_, __, context) => {
        return [
          { type: 'Invitation' },
          { type: 'Invitation', id: context.invitationId },
        ];
      },
    }),
    updateMember: builder.mutation<
      UpdateMemberReply,
      DeepPartial<UpdateMemberRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.users.UpdateMember, args),
      invalidatesTags: (_, __, context) => {
        return [{ type: 'Member' }, { type: 'Member', id: context.userId }];
      },
    }),
    listRoles: builder.query<ListRolesReply, void>({
      queryFn: () => grpcQueryFn(grpc.users.ListRoles, {}),
    }),
    getTxEta: builder.query<GetETAReply, DeepPartial<GetETARequest>>({
      queryFn: (args) => grpcQueryFn(grpc.transactions.GetETA, args),
    }),
    createBulkPaymentFileRequest: builder.query<
      ProcessBulkPaymentFileReply,
      DeepPartial<ProcessBulkPaymentFileRequest>
    >({
      queryFn: (args) =>
        grpcQueryFn(grpc.transactions.ProcessBulkPaymentFile, args),
    }),
    createBulkPaymentFromReviews: builder.mutation<
      CreateBulkPaymentFromReviewsReply,
      DeepPartial<CreateBulkPaymentFromReviewsRequest>
    >({
      queryFn: (args) =>
        grpcQueryFn(grpc.transactions.CreateBulkPaymentFromReviews, args),
      invalidatesTags: [{ type: 'Transaction' }],
    }),
    signUpWithExternalAuth: builder.mutation<
      SignUpReply,
      DeepPartial<SignUpWithExternalAuthRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.users.SignUpWithExternalAuth, args),
      invalidatesTags: () => [{ type: 'Account' }],
    }),
    startExternalAuthLinking: builder.mutation<
      StartExternalAuthLinkingReply,
      DeepPartial<StartExternalAuthLinkingRequest>
    >({
      queryFn: (args) =>
        grpcQueryFn(grpc.anonymousUsers.StartExternalAuthLinking, args),
    }),
    joinAccount: builder.mutation<
      JoinAccountReply,
      DeepPartial<JoinAccountRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.users.JoinAccount, args),
      invalidatesTags: () => [{ type: 'Account' }],
    }),
    inviteMember: builder.mutation<
      InviteMemberReply,
      DeepPartial<InviteMemberRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.users.InviteMember, args),
      invalidatesTags: () => [{ type: 'Invitation' }],
    }),
    getPostLoginActions: builder.query<GetPostLoginActionsReply, void>({
      queryFn: () => grpcQueryFn(grpc.users.GetPostLoginActions, {}),
      providesTags: () => ['PostLoginActions'],
    }),
    sendContactDetailVerification: builder.mutation<
      SendContactDetailVerificationReply,
      DeepPartial<SendContactDetailVerificationRequest>
    >({
      queryFn: (args) =>
        grpcQueryFn(grpc.users.SendContactDetailVerification, args),
    }),
    verifyContactDetail: builder.mutation<
      VerifyContactDetailReply,
      DeepPartial<VerifyContactDetailRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.users.VerifyContactDetail, args),
    }),
    getActionsRequiredForSetup: builder.query<
      GetActionsRequiredForSetupReply,
      DeepPartial<GetActionsRequiredForSetupRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.kyc.GetActionsRequiredForSetup, args),
    }),
    getActionsRequiredForTransaction: builder.query<
      GetActionsRequiredForTransactionReply,
      DeepPartial<GetActionsRequiredForTransactionRequest>
    >({
      queryFn: (args) =>
        grpcQueryFn(grpc.kyc.GetActionsRequiredForTransaction, args),
    }),
    setPersonalDetails: builder.mutation<
      SetPersonalDetailsReply,
      DeepPartial<SetPersonalDetailsRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.kyc.SetPersonalDetails, args),
    }),
    updateKYCData: builder.mutation<
      UpdateKYCDataReply,
      DeepPartial<UpdateKYCDataRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.kyc.UpdateKYCData, args),
    }),
    getFundingDetails: builder.query<
      GetFundingDetailsReply,
      DeepPartial<GetFundingDetailsRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.transactions.GetFundingDetails, args),
    }),
    cancelTransaction: builder.mutation<
      CancelTransactionReply,
      DeepPartial<CancelTransactionRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.transactions.CancelTransaction, args),
      invalidatesTags: (_, __, args) => [
        { type: 'Transaction', id: args.transactionId },
        { type: 'Transaction' },
      ],
    }),
    markTransactionAsPaid: builder.mutation<
      MarkTransactionAsPaidReply,
      DeepPartial<MarkTransactionAsPaidRequest>
    >({
      queryFn: (args) =>
        grpcQueryFn(grpc.transactions.MarkTransactionAsPaid, args),
      invalidatesTags: (_, __, args) => [
        { type: 'Transaction', id: args.transactionId },
        { type: 'Transaction' },
      ],
    }),
    listPaymentSources: builder.query<ListPaymentSourcesReply, void>({
      queryFn: () => grpcQueryFn(grpc.transactions.ListPaymentSources, {}),
    }),
    listOffPlatformFundingOptions: builder.query<
      ListOffPlatformFundingOptionsReply,
      DeepPartial<ListOffPlatformFundingOptionsRequest>
    >({
      queryFn: (args) =>
        grpcQueryFn(grpc.transactions.ListOffPlatformFundingOptions, args),
    }),
    acceptTerms: builder.mutation<
      AcceptTermsReply,
      DeepPartial<AcceptTermsRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.users.AcceptTerms, args),
      invalidatesTags: () => [{ type: 'PostLoginActions' }],
    }),
    listTransactionPurposes: builder.query<ListTransactionPurposesReply, void>({
      queryFn: () => grpcQueryFn(grpc.transactions.ListTransactionPurposes, {}),
    }),
    getAccountLimits: builder.query<
      GetAccountLimitsReply,
      GetAccountLimitsRequest['fromCurrencyCode']
    >({
      queryFn: (fromCurrencyCode) =>
        grpcQueryFn(grpc.transactions.GetAccountLimits, { fromCurrencyCode }),
      providesTags: () => [{ type: 'AccountLimits' }],
    }),
    getUploadDocumentURLRequest: builder.query<
      GetUploadDocumentURLReply,
      DeepPartial<GetUploadDocumentURLRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.documents.GetUploadDocumentURL, args),
    }),
    submitPurposeProof: builder.query<
      SubmitPurposeProofReply,
      DeepPartial<SubmitPurposeProofRequest>
    >({
      queryFn: (args) =>
        grpcQueryFn(grpc.transactions.SubmitPurposeProof, args),
    }),
    listFeatures: builder.query<ListFeaturesReply, void>({
      queryFn: () => grpcQueryFn(grpc.users.ListFeatures, {}),
      providesTags: () => [{ type: 'Features' }],
    }),
    updateFeature: builder.mutation<
      UpdateFeatureReply,
      DeepPartial<UpdateFeatureRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.users.UpdateFeature, args),
      invalidatesTags: [{ type: 'Features' }],
    }),
    approveTransaction: builder.mutation<
      ApproveTransactionReply,
      DeepPartial<ApproveTransactionRequest>
    >({
      queryFn: async (args) => {
        const data = await grpcQueryFn(
          grpc.transactions.ApproveTransaction,
          args,
        );
        return data;
      },
      invalidatesTags: (_, __, args) => [
        { type: 'Transaction', id: args.transactionId },
        { type: 'Transaction' },
      ],
    }),
    rejectTransaction: builder.mutation<
      RejectTransactionReply,
      DeepPartial<RejectTransactionRequest>
    >({
      queryFn: (args) => grpcQueryFn(grpc.transactions.RejectTransaction, args),
      invalidatesTags: (_, __, args) => [
        { type: 'Transaction', id: args.transactionId },
        { type: 'Transaction' },
      ],
    }),
  }),
});

export default api;
