/* eslint-disable */
import { grpc } from '@improbable-eng/grpc-web';
import { BrowserHeaders } from 'browser-headers';
import _m0 from 'protobufjs/minimal';
import { Money } from '../../../common/proto/fx/types';
import {
  Action,
  Address,
  Data,
  DataType,
  dataTypeFromJSON,
  dataTypeToJSON,
  Document,
  DocumentSide,
  documentSideFromJSON,
  documentSideToJSON,
  DocumentType,
  documentTypeFromJSON,
  documentTypeToJSON,
  Gender,
  genderFromJSON,
  genderToJSON,
  Name,
} from '../../../common/proto/kyc/types';
import {
  TransactionType,
  transactionTypeFromJSON,
  transactionTypeToJSON,
} from '../../../common/proto/transactions/types';
import { ContactDetail, User } from '../../../common/proto/users/types';
import { Timestamp } from '../../../google/protobuf/timestamp';

export const protobufPackage = 'public';

export interface KYCStatus {
  actions: Action[];
  status: string;
}

/** submit data */
export interface SubmitKYCDataRequest {
  type: string;
  data: { [key: string]: string };
}

export interface SubmitKYCDataRequest_DataEntry {
  key: string;
  value: string;
}

export interface SubmitKYCDataReply {
  user: User | undefined;
  status: KYCStatus | undefined;
}

export interface SubmitRunKYCCheckOutputRequest {
  checkId: string;
  data: { [key: string]: string };
}

export interface SubmitRunKYCCheckOutputRequest_DataEntry {
  key: string;
  value: string;
}

export interface SubmitRunKYCCheckOutputReply {
  user: User | undefined;
  status: KYCStatus | undefined;
}

/** get status */
export interface GetKYCStatusRequest {}

export interface GetKYCStatusReply {
  user: User | undefined;
  status: KYCStatus | undefined;
}

export interface GetPersonalDetailsRequest {}

export interface GetPersonalDetailsReply {
  legalName: Name | undefined;
  preferredName: Name | undefined;
  /** @deprecated */
  deprecatedLegalGender: string;
  dateOfBirth: Timestamp | undefined;
  countryOfBirth: string;
  address: Address | undefined;
  emails: ContactDetail[];
  phoneNumbers: ContactDetail[];
  editableFields: DataType[];
  legalGender: Gender;
}

export interface SetPersonalDetailsRequest {
  legalName: Name | undefined;
  preferredName: Name | undefined;
  legalGender: Gender;
  dateOfBirth: Timestamp | undefined;
  countryOfBirth: string;
  address: Address | undefined;
}

export interface SetPersonalDetailsReply {}

export interface GetAddressesRequest {}

export interface GetAddressesReply {
  addresses: Address[];
}

export interface SetAddressRequest {
  address: Address | undefined;
}

export interface SetAddressReply {}

export interface GetActionsRequiredForTransactionRequest {
  transactionId: string;
  recipientId: string;
  amountSent: Money | undefined;
  recipientAccountId: string;
  transactionType: TransactionType;
}

export interface GetActionsRequiredForTransactionReply {
  nextAction: Action | undefined;
}

export interface GetActionsRequiredForSetupRequest {}

export interface GetActionsRequiredForSetupReply {
  nextAction: Action | undefined;
}

/** AddDocument */
export interface AddDocumentRequest {
  document: Document | undefined;
}

export interface AddDocumentReply {
  id: string;
}

/** GetUploadDocumentURL */
export interface GetUploadDocumentURLRequest {
  type: DocumentType;
  side: DocumentSide;
  name: string;
  size: number;
  contentType: string;
}

export interface GetUploadDocumentURLReply {
  url: string;
  headers: { [key: string]: string };
}

export interface GetUploadDocumentURLReply_HeadersEntry {
  key: string;
  value: string;
}

export interface UpdateKYCDataRequest {
  updates: Data[];
}

export interface UpdateKYCDataReply {
  data: Data[];
}

function createBaseKYCStatus(): KYCStatus {
  return { actions: [], status: '' };
}

export const KYCStatus = {
  encode(
    message: KYCStatus,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.actions) {
      Action.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.status !== '') {
      writer.uint32(26).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KYCStatus {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKYCStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.actions.push(Action.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KYCStatus {
    return {
      actions: Array.isArray(object?.actions)
        ? object.actions.map((e: any) => Action.fromJSON(e))
        : [],
      status: isSet(object.status) ? String(object.status) : '',
    };
  },

  toJSON(message: KYCStatus): unknown {
    const obj: any = {};
    if (message.actions) {
      obj.actions = message.actions.map((e) =>
        e ? Action.toJSON(e) : undefined,
      );
    } else {
      obj.actions = [];
    }
    message.status !== undefined && (obj.status = message.status);
    return obj;
  },

  create(base?: DeepPartial<KYCStatus>): KYCStatus {
    return KYCStatus.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<KYCStatus>): KYCStatus {
    const message = createBaseKYCStatus();
    message.actions = object.actions?.map((e) => Action.fromPartial(e)) || [];
    message.status = object.status ?? '';
    return message;
  },
};

function createBaseSubmitKYCDataRequest(): SubmitKYCDataRequest {
  return { type: '', data: {} };
}

export const SubmitKYCDataRequest = {
  encode(
    message: SubmitKYCDataRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== '') {
      writer.uint32(10).string(message.type);
    }
    Object.entries(message.data).forEach(([key, value]) => {
      SubmitKYCDataRequest_DataEntry.encode(
        { key: key as any, value },
        writer.uint32(18).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SubmitKYCDataRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitKYCDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = SubmitKYCDataRequest_DataEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry2.value !== undefined) {
            message.data[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitKYCDataRequest {
    return {
      type: isSet(object.type) ? String(object.type) : '',
      data: isObject(object.data)
        ? Object.entries(object.data).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
    };
  },

  toJSON(message: SubmitKYCDataRequest): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = message.type);
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    return obj;
  },

  create(base?: DeepPartial<SubmitKYCDataRequest>): SubmitKYCDataRequest {
    return SubmitKYCDataRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SubmitKYCDataRequest>): SubmitKYCDataRequest {
    const message = createBaseSubmitKYCDataRequest();
    message.type = object.type ?? '';
    message.data = Object.entries(object.data ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseSubmitKYCDataRequest_DataEntry(): SubmitKYCDataRequest_DataEntry {
  return { key: '', value: '' };
}

export const SubmitKYCDataRequest_DataEntry = {
  encode(
    message: SubmitKYCDataRequest_DataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SubmitKYCDataRequest_DataEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitKYCDataRequest_DataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitKYCDataRequest_DataEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: SubmitKYCDataRequest_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<SubmitKYCDataRequest_DataEntry>,
  ): SubmitKYCDataRequest_DataEntry {
    return SubmitKYCDataRequest_DataEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<SubmitKYCDataRequest_DataEntry>,
  ): SubmitKYCDataRequest_DataEntry {
    const message = createBaseSubmitKYCDataRequest_DataEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseSubmitKYCDataReply(): SubmitKYCDataReply {
  return { user: undefined, status: undefined };
}

export const SubmitKYCDataReply = {
  encode(
    message: SubmitKYCDataReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.status !== undefined) {
      KYCStatus.encode(message.status, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubmitKYCDataReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitKYCDataReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.status = KYCStatus.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitKYCDataReply {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      status: isSet(object.status)
        ? KYCStatus.fromJSON(object.status)
        : undefined,
    };
  },

  toJSON(message: SubmitKYCDataReply): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    message.status !== undefined &&
      (obj.status = message.status
        ? KYCStatus.toJSON(message.status)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<SubmitKYCDataReply>): SubmitKYCDataReply {
    return SubmitKYCDataReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SubmitKYCDataReply>): SubmitKYCDataReply {
    const message = createBaseSubmitKYCDataReply();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    message.status =
      object.status !== undefined && object.status !== null
        ? KYCStatus.fromPartial(object.status)
        : undefined;
    return message;
  },
};

function createBaseSubmitRunKYCCheckOutputRequest(): SubmitRunKYCCheckOutputRequest {
  return { checkId: '', data: {} };
}

export const SubmitRunKYCCheckOutputRequest = {
  encode(
    message: SubmitRunKYCCheckOutputRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.checkId !== '') {
      writer.uint32(10).string(message.checkId);
    }
    Object.entries(message.data).forEach(([key, value]) => {
      SubmitRunKYCCheckOutputRequest_DataEntry.encode(
        { key: key as any, value },
        writer.uint32(18).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SubmitRunKYCCheckOutputRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitRunKYCCheckOutputRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.checkId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = SubmitRunKYCCheckOutputRequest_DataEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry2.value !== undefined) {
            message.data[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitRunKYCCheckOutputRequest {
    return {
      checkId: isSet(object.checkId) ? String(object.checkId) : '',
      data: isObject(object.data)
        ? Object.entries(object.data).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
    };
  },

  toJSON(message: SubmitRunKYCCheckOutputRequest): unknown {
    const obj: any = {};
    message.checkId !== undefined && (obj.checkId = message.checkId);
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    return obj;
  },

  create(
    base?: DeepPartial<SubmitRunKYCCheckOutputRequest>,
  ): SubmitRunKYCCheckOutputRequest {
    return SubmitRunKYCCheckOutputRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<SubmitRunKYCCheckOutputRequest>,
  ): SubmitRunKYCCheckOutputRequest {
    const message = createBaseSubmitRunKYCCheckOutputRequest();
    message.checkId = object.checkId ?? '';
    message.data = Object.entries(object.data ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseSubmitRunKYCCheckOutputRequest_DataEntry(): SubmitRunKYCCheckOutputRequest_DataEntry {
  return { key: '', value: '' };
}

export const SubmitRunKYCCheckOutputRequest_DataEntry = {
  encode(
    message: SubmitRunKYCCheckOutputRequest_DataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SubmitRunKYCCheckOutputRequest_DataEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitRunKYCCheckOutputRequest_DataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitRunKYCCheckOutputRequest_DataEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: SubmitRunKYCCheckOutputRequest_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<SubmitRunKYCCheckOutputRequest_DataEntry>,
  ): SubmitRunKYCCheckOutputRequest_DataEntry {
    return SubmitRunKYCCheckOutputRequest_DataEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<SubmitRunKYCCheckOutputRequest_DataEntry>,
  ): SubmitRunKYCCheckOutputRequest_DataEntry {
    const message = createBaseSubmitRunKYCCheckOutputRequest_DataEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseSubmitRunKYCCheckOutputReply(): SubmitRunKYCCheckOutputReply {
  return { user: undefined, status: undefined };
}

export const SubmitRunKYCCheckOutputReply = {
  encode(
    message: SubmitRunKYCCheckOutputReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.status !== undefined) {
      KYCStatus.encode(message.status, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SubmitRunKYCCheckOutputReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitRunKYCCheckOutputReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.status = KYCStatus.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitRunKYCCheckOutputReply {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      status: isSet(object.status)
        ? KYCStatus.fromJSON(object.status)
        : undefined,
    };
  },

  toJSON(message: SubmitRunKYCCheckOutputReply): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    message.status !== undefined &&
      (obj.status = message.status
        ? KYCStatus.toJSON(message.status)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<SubmitRunKYCCheckOutputReply>,
  ): SubmitRunKYCCheckOutputReply {
    return SubmitRunKYCCheckOutputReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<SubmitRunKYCCheckOutputReply>,
  ): SubmitRunKYCCheckOutputReply {
    const message = createBaseSubmitRunKYCCheckOutputReply();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    message.status =
      object.status !== undefined && object.status !== null
        ? KYCStatus.fromPartial(object.status)
        : undefined;
    return message;
  },
};

function createBaseGetKYCStatusRequest(): GetKYCStatusRequest {
  return {};
}

export const GetKYCStatusRequest = {
  encode(
    _: GetKYCStatusRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetKYCStatusRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetKYCStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetKYCStatusRequest {
    return {};
  },

  toJSON(_: GetKYCStatusRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<GetKYCStatusRequest>): GetKYCStatusRequest {
    return GetKYCStatusRequest.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<GetKYCStatusRequest>): GetKYCStatusRequest {
    const message = createBaseGetKYCStatusRequest();
    return message;
  },
};

function createBaseGetKYCStatusReply(): GetKYCStatusReply {
  return { user: undefined, status: undefined };
}

export const GetKYCStatusReply = {
  encode(
    message: GetKYCStatusReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.status !== undefined) {
      KYCStatus.encode(message.status, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetKYCStatusReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetKYCStatusReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.status = KYCStatus.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetKYCStatusReply {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      status: isSet(object.status)
        ? KYCStatus.fromJSON(object.status)
        : undefined,
    };
  },

  toJSON(message: GetKYCStatusReply): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    message.status !== undefined &&
      (obj.status = message.status
        ? KYCStatus.toJSON(message.status)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetKYCStatusReply>): GetKYCStatusReply {
    return GetKYCStatusReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetKYCStatusReply>): GetKYCStatusReply {
    const message = createBaseGetKYCStatusReply();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    message.status =
      object.status !== undefined && object.status !== null
        ? KYCStatus.fromPartial(object.status)
        : undefined;
    return message;
  },
};

function createBaseGetPersonalDetailsRequest(): GetPersonalDetailsRequest {
  return {};
}

export const GetPersonalDetailsRequest = {
  encode(
    _: GetPersonalDetailsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetPersonalDetailsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPersonalDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetPersonalDetailsRequest {
    return {};
  },

  toJSON(_: GetPersonalDetailsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<GetPersonalDetailsRequest>,
  ): GetPersonalDetailsRequest {
    return GetPersonalDetailsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<GetPersonalDetailsRequest>,
  ): GetPersonalDetailsRequest {
    const message = createBaseGetPersonalDetailsRequest();
    return message;
  },
};

function createBaseGetPersonalDetailsReply(): GetPersonalDetailsReply {
  return {
    legalName: undefined,
    preferredName: undefined,
    deprecatedLegalGender: '',
    dateOfBirth: undefined,
    countryOfBirth: '',
    address: undefined,
    emails: [],
    phoneNumbers: [],
    editableFields: [],
    legalGender: 0,
  };
}

export const GetPersonalDetailsReply = {
  encode(
    message: GetPersonalDetailsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.legalName !== undefined) {
      Name.encode(message.legalName, writer.uint32(10).fork()).ldelim();
    }
    if (message.preferredName !== undefined) {
      Name.encode(message.preferredName, writer.uint32(18).fork()).ldelim();
    }
    if (message.deprecatedLegalGender !== '') {
      writer.uint32(26).string(message.deprecatedLegalGender);
    }
    if (message.dateOfBirth !== undefined) {
      Timestamp.encode(message.dateOfBirth, writer.uint32(34).fork()).ldelim();
    }
    if (message.countryOfBirth !== '') {
      writer.uint32(42).string(message.countryOfBirth);
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.emails) {
      ContactDetail.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.phoneNumbers) {
      ContactDetail.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    writer.uint32(90).fork();
    for (const v of message.editableFields) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.legalGender !== 0) {
      writer.uint32(96).int32(message.legalGender);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetPersonalDetailsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPersonalDetailsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.legalName = Name.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.preferredName = Name.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deprecatedLegalGender = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.dateOfBirth = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.countryOfBirth = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.emails.push(ContactDetail.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.phoneNumbers.push(
            ContactDetail.decode(reader, reader.uint32()),
          );
          continue;
        case 11:
          if (tag === 88) {
            message.editableFields.push(reader.int32() as any);

            continue;
          }

          if (tag === 90) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.editableFields.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.legalGender = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPersonalDetailsReply {
    return {
      legalName: isSet(object.legalName)
        ? Name.fromJSON(object.legalName)
        : undefined,
      preferredName: isSet(object.preferredName)
        ? Name.fromJSON(object.preferredName)
        : undefined,
      deprecatedLegalGender: isSet(object.deprecatedLegalGender)
        ? String(object.deprecatedLegalGender)
        : '',
      dateOfBirth: isSet(object.dateOfBirth)
        ? fromJsonTimestamp(object.dateOfBirth)
        : undefined,
      countryOfBirth: isSet(object.countryOfBirth)
        ? String(object.countryOfBirth)
        : '',
      address: isSet(object.address)
        ? Address.fromJSON(object.address)
        : undefined,
      emails: Array.isArray(object?.emails)
        ? object.emails.map((e: any) => ContactDetail.fromJSON(e))
        : [],
      phoneNumbers: Array.isArray(object?.phoneNumbers)
        ? object.phoneNumbers.map((e: any) => ContactDetail.fromJSON(e))
        : [],
      editableFields: Array.isArray(object?.editableFields)
        ? object.editableFields.map((e: any) => dataTypeFromJSON(e))
        : [],
      legalGender: isSet(object.legalGender)
        ? genderFromJSON(object.legalGender)
        : 0,
    };
  },

  toJSON(message: GetPersonalDetailsReply): unknown {
    const obj: any = {};
    message.legalName !== undefined &&
      (obj.legalName = message.legalName
        ? Name.toJSON(message.legalName)
        : undefined);
    message.preferredName !== undefined &&
      (obj.preferredName = message.preferredName
        ? Name.toJSON(message.preferredName)
        : undefined);
    message.deprecatedLegalGender !== undefined &&
      (obj.deprecatedLegalGender = message.deprecatedLegalGender);
    message.dateOfBirth !== undefined &&
      (obj.dateOfBirth = fromTimestamp(message.dateOfBirth).toISOString());
    message.countryOfBirth !== undefined &&
      (obj.countryOfBirth = message.countryOfBirth);
    message.address !== undefined &&
      (obj.address = message.address
        ? Address.toJSON(message.address)
        : undefined);
    if (message.emails) {
      obj.emails = message.emails.map((e) =>
        e ? ContactDetail.toJSON(e) : undefined,
      );
    } else {
      obj.emails = [];
    }
    if (message.phoneNumbers) {
      obj.phoneNumbers = message.phoneNumbers.map((e) =>
        e ? ContactDetail.toJSON(e) : undefined,
      );
    } else {
      obj.phoneNumbers = [];
    }
    if (message.editableFields) {
      obj.editableFields = message.editableFields.map((e) => dataTypeToJSON(e));
    } else {
      obj.editableFields = [];
    }
    message.legalGender !== undefined &&
      (obj.legalGender = genderToJSON(message.legalGender));
    return obj;
  },

  create(base?: DeepPartial<GetPersonalDetailsReply>): GetPersonalDetailsReply {
    return GetPersonalDetailsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetPersonalDetailsReply>,
  ): GetPersonalDetailsReply {
    const message = createBaseGetPersonalDetailsReply();
    message.legalName =
      object.legalName !== undefined && object.legalName !== null
        ? Name.fromPartial(object.legalName)
        : undefined;
    message.preferredName =
      object.preferredName !== undefined && object.preferredName !== null
        ? Name.fromPartial(object.preferredName)
        : undefined;
    message.deprecatedLegalGender = object.deprecatedLegalGender ?? '';
    message.dateOfBirth =
      object.dateOfBirth !== undefined && object.dateOfBirth !== null
        ? Timestamp.fromPartial(object.dateOfBirth)
        : undefined;
    message.countryOfBirth = object.countryOfBirth ?? '';
    message.address =
      object.address !== undefined && object.address !== null
        ? Address.fromPartial(object.address)
        : undefined;
    message.emails =
      object.emails?.map((e) => ContactDetail.fromPartial(e)) || [];
    message.phoneNumbers =
      object.phoneNumbers?.map((e) => ContactDetail.fromPartial(e)) || [];
    message.editableFields = object.editableFields?.map((e) => e) || [];
    message.legalGender = object.legalGender ?? 0;
    return message;
  },
};

function createBaseSetPersonalDetailsRequest(): SetPersonalDetailsRequest {
  return {
    legalName: undefined,
    preferredName: undefined,
    legalGender: 0,
    dateOfBirth: undefined,
    countryOfBirth: '',
    address: undefined,
  };
}

export const SetPersonalDetailsRequest = {
  encode(
    message: SetPersonalDetailsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.legalName !== undefined) {
      Name.encode(message.legalName, writer.uint32(10).fork()).ldelim();
    }
    if (message.preferredName !== undefined) {
      Name.encode(message.preferredName, writer.uint32(18).fork()).ldelim();
    }
    if (message.legalGender !== 0) {
      writer.uint32(24).int32(message.legalGender);
    }
    if (message.dateOfBirth !== undefined) {
      Timestamp.encode(message.dateOfBirth, writer.uint32(34).fork()).ldelim();
    }
    if (message.countryOfBirth !== '') {
      writer.uint32(42).string(message.countryOfBirth);
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPersonalDetailsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPersonalDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.legalName = Name.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.preferredName = Name.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.legalGender = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.dateOfBirth = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.countryOfBirth = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetPersonalDetailsRequest {
    return {
      legalName: isSet(object.legalName)
        ? Name.fromJSON(object.legalName)
        : undefined,
      preferredName: isSet(object.preferredName)
        ? Name.fromJSON(object.preferredName)
        : undefined,
      legalGender: isSet(object.legalGender)
        ? genderFromJSON(object.legalGender)
        : 0,
      dateOfBirth: isSet(object.dateOfBirth)
        ? fromJsonTimestamp(object.dateOfBirth)
        : undefined,
      countryOfBirth: isSet(object.countryOfBirth)
        ? String(object.countryOfBirth)
        : '',
      address: isSet(object.address)
        ? Address.fromJSON(object.address)
        : undefined,
    };
  },

  toJSON(message: SetPersonalDetailsRequest): unknown {
    const obj: any = {};
    message.legalName !== undefined &&
      (obj.legalName = message.legalName
        ? Name.toJSON(message.legalName)
        : undefined);
    message.preferredName !== undefined &&
      (obj.preferredName = message.preferredName
        ? Name.toJSON(message.preferredName)
        : undefined);
    message.legalGender !== undefined &&
      (obj.legalGender = genderToJSON(message.legalGender));
    message.dateOfBirth !== undefined &&
      (obj.dateOfBirth = fromTimestamp(message.dateOfBirth).toISOString());
    message.countryOfBirth !== undefined &&
      (obj.countryOfBirth = message.countryOfBirth);
    message.address !== undefined &&
      (obj.address = message.address
        ? Address.toJSON(message.address)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<SetPersonalDetailsRequest>,
  ): SetPersonalDetailsRequest {
    return SetPersonalDetailsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<SetPersonalDetailsRequest>,
  ): SetPersonalDetailsRequest {
    const message = createBaseSetPersonalDetailsRequest();
    message.legalName =
      object.legalName !== undefined && object.legalName !== null
        ? Name.fromPartial(object.legalName)
        : undefined;
    message.preferredName =
      object.preferredName !== undefined && object.preferredName !== null
        ? Name.fromPartial(object.preferredName)
        : undefined;
    message.legalGender = object.legalGender ?? 0;
    message.dateOfBirth =
      object.dateOfBirth !== undefined && object.dateOfBirth !== null
        ? Timestamp.fromPartial(object.dateOfBirth)
        : undefined;
    message.countryOfBirth = object.countryOfBirth ?? '';
    message.address =
      object.address !== undefined && object.address !== null
        ? Address.fromPartial(object.address)
        : undefined;
    return message;
  },
};

function createBaseSetPersonalDetailsReply(): SetPersonalDetailsReply {
  return {};
}

export const SetPersonalDetailsReply = {
  encode(
    _: SetPersonalDetailsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPersonalDetailsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPersonalDetailsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetPersonalDetailsReply {
    return {};
  },

  toJSON(_: SetPersonalDetailsReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<SetPersonalDetailsReply>): SetPersonalDetailsReply {
    return SetPersonalDetailsReply.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<SetPersonalDetailsReply>,
  ): SetPersonalDetailsReply {
    const message = createBaseSetPersonalDetailsReply();
    return message;
  },
};

function createBaseGetAddressesRequest(): GetAddressesRequest {
  return {};
}

export const GetAddressesRequest = {
  encode(
    _: GetAddressesRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAddressesRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAddressesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetAddressesRequest {
    return {};
  },

  toJSON(_: GetAddressesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<GetAddressesRequest>): GetAddressesRequest {
    return GetAddressesRequest.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<GetAddressesRequest>): GetAddressesRequest {
    const message = createBaseGetAddressesRequest();
    return message;
  },
};

function createBaseGetAddressesReply(): GetAddressesReply {
  return { addresses: [] };
}

export const GetAddressesReply = {
  encode(
    message: GetAddressesReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.addresses) {
      Address.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAddressesReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAddressesReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.addresses.push(Address.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAddressesReply {
    return {
      addresses: Array.isArray(object?.addresses)
        ? object.addresses.map((e: any) => Address.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetAddressesReply): unknown {
    const obj: any = {};
    if (message.addresses) {
      obj.addresses = message.addresses.map((e) =>
        e ? Address.toJSON(e) : undefined,
      );
    } else {
      obj.addresses = [];
    }
    return obj;
  },

  create(base?: DeepPartial<GetAddressesReply>): GetAddressesReply {
    return GetAddressesReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetAddressesReply>): GetAddressesReply {
    const message = createBaseGetAddressesReply();
    message.addresses =
      object.addresses?.map((e) => Address.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetAddressRequest(): SetAddressRequest {
  return { address: undefined };
}

export const SetAddressRequest = {
  encode(
    message: SetAddressRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetAddressRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAddressRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetAddressRequest {
    return {
      address: isSet(object.address)
        ? Address.fromJSON(object.address)
        : undefined,
    };
  },

  toJSON(message: SetAddressRequest): unknown {
    const obj: any = {};
    message.address !== undefined &&
      (obj.address = message.address
        ? Address.toJSON(message.address)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<SetAddressRequest>): SetAddressRequest {
    return SetAddressRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SetAddressRequest>): SetAddressRequest {
    const message = createBaseSetAddressRequest();
    message.address =
      object.address !== undefined && object.address !== null
        ? Address.fromPartial(object.address)
        : undefined;
    return message;
  },
};

function createBaseSetAddressReply(): SetAddressReply {
  return {};
}

export const SetAddressReply = {
  encode(
    _: SetAddressReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetAddressReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAddressReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetAddressReply {
    return {};
  },

  toJSON(_: SetAddressReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<SetAddressReply>): SetAddressReply {
    return SetAddressReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<SetAddressReply>): SetAddressReply {
    const message = createBaseSetAddressReply();
    return message;
  },
};

function createBaseGetActionsRequiredForTransactionRequest(): GetActionsRequiredForTransactionRequest {
  return {
    transactionId: '',
    recipientId: '',
    amountSent: undefined,
    recipientAccountId: '',
    transactionType: 0,
  };
}

export const GetActionsRequiredForTransactionRequest = {
  encode(
    message: GetActionsRequiredForTransactionRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.recipientId !== '') {
      writer.uint32(18).string(message.recipientId);
    }
    if (message.amountSent !== undefined) {
      Money.encode(message.amountSent, writer.uint32(26).fork()).ldelim();
    }
    if (message.recipientAccountId !== '') {
      writer.uint32(34).string(message.recipientAccountId);
    }
    if (message.transactionType !== 0) {
      writer.uint32(40).int32(message.transactionType);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetActionsRequiredForTransactionRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetActionsRequiredForTransactionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.recipientId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.amountSent = Money.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.recipientAccountId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.transactionType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetActionsRequiredForTransactionRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
      recipientId: isSet(object.recipientId) ? String(object.recipientId) : '',
      amountSent: isSet(object.amountSent)
        ? Money.fromJSON(object.amountSent)
        : undefined,
      recipientAccountId: isSet(object.recipientAccountId)
        ? String(object.recipientAccountId)
        : '',
      transactionType: isSet(object.transactionType)
        ? transactionTypeFromJSON(object.transactionType)
        : 0,
    };
  },

  toJSON(message: GetActionsRequiredForTransactionRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    message.recipientId !== undefined &&
      (obj.recipientId = message.recipientId);
    message.amountSent !== undefined &&
      (obj.amountSent = message.amountSent
        ? Money.toJSON(message.amountSent)
        : undefined);
    message.recipientAccountId !== undefined &&
      (obj.recipientAccountId = message.recipientAccountId);
    message.transactionType !== undefined &&
      (obj.transactionType = transactionTypeToJSON(message.transactionType));
    return obj;
  },

  create(
    base?: DeepPartial<GetActionsRequiredForTransactionRequest>,
  ): GetActionsRequiredForTransactionRequest {
    return GetActionsRequiredForTransactionRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetActionsRequiredForTransactionRequest>,
  ): GetActionsRequiredForTransactionRequest {
    const message = createBaseGetActionsRequiredForTransactionRequest();
    message.transactionId = object.transactionId ?? '';
    message.recipientId = object.recipientId ?? '';
    message.amountSent =
      object.amountSent !== undefined && object.amountSent !== null
        ? Money.fromPartial(object.amountSent)
        : undefined;
    message.recipientAccountId = object.recipientAccountId ?? '';
    message.transactionType = object.transactionType ?? 0;
    return message;
  },
};

function createBaseGetActionsRequiredForTransactionReply(): GetActionsRequiredForTransactionReply {
  return { nextAction: undefined };
}

export const GetActionsRequiredForTransactionReply = {
  encode(
    message: GetActionsRequiredForTransactionReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.nextAction !== undefined) {
      Action.encode(message.nextAction, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetActionsRequiredForTransactionReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetActionsRequiredForTransactionReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nextAction = Action.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetActionsRequiredForTransactionReply {
    return {
      nextAction: isSet(object.nextAction)
        ? Action.fromJSON(object.nextAction)
        : undefined,
    };
  },

  toJSON(message: GetActionsRequiredForTransactionReply): unknown {
    const obj: any = {};
    message.nextAction !== undefined &&
      (obj.nextAction = message.nextAction
        ? Action.toJSON(message.nextAction)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<GetActionsRequiredForTransactionReply>,
  ): GetActionsRequiredForTransactionReply {
    return GetActionsRequiredForTransactionReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetActionsRequiredForTransactionReply>,
  ): GetActionsRequiredForTransactionReply {
    const message = createBaseGetActionsRequiredForTransactionReply();
    message.nextAction =
      object.nextAction !== undefined && object.nextAction !== null
        ? Action.fromPartial(object.nextAction)
        : undefined;
    return message;
  },
};

function createBaseGetActionsRequiredForSetupRequest(): GetActionsRequiredForSetupRequest {
  return {};
}

export const GetActionsRequiredForSetupRequest = {
  encode(
    _: GetActionsRequiredForSetupRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetActionsRequiredForSetupRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetActionsRequiredForSetupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetActionsRequiredForSetupRequest {
    return {};
  },

  toJSON(_: GetActionsRequiredForSetupRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<GetActionsRequiredForSetupRequest>,
  ): GetActionsRequiredForSetupRequest {
    return GetActionsRequiredForSetupRequest.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<GetActionsRequiredForSetupRequest>,
  ): GetActionsRequiredForSetupRequest {
    const message = createBaseGetActionsRequiredForSetupRequest();
    return message;
  },
};

function createBaseGetActionsRequiredForSetupReply(): GetActionsRequiredForSetupReply {
  return { nextAction: undefined };
}

export const GetActionsRequiredForSetupReply = {
  encode(
    message: GetActionsRequiredForSetupReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.nextAction !== undefined) {
      Action.encode(message.nextAction, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetActionsRequiredForSetupReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetActionsRequiredForSetupReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nextAction = Action.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetActionsRequiredForSetupReply {
    return {
      nextAction: isSet(object.nextAction)
        ? Action.fromJSON(object.nextAction)
        : undefined,
    };
  },

  toJSON(message: GetActionsRequiredForSetupReply): unknown {
    const obj: any = {};
    message.nextAction !== undefined &&
      (obj.nextAction = message.nextAction
        ? Action.toJSON(message.nextAction)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<GetActionsRequiredForSetupReply>,
  ): GetActionsRequiredForSetupReply {
    return GetActionsRequiredForSetupReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetActionsRequiredForSetupReply>,
  ): GetActionsRequiredForSetupReply {
    const message = createBaseGetActionsRequiredForSetupReply();
    message.nextAction =
      object.nextAction !== undefined && object.nextAction !== null
        ? Action.fromPartial(object.nextAction)
        : undefined;
    return message;
  },
};

function createBaseAddDocumentRequest(): AddDocumentRequest {
  return { document: undefined };
}

export const AddDocumentRequest = {
  encode(
    message: AddDocumentRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.document !== undefined) {
      Document.encode(message.document, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddDocumentRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddDocumentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.document = Document.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddDocumentRequest {
    return {
      document: isSet(object.document)
        ? Document.fromJSON(object.document)
        : undefined,
    };
  },

  toJSON(message: AddDocumentRequest): unknown {
    const obj: any = {};
    message.document !== undefined &&
      (obj.document = message.document
        ? Document.toJSON(message.document)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<AddDocumentRequest>): AddDocumentRequest {
    return AddDocumentRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<AddDocumentRequest>): AddDocumentRequest {
    const message = createBaseAddDocumentRequest();
    message.document =
      object.document !== undefined && object.document !== null
        ? Document.fromPartial(object.document)
        : undefined;
    return message;
  },
};

function createBaseAddDocumentReply(): AddDocumentReply {
  return { id: '' };
}

export const AddDocumentReply = {
  encode(
    message: AddDocumentReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddDocumentReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddDocumentReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddDocumentReply {
    return { id: isSet(object.id) ? String(object.id) : '' };
  },

  toJSON(message: AddDocumentReply): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create(base?: DeepPartial<AddDocumentReply>): AddDocumentReply {
    return AddDocumentReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<AddDocumentReply>): AddDocumentReply {
    const message = createBaseAddDocumentReply();
    message.id = object.id ?? '';
    return message;
  },
};

function createBaseGetUploadDocumentURLRequest(): GetUploadDocumentURLRequest {
  return { type: 0, side: 0, name: '', size: 0, contentType: '' };
}

export const GetUploadDocumentURLRequest = {
  encode(
    message: GetUploadDocumentURLRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.side !== 0) {
      writer.uint32(16).int32(message.side);
    }
    if (message.name !== '') {
      writer.uint32(26).string(message.name);
    }
    if (message.size !== 0) {
      writer.uint32(32).int32(message.size);
    }
    if (message.contentType !== '') {
      writer.uint32(42).string(message.contentType);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetUploadDocumentURLRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUploadDocumentURLRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.side = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.size = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.contentType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUploadDocumentURLRequest {
    return {
      type: isSet(object.type) ? documentTypeFromJSON(object.type) : 0,
      side: isSet(object.side) ? documentSideFromJSON(object.side) : 0,
      name: isSet(object.name) ? String(object.name) : '',
      size: isSet(object.size) ? Number(object.size) : 0,
      contentType: isSet(object.contentType) ? String(object.contentType) : '',
    };
  },

  toJSON(message: GetUploadDocumentURLRequest): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = documentTypeToJSON(message.type));
    message.side !== undefined && (obj.side = documentSideToJSON(message.side));
    message.name !== undefined && (obj.name = message.name);
    message.size !== undefined && (obj.size = Math.round(message.size));
    message.contentType !== undefined &&
      (obj.contentType = message.contentType);
    return obj;
  },

  create(
    base?: DeepPartial<GetUploadDocumentURLRequest>,
  ): GetUploadDocumentURLRequest {
    return GetUploadDocumentURLRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetUploadDocumentURLRequest>,
  ): GetUploadDocumentURLRequest {
    const message = createBaseGetUploadDocumentURLRequest();
    message.type = object.type ?? 0;
    message.side = object.side ?? 0;
    message.name = object.name ?? '';
    message.size = object.size ?? 0;
    message.contentType = object.contentType ?? '';
    return message;
  },
};

function createBaseGetUploadDocumentURLReply(): GetUploadDocumentURLReply {
  return { url: '', headers: {} };
}

export const GetUploadDocumentURLReply = {
  encode(
    message: GetUploadDocumentURLReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.url !== '') {
      writer.uint32(10).string(message.url);
    }
    Object.entries(message.headers).forEach(([key, value]) => {
      GetUploadDocumentURLReply_HeadersEntry.encode(
        { key: key as any, value },
        writer.uint32(18).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetUploadDocumentURLReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUploadDocumentURLReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = GetUploadDocumentURLReply_HeadersEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry2.value !== undefined) {
            message.headers[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUploadDocumentURLReply {
    return {
      url: isSet(object.url) ? String(object.url) : '',
      headers: isObject(object.headers)
        ? Object.entries(object.headers).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
    };
  },

  toJSON(message: GetUploadDocumentURLReply): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    obj.headers = {};
    if (message.headers) {
      Object.entries(message.headers).forEach(([k, v]) => {
        obj.headers[k] = v;
      });
    }
    return obj;
  },

  create(
    base?: DeepPartial<GetUploadDocumentURLReply>,
  ): GetUploadDocumentURLReply {
    return GetUploadDocumentURLReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetUploadDocumentURLReply>,
  ): GetUploadDocumentURLReply {
    const message = createBaseGetUploadDocumentURLReply();
    message.url = object.url ?? '';
    message.headers = Object.entries(object.headers ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseGetUploadDocumentURLReply_HeadersEntry(): GetUploadDocumentURLReply_HeadersEntry {
  return { key: '', value: '' };
}

export const GetUploadDocumentURLReply_HeadersEntry = {
  encode(
    message: GetUploadDocumentURLReply_HeadersEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetUploadDocumentURLReply_HeadersEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUploadDocumentURLReply_HeadersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUploadDocumentURLReply_HeadersEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: GetUploadDocumentURLReply_HeadersEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<GetUploadDocumentURLReply_HeadersEntry>,
  ): GetUploadDocumentURLReply_HeadersEntry {
    return GetUploadDocumentURLReply_HeadersEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetUploadDocumentURLReply_HeadersEntry>,
  ): GetUploadDocumentURLReply_HeadersEntry {
    const message = createBaseGetUploadDocumentURLReply_HeadersEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseUpdateKYCDataRequest(): UpdateKYCDataRequest {
  return { updates: [] };
}

export const UpdateKYCDataRequest = {
  encode(
    message: UpdateKYCDataRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.updates) {
      Data.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UpdateKYCDataRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateKYCDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.updates.push(Data.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateKYCDataRequest {
    return {
      updates: Array.isArray(object?.updates)
        ? object.updates.map((e: any) => Data.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateKYCDataRequest): unknown {
    const obj: any = {};
    if (message.updates) {
      obj.updates = message.updates.map((e) =>
        e ? Data.toJSON(e) : undefined,
      );
    } else {
      obj.updates = [];
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateKYCDataRequest>): UpdateKYCDataRequest {
    return UpdateKYCDataRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<UpdateKYCDataRequest>): UpdateKYCDataRequest {
    const message = createBaseUpdateKYCDataRequest();
    message.updates = object.updates?.map((e) => Data.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateKYCDataReply(): UpdateKYCDataReply {
  return { data: [] };
}

export const UpdateKYCDataReply = {
  encode(
    message: UpdateKYCDataReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.data) {
      Data.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateKYCDataReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateKYCDataReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data.push(Data.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateKYCDataReply {
    return {
      data: Array.isArray(object?.data)
        ? object.data.map((e: any) => Data.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateKYCDataReply): unknown {
    const obj: any = {};
    if (message.data) {
      obj.data = message.data.map((e) => (e ? Data.toJSON(e) : undefined));
    } else {
      obj.data = [];
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateKYCDataReply>): UpdateKYCDataReply {
    return UpdateKYCDataReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<UpdateKYCDataReply>): UpdateKYCDataReply {
    const message = createBaseUpdateKYCDataReply();
    message.data = object.data?.map((e) => Data.fromPartial(e)) || [];
    return message;
  },
};

export interface KYC {
  /** buf:lint:ignore COMMENT_RPC */
  GetKYCStatus(
    request: DeepPartial<GetKYCStatusRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetKYCStatusReply>;
  /** buf:lint:ignore COMMENT_RPC */
  SubmitKYCData(
    request: DeepPartial<SubmitKYCDataRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SubmitKYCDataReply>;
  /** buf:lint:ignore COMMENT_RPC */
  SubmitRunKYCCheckOutput(
    request: DeepPartial<SubmitRunKYCCheckOutputRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SubmitRunKYCCheckOutputReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetAddresses(
    request: DeepPartial<GetAddressesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetAddressesReply>;
  /** buf:lint:ignore COMMENT_RPC */
  SetAddress(
    request: DeepPartial<SetAddressRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetAddressReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetPersonalDetails(
    request: DeepPartial<GetPersonalDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetPersonalDetailsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  SetPersonalDetails(
    request: DeepPartial<SetPersonalDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetPersonalDetailsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetActionsRequiredForTransaction(
    request: DeepPartial<GetActionsRequiredForTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetActionsRequiredForTransactionReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetActionsRequiredForSetup(
    request: DeepPartial<GetActionsRequiredForSetupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetActionsRequiredForSetupReply>;
  /** buf:lint:ignore COMMENT_RPC */
  AddDocument(
    request: DeepPartial<AddDocumentRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddDocumentReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetUploadDocumentURL(
    request: DeepPartial<GetUploadDocumentURLRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetUploadDocumentURLReply>;
  /** UpdateKYCData using a list of updates */
  UpdateKYCData(
    request: DeepPartial<UpdateKYCDataRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateKYCDataReply>;
}

export class KYCClientImpl implements KYC {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetKYCStatus = this.GetKYCStatus.bind(this);
    this.SubmitKYCData = this.SubmitKYCData.bind(this);
    this.SubmitRunKYCCheckOutput = this.SubmitRunKYCCheckOutput.bind(this);
    this.GetAddresses = this.GetAddresses.bind(this);
    this.SetAddress = this.SetAddress.bind(this);
    this.GetPersonalDetails = this.GetPersonalDetails.bind(this);
    this.SetPersonalDetails = this.SetPersonalDetails.bind(this);
    this.GetActionsRequiredForTransaction =
      this.GetActionsRequiredForTransaction.bind(this);
    this.GetActionsRequiredForSetup =
      this.GetActionsRequiredForSetup.bind(this);
    this.AddDocument = this.AddDocument.bind(this);
    this.GetUploadDocumentURL = this.GetUploadDocumentURL.bind(this);
    this.UpdateKYCData = this.UpdateKYCData.bind(this);
  }

  GetKYCStatus(
    request: DeepPartial<GetKYCStatusRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetKYCStatusReply> {
    return this.rpc.unary(
      KYCGetKYCStatusDesc,
      GetKYCStatusRequest.fromPartial(request),
      metadata,
    );
  }

  SubmitKYCData(
    request: DeepPartial<SubmitKYCDataRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SubmitKYCDataReply> {
    return this.rpc.unary(
      KYCSubmitKYCDataDesc,
      SubmitKYCDataRequest.fromPartial(request),
      metadata,
    );
  }

  SubmitRunKYCCheckOutput(
    request: DeepPartial<SubmitRunKYCCheckOutputRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SubmitRunKYCCheckOutputReply> {
    return this.rpc.unary(
      KYCSubmitRunKYCCheckOutputDesc,
      SubmitRunKYCCheckOutputRequest.fromPartial(request),
      metadata,
    );
  }

  GetAddresses(
    request: DeepPartial<GetAddressesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetAddressesReply> {
    return this.rpc.unary(
      KYCGetAddressesDesc,
      GetAddressesRequest.fromPartial(request),
      metadata,
    );
  }

  SetAddress(
    request: DeepPartial<SetAddressRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetAddressReply> {
    return this.rpc.unary(
      KYCSetAddressDesc,
      SetAddressRequest.fromPartial(request),
      metadata,
    );
  }

  GetPersonalDetails(
    request: DeepPartial<GetPersonalDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetPersonalDetailsReply> {
    return this.rpc.unary(
      KYCGetPersonalDetailsDesc,
      GetPersonalDetailsRequest.fromPartial(request),
      metadata,
    );
  }

  SetPersonalDetails(
    request: DeepPartial<SetPersonalDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetPersonalDetailsReply> {
    return this.rpc.unary(
      KYCSetPersonalDetailsDesc,
      SetPersonalDetailsRequest.fromPartial(request),
      metadata,
    );
  }

  GetActionsRequiredForTransaction(
    request: DeepPartial<GetActionsRequiredForTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetActionsRequiredForTransactionReply> {
    return this.rpc.unary(
      KYCGetActionsRequiredForTransactionDesc,
      GetActionsRequiredForTransactionRequest.fromPartial(request),
      metadata,
    );
  }

  GetActionsRequiredForSetup(
    request: DeepPartial<GetActionsRequiredForSetupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetActionsRequiredForSetupReply> {
    return this.rpc.unary(
      KYCGetActionsRequiredForSetupDesc,
      GetActionsRequiredForSetupRequest.fromPartial(request),
      metadata,
    );
  }

  AddDocument(
    request: DeepPartial<AddDocumentRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddDocumentReply> {
    return this.rpc.unary(
      KYCAddDocumentDesc,
      AddDocumentRequest.fromPartial(request),
      metadata,
    );
  }

  GetUploadDocumentURL(
    request: DeepPartial<GetUploadDocumentURLRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetUploadDocumentURLReply> {
    return this.rpc.unary(
      KYCGetUploadDocumentURLDesc,
      GetUploadDocumentURLRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateKYCData(
    request: DeepPartial<UpdateKYCDataRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateKYCDataReply> {
    return this.rpc.unary(
      KYCUpdateKYCDataDesc,
      UpdateKYCDataRequest.fromPartial(request),
      metadata,
    );
  }
}

export const KYCDesc = { serviceName: 'public.KYC' };

export const KYCGetKYCStatusDesc: UnaryMethodDefinitionish = {
  methodName: 'GetKYCStatus',
  service: KYCDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetKYCStatusRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetKYCStatusReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const KYCSubmitKYCDataDesc: UnaryMethodDefinitionish = {
  methodName: 'SubmitKYCData',
  service: KYCDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SubmitKYCDataRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SubmitKYCDataReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const KYCSubmitRunKYCCheckOutputDesc: UnaryMethodDefinitionish = {
  methodName: 'SubmitRunKYCCheckOutput',
  service: KYCDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SubmitRunKYCCheckOutputRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SubmitRunKYCCheckOutputReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const KYCGetAddressesDesc: UnaryMethodDefinitionish = {
  methodName: 'GetAddresses',
  service: KYCDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAddressesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetAddressesReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const KYCSetAddressDesc: UnaryMethodDefinitionish = {
  methodName: 'SetAddress',
  service: KYCDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetAddressRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SetAddressReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const KYCGetPersonalDetailsDesc: UnaryMethodDefinitionish = {
  methodName: 'GetPersonalDetails',
  service: KYCDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetPersonalDetailsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetPersonalDetailsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const KYCSetPersonalDetailsDesc: UnaryMethodDefinitionish = {
  methodName: 'SetPersonalDetails',
  service: KYCDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetPersonalDetailsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SetPersonalDetailsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const KYCGetActionsRequiredForTransactionDesc: UnaryMethodDefinitionish =
  {
    methodName: 'GetActionsRequiredForTransaction',
    service: KYCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return GetActionsRequiredForTransactionRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = GetActionsRequiredForTransactionReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const KYCGetActionsRequiredForSetupDesc: UnaryMethodDefinitionish = {
  methodName: 'GetActionsRequiredForSetup',
  service: KYCDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetActionsRequiredForSetupRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetActionsRequiredForSetupReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const KYCAddDocumentDesc: UnaryMethodDefinitionish = {
  methodName: 'AddDocument',
  service: KYCDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddDocumentRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AddDocumentReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const KYCGetUploadDocumentURLDesc: UnaryMethodDefinitionish = {
  methodName: 'GetUploadDocumentURL',
  service: KYCDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUploadDocumentURLRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetUploadDocumentURLReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const KYCUpdateKYCDataDesc: UnaryMethodDefinitionish = {
  methodName: 'UpdateKYCData',
  service: KYCDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateKYCDataRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateKYCDataReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(
              response.statusMessage,
              response.status,
              response.trailers,
            );
            reject(err);
          }
        },
      });
    });
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Timestamp {
  if (o instanceof Date) {
    return toTimestamp(o);
  } else if (typeof o === 'string') {
    return toTimestamp(new Date(o));
  } else {
    return Timestamp.fromJSON(o);
  }
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(
    message: string,
    public code: grpc.Code,
    public metadata: grpc.Metadata,
  ) {
    super(message);
  }
}
