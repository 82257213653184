/* eslint-disable */
import _m0 from 'protobufjs/minimal';
import { Timestamp } from '../../../google/protobuf/timestamp';
import { PaymentSummary } from '../collections/types';
import { Recipient, RecipientAccount } from '../disbursements/types';
import { ClientQuote, Money, Quote } from '../fx/types';
import { ETA, TimeRange } from '../utils/types';
import { Wallet } from '../wallets/types';
import { Summary } from './summary';

export const protobufPackage = 'transactions';

/** buf:lint:ignore COMMENT_ENUM */
export enum PublicState {
  PUBLIC_STATE_INVALID = 0,
  PUBLIC_STATE_WAITING_FOR_CUSTOMER_FUNDS = 1,
  PUBLIC_STATE_CANCELLED = 2,
  PUBLIC_STATE_FAILED = 3,
  PUBLIC_STATE_IN_PROGRESS = 4,
  PUBLIC_STATE_COMPLETED = 5,
  PUBLIC_STATE_WAITING_FOR_PAYMENT = 6,
  PUBLIC_STATE_REFUNDED = 7,
  PUBLIC_STATE_CREATED = 8,
  PUBLIC_STATE_WAITING_FOR_APPROVAL = 9,
  PUBLIC_STATE_REJECTED = 10,
  UNRECOGNIZED = -1,
}

export function publicStateFromJSON(object: any): PublicState {
  switch (object) {
    case 0:
    case 'PUBLIC_STATE_INVALID':
      return PublicState.PUBLIC_STATE_INVALID;
    case 1:
    case 'PUBLIC_STATE_WAITING_FOR_CUSTOMER_FUNDS':
      return PublicState.PUBLIC_STATE_WAITING_FOR_CUSTOMER_FUNDS;
    case 2:
    case 'PUBLIC_STATE_CANCELLED':
      return PublicState.PUBLIC_STATE_CANCELLED;
    case 3:
    case 'PUBLIC_STATE_FAILED':
      return PublicState.PUBLIC_STATE_FAILED;
    case 4:
    case 'PUBLIC_STATE_IN_PROGRESS':
      return PublicState.PUBLIC_STATE_IN_PROGRESS;
    case 5:
    case 'PUBLIC_STATE_COMPLETED':
      return PublicState.PUBLIC_STATE_COMPLETED;
    case 6:
    case 'PUBLIC_STATE_WAITING_FOR_PAYMENT':
      return PublicState.PUBLIC_STATE_WAITING_FOR_PAYMENT;
    case 7:
    case 'PUBLIC_STATE_REFUNDED':
      return PublicState.PUBLIC_STATE_REFUNDED;
    case 8:
    case 'PUBLIC_STATE_CREATED':
      return PublicState.PUBLIC_STATE_CREATED;
    case 9:
    case 'PUBLIC_STATE_WAITING_FOR_APPROVAL':
      return PublicState.PUBLIC_STATE_WAITING_FOR_APPROVAL;
    case 10:
    case 'PUBLIC_STATE_REJECTED':
      return PublicState.PUBLIC_STATE_REJECTED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PublicState.UNRECOGNIZED;
  }
}

export function publicStateToJSON(object: PublicState): string {
  switch (object) {
    case PublicState.PUBLIC_STATE_INVALID:
      return 'PUBLIC_STATE_INVALID';
    case PublicState.PUBLIC_STATE_WAITING_FOR_CUSTOMER_FUNDS:
      return 'PUBLIC_STATE_WAITING_FOR_CUSTOMER_FUNDS';
    case PublicState.PUBLIC_STATE_CANCELLED:
      return 'PUBLIC_STATE_CANCELLED';
    case PublicState.PUBLIC_STATE_FAILED:
      return 'PUBLIC_STATE_FAILED';
    case PublicState.PUBLIC_STATE_IN_PROGRESS:
      return 'PUBLIC_STATE_IN_PROGRESS';
    case PublicState.PUBLIC_STATE_COMPLETED:
      return 'PUBLIC_STATE_COMPLETED';
    case PublicState.PUBLIC_STATE_WAITING_FOR_PAYMENT:
      return 'PUBLIC_STATE_WAITING_FOR_PAYMENT';
    case PublicState.PUBLIC_STATE_REFUNDED:
      return 'PUBLIC_STATE_REFUNDED';
    case PublicState.PUBLIC_STATE_CREATED:
      return 'PUBLIC_STATE_CREATED';
    case PublicState.PUBLIC_STATE_WAITING_FOR_APPROVAL:
      return 'PUBLIC_STATE_WAITING_FOR_APPROVAL';
    case PublicState.PUBLIC_STATE_REJECTED:
      return 'PUBLIC_STATE_REJECTED';
    case PublicState.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum TransactionType {
  TRANSACTION_TYPE_INVALID = 0,
  TRANSACTION_TYPE_COLLECTION_CONVERSION_DISBURSEMENT = 1,
  TRANSACTION_TYPE_COLLECTION = 2,
  TRANSACTION_TYPE_CONVERSION = 3,
  TRANSACTION_TYPE_DISBURSEMENT = 4,
  TRANSACTION_TYPE_CONVERSION_DISBURSEMENT = 7,
  TRANSACTION_TYPE_COLLECTION_CONVERSION = 8,
  TRANSACTION_TYPE_EARNED_REWARD = 9,
  TRANSACTION_TYPE_REVERSAL = 10,
  TRANSACTION_TYPE_INCOMING = 11,
  UNRECOGNIZED = -1,
}

export function transactionTypeFromJSON(object: any): TransactionType {
  switch (object) {
    case 0:
    case 'TRANSACTION_TYPE_INVALID':
      return TransactionType.TRANSACTION_TYPE_INVALID;
    case 1:
    case 'TRANSACTION_TYPE_COLLECTION_CONVERSION_DISBURSEMENT':
      return TransactionType.TRANSACTION_TYPE_COLLECTION_CONVERSION_DISBURSEMENT;
    case 2:
    case 'TRANSACTION_TYPE_COLLECTION':
      return TransactionType.TRANSACTION_TYPE_COLLECTION;
    case 3:
    case 'TRANSACTION_TYPE_CONVERSION':
      return TransactionType.TRANSACTION_TYPE_CONVERSION;
    case 4:
    case 'TRANSACTION_TYPE_DISBURSEMENT':
      return TransactionType.TRANSACTION_TYPE_DISBURSEMENT;
    case 7:
    case 'TRANSACTION_TYPE_CONVERSION_DISBURSEMENT':
      return TransactionType.TRANSACTION_TYPE_CONVERSION_DISBURSEMENT;
    case 8:
    case 'TRANSACTION_TYPE_COLLECTION_CONVERSION':
      return TransactionType.TRANSACTION_TYPE_COLLECTION_CONVERSION;
    case 9:
    case 'TRANSACTION_TYPE_EARNED_REWARD':
      return TransactionType.TRANSACTION_TYPE_EARNED_REWARD;
    case 10:
    case 'TRANSACTION_TYPE_REVERSAL':
      return TransactionType.TRANSACTION_TYPE_REVERSAL;
    case 11:
    case 'TRANSACTION_TYPE_INCOMING':
      return TransactionType.TRANSACTION_TYPE_INCOMING;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TransactionType.UNRECOGNIZED;
  }
}

export function transactionTypeToJSON(object: TransactionType): string {
  switch (object) {
    case TransactionType.TRANSACTION_TYPE_INVALID:
      return 'TRANSACTION_TYPE_INVALID';
    case TransactionType.TRANSACTION_TYPE_COLLECTION_CONVERSION_DISBURSEMENT:
      return 'TRANSACTION_TYPE_COLLECTION_CONVERSION_DISBURSEMENT';
    case TransactionType.TRANSACTION_TYPE_COLLECTION:
      return 'TRANSACTION_TYPE_COLLECTION';
    case TransactionType.TRANSACTION_TYPE_CONVERSION:
      return 'TRANSACTION_TYPE_CONVERSION';
    case TransactionType.TRANSACTION_TYPE_DISBURSEMENT:
      return 'TRANSACTION_TYPE_DISBURSEMENT';
    case TransactionType.TRANSACTION_TYPE_CONVERSION_DISBURSEMENT:
      return 'TRANSACTION_TYPE_CONVERSION_DISBURSEMENT';
    case TransactionType.TRANSACTION_TYPE_COLLECTION_CONVERSION:
      return 'TRANSACTION_TYPE_COLLECTION_CONVERSION';
    case TransactionType.TRANSACTION_TYPE_EARNED_REWARD:
      return 'TRANSACTION_TYPE_EARNED_REWARD';
    case TransactionType.TRANSACTION_TYPE_REVERSAL:
      return 'TRANSACTION_TYPE_REVERSAL';
    case TransactionType.TRANSACTION_TYPE_INCOMING:
      return 'TRANSACTION_TYPE_INCOMING';
    case TransactionType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum StatementOutputFormat {
  STATEMENT_OUTPUT_FORMAT_INVALID = 0,
  STATEMENT_OUTPUT_FORMAT_CSV = 1,
  STATEMENT_OUTPUT_FORMAT_XLS = 2,
  STATEMENT_OUTPUT_FORMAT_PDF = 3,
  UNRECOGNIZED = -1,
}

export function statementOutputFormatFromJSON(
  object: any,
): StatementOutputFormat {
  switch (object) {
    case 0:
    case 'STATEMENT_OUTPUT_FORMAT_INVALID':
      return StatementOutputFormat.STATEMENT_OUTPUT_FORMAT_INVALID;
    case 1:
    case 'STATEMENT_OUTPUT_FORMAT_CSV':
      return StatementOutputFormat.STATEMENT_OUTPUT_FORMAT_CSV;
    case 2:
    case 'STATEMENT_OUTPUT_FORMAT_XLS':
      return StatementOutputFormat.STATEMENT_OUTPUT_FORMAT_XLS;
    case 3:
    case 'STATEMENT_OUTPUT_FORMAT_PDF':
      return StatementOutputFormat.STATEMENT_OUTPUT_FORMAT_PDF;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return StatementOutputFormat.UNRECOGNIZED;
  }
}

export function statementOutputFormatToJSON(
  object: StatementOutputFormat,
): string {
  switch (object) {
    case StatementOutputFormat.STATEMENT_OUTPUT_FORMAT_INVALID:
      return 'STATEMENT_OUTPUT_FORMAT_INVALID';
    case StatementOutputFormat.STATEMENT_OUTPUT_FORMAT_CSV:
      return 'STATEMENT_OUTPUT_FORMAT_CSV';
    case StatementOutputFormat.STATEMENT_OUTPUT_FORMAT_XLS:
      return 'STATEMENT_OUTPUT_FORMAT_XLS';
    case StatementOutputFormat.STATEMENT_OUTPUT_FORMAT_PDF:
      return 'STATEMENT_OUTPUT_FORMAT_PDF';
    case StatementOutputFormat.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Transaction contains the details of a transaction. */
export interface Transaction {
  id: string;
  /** @deprecated */
  deprecatedState: string;
  recipient: Recipient | undefined;
  quote: Quote | undefined;
  amountSent: Money | undefined;
  amountReceived: Money | undefined;
  /**
   * Not used anymore
   *
   * @deprecated
   */
  deprecatedSenderId: string;
  createdAt: Timestamp | undefined;
  lastUpdatedAt: Timestamp | undefined;
  expiresAt: Timestamp | undefined;
  memo: string;
  recipientMessage: string;
  publicState: PublicState;
  recipientAccount: RecipientAccount | undefined;
  flags: { [key: string]: string };
  eta: ETA | undefined;
  collectionEta: ETA | undefined;
  accountId: string;
  type: TransactionType;
  fromWallet: Wallet | undefined;
  toWallet: Wallet | undefined;
  summary: Summary | undefined;
  /**
   * "Proof of Payment" (preferred label) / "Receipt Number".
   * For mobile money, this is the confirmation code in the SMS from the operator.
   */
  receiptNumber: string;
  reference: string;
  /** Includes details like KPLC (electricity) token but excludes receipt_number */
  userViewableDetails: UserViewableDetail[];
  userId: string;
  /**
   * To be used with AdditionalPaymentMethods (Apple/Google Pay) and in case the collection (if it exists)
   * is pending on the customer to initiate.
   */
  paymentSummary: PaymentSummary | undefined;
  purpose: string;
}

export interface Transaction_FlagsEntry {
  key: string;
  value: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface UserViewableDetail {
  key: string;
  keyDisplay: string;
  value: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface TransactionUpdate {
  /** @deprecated */
  deprecatedState: string;
  createdAt: Timestamp | undefined;
  publicState: PublicState;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface ExternalID {
  providerName: string;
  providerId: string;
  customerReference: string;
}

/**
 * TransactionReview is an internal message used for bulk payments.
 * When a bulk payment spreadsheet is uploaded, it gets processed into transaction reviews and is returned to the N4B platform.
 * These "transactions" can be edited/reviewed on the N4B platform before processing the transactions.
 */
export interface TransactionReview {
  recipientAccount: RecipientAccount | undefined;
  clientQuote: ClientQuote | undefined;
  isValid: boolean;
  validationErrors: string[];
}

/** TransactionsFilter specifies the available filters for listing transactions */
export interface TransactionsFilter {
  recipientCountry: string;
  publicState: PublicState;
  createdAtTimeRange: TimeRange | undefined;
}

function createBaseTransaction(): Transaction {
  return {
    id: '',
    deprecatedState: '',
    recipient: undefined,
    quote: undefined,
    amountSent: undefined,
    amountReceived: undefined,
    deprecatedSenderId: '',
    createdAt: undefined,
    lastUpdatedAt: undefined,
    expiresAt: undefined,
    memo: '',
    recipientMessage: '',
    publicState: 0,
    recipientAccount: undefined,
    flags: {},
    eta: undefined,
    collectionEta: undefined,
    accountId: '',
    type: 0,
    fromWallet: undefined,
    toWallet: undefined,
    summary: undefined,
    receiptNumber: '',
    reference: '',
    userViewableDetails: [],
    userId: '',
    paymentSummary: undefined,
    purpose: '',
  };
}

export const Transaction = {
  encode(
    message: Transaction,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.deprecatedState !== '') {
      writer.uint32(18).string(message.deprecatedState);
    }
    if (message.recipient !== undefined) {
      Recipient.encode(message.recipient, writer.uint32(26).fork()).ldelim();
    }
    if (message.quote !== undefined) {
      Quote.encode(message.quote, writer.uint32(34).fork()).ldelim();
    }
    if (message.amountSent !== undefined) {
      Money.encode(message.amountSent, writer.uint32(42).fork()).ldelim();
    }
    if (message.amountReceived !== undefined) {
      Money.encode(message.amountReceived, writer.uint32(50).fork()).ldelim();
    }
    if (message.deprecatedSenderId !== '') {
      writer.uint32(58).string(message.deprecatedSenderId);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(66).fork()).ldelim();
    }
    if (message.lastUpdatedAt !== undefined) {
      Timestamp.encode(
        message.lastUpdatedAt,
        writer.uint32(74).fork(),
      ).ldelim();
    }
    if (message.expiresAt !== undefined) {
      Timestamp.encode(message.expiresAt, writer.uint32(82).fork()).ldelim();
    }
    if (message.memo !== '') {
      writer.uint32(90).string(message.memo);
    }
    if (message.recipientMessage !== '') {
      writer.uint32(98).string(message.recipientMessage);
    }
    if (message.publicState !== 0) {
      writer.uint32(104).int32(message.publicState);
    }
    if (message.recipientAccount !== undefined) {
      RecipientAccount.encode(
        message.recipientAccount,
        writer.uint32(122).fork(),
      ).ldelim();
    }
    Object.entries(message.flags).forEach(([key, value]) => {
      Transaction_FlagsEntry.encode(
        { key: key as any, value },
        writer.uint32(130).fork(),
      ).ldelim();
    });
    if (message.eta !== undefined) {
      ETA.encode(message.eta, writer.uint32(138).fork()).ldelim();
    }
    if (message.collectionEta !== undefined) {
      ETA.encode(message.collectionEta, writer.uint32(146).fork()).ldelim();
    }
    if (message.accountId !== '') {
      writer.uint32(154).string(message.accountId);
    }
    if (message.type !== 0) {
      writer.uint32(160).int32(message.type);
    }
    if (message.fromWallet !== undefined) {
      Wallet.encode(message.fromWallet, writer.uint32(170).fork()).ldelim();
    }
    if (message.toWallet !== undefined) {
      Wallet.encode(message.toWallet, writer.uint32(178).fork()).ldelim();
    }
    if (message.summary !== undefined) {
      Summary.encode(message.summary, writer.uint32(186).fork()).ldelim();
    }
    if (message.receiptNumber !== '') {
      writer.uint32(194).string(message.receiptNumber);
    }
    if (message.reference !== '') {
      writer.uint32(202).string(message.reference);
    }
    for (const v of message.userViewableDetails) {
      UserViewableDetail.encode(v!, writer.uint32(210).fork()).ldelim();
    }
    if (message.userId !== '') {
      writer.uint32(218).string(message.userId);
    }
    if (message.paymentSummary !== undefined) {
      PaymentSummary.encode(
        message.paymentSummary,
        writer.uint32(226).fork(),
      ).ldelim();
    }
    if (message.purpose !== '') {
      writer.uint32(234).string(message.purpose);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Transaction {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deprecatedState = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.recipient = Recipient.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.quote = Quote.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.amountSent = Money.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.amountReceived = Money.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.deprecatedSenderId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.lastUpdatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.expiresAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.memo = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.recipientMessage = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.publicState = reader.int32() as any;
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.recipientAccount = RecipientAccount.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          const entry16 = Transaction_FlagsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry16.value !== undefined) {
            message.flags[entry16.key] = entry16.value;
          }
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.eta = ETA.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.collectionEta = ETA.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.fromWallet = Wallet.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.toWallet = Wallet.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.summary = Summary.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.receiptNumber = reader.string();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.reference = reader.string();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.userViewableDetails.push(
            UserViewableDetail.decode(reader, reader.uint32()),
          );
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.paymentSummary = PaymentSummary.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.purpose = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      deprecatedState: isSet(object.deprecatedState)
        ? String(object.deprecatedState)
        : '',
      recipient: isSet(object.recipient)
        ? Recipient.fromJSON(object.recipient)
        : undefined,
      quote: isSet(object.quote) ? Quote.fromJSON(object.quote) : undefined,
      amountSent: isSet(object.amountSent)
        ? Money.fromJSON(object.amountSent)
        : undefined,
      amountReceived: isSet(object.amountReceived)
        ? Money.fromJSON(object.amountReceived)
        : undefined,
      deprecatedSenderId: isSet(object.deprecatedSenderId)
        ? String(object.deprecatedSenderId)
        : '',
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      lastUpdatedAt: isSet(object.lastUpdatedAt)
        ? fromJsonTimestamp(object.lastUpdatedAt)
        : undefined,
      expiresAt: isSet(object.expiresAt)
        ? fromJsonTimestamp(object.expiresAt)
        : undefined,
      memo: isSet(object.memo) ? String(object.memo) : '',
      recipientMessage: isSet(object.recipientMessage)
        ? String(object.recipientMessage)
        : '',
      publicState: isSet(object.publicState)
        ? publicStateFromJSON(object.publicState)
        : 0,
      recipientAccount: isSet(object.recipientAccount)
        ? RecipientAccount.fromJSON(object.recipientAccount)
        : undefined,
      flags: isObject(object.flags)
        ? Object.entries(object.flags).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
      eta: isSet(object.eta) ? ETA.fromJSON(object.eta) : undefined,
      collectionEta: isSet(object.collectionEta)
        ? ETA.fromJSON(object.collectionEta)
        : undefined,
      accountId: isSet(object.accountId) ? String(object.accountId) : '',
      type: isSet(object.type) ? transactionTypeFromJSON(object.type) : 0,
      fromWallet: isSet(object.fromWallet)
        ? Wallet.fromJSON(object.fromWallet)
        : undefined,
      toWallet: isSet(object.toWallet)
        ? Wallet.fromJSON(object.toWallet)
        : undefined,
      summary: isSet(object.summary)
        ? Summary.fromJSON(object.summary)
        : undefined,
      receiptNumber: isSet(object.receiptNumber)
        ? String(object.receiptNumber)
        : '',
      reference: isSet(object.reference) ? String(object.reference) : '',
      userViewableDetails: Array.isArray(object?.userViewableDetails)
        ? object.userViewableDetails.map((e: any) =>
            UserViewableDetail.fromJSON(e),
          )
        : [],
      userId: isSet(object.userId) ? String(object.userId) : '',
      paymentSummary: isSet(object.paymentSummary)
        ? PaymentSummary.fromJSON(object.paymentSummary)
        : undefined,
      purpose: isSet(object.purpose) ? String(object.purpose) : '',
    };
  },

  toJSON(message: Transaction): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.deprecatedState !== undefined &&
      (obj.deprecatedState = message.deprecatedState);
    message.recipient !== undefined &&
      (obj.recipient = message.recipient
        ? Recipient.toJSON(message.recipient)
        : undefined);
    message.quote !== undefined &&
      (obj.quote = message.quote ? Quote.toJSON(message.quote) : undefined);
    message.amountSent !== undefined &&
      (obj.amountSent = message.amountSent
        ? Money.toJSON(message.amountSent)
        : undefined);
    message.amountReceived !== undefined &&
      (obj.amountReceived = message.amountReceived
        ? Money.toJSON(message.amountReceived)
        : undefined);
    message.deprecatedSenderId !== undefined &&
      (obj.deprecatedSenderId = message.deprecatedSenderId);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.lastUpdatedAt !== undefined &&
      (obj.lastUpdatedAt = fromTimestamp(message.lastUpdatedAt).toISOString());
    message.expiresAt !== undefined &&
      (obj.expiresAt = fromTimestamp(message.expiresAt).toISOString());
    message.memo !== undefined && (obj.memo = message.memo);
    message.recipientMessage !== undefined &&
      (obj.recipientMessage = message.recipientMessage);
    message.publicState !== undefined &&
      (obj.publicState = publicStateToJSON(message.publicState));
    message.recipientAccount !== undefined &&
      (obj.recipientAccount = message.recipientAccount
        ? RecipientAccount.toJSON(message.recipientAccount)
        : undefined);
    obj.flags = {};
    if (message.flags) {
      Object.entries(message.flags).forEach(([k, v]) => {
        obj.flags[k] = v;
      });
    }
    message.eta !== undefined &&
      (obj.eta = message.eta ? ETA.toJSON(message.eta) : undefined);
    message.collectionEta !== undefined &&
      (obj.collectionEta = message.collectionEta
        ? ETA.toJSON(message.collectionEta)
        : undefined);
    message.accountId !== undefined && (obj.accountId = message.accountId);
    message.type !== undefined &&
      (obj.type = transactionTypeToJSON(message.type));
    message.fromWallet !== undefined &&
      (obj.fromWallet = message.fromWallet
        ? Wallet.toJSON(message.fromWallet)
        : undefined);
    message.toWallet !== undefined &&
      (obj.toWallet = message.toWallet
        ? Wallet.toJSON(message.toWallet)
        : undefined);
    message.summary !== undefined &&
      (obj.summary = message.summary
        ? Summary.toJSON(message.summary)
        : undefined);
    message.receiptNumber !== undefined &&
      (obj.receiptNumber = message.receiptNumber);
    message.reference !== undefined && (obj.reference = message.reference);
    if (message.userViewableDetails) {
      obj.userViewableDetails = message.userViewableDetails.map((e) =>
        e ? UserViewableDetail.toJSON(e) : undefined,
      );
    } else {
      obj.userViewableDetails = [];
    }
    message.userId !== undefined && (obj.userId = message.userId);
    message.paymentSummary !== undefined &&
      (obj.paymentSummary = message.paymentSummary
        ? PaymentSummary.toJSON(message.paymentSummary)
        : undefined);
    message.purpose !== undefined && (obj.purpose = message.purpose);
    return obj;
  },

  create(base?: DeepPartial<Transaction>): Transaction {
    return Transaction.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Transaction>): Transaction {
    const message = createBaseTransaction();
    message.id = object.id ?? '';
    message.deprecatedState = object.deprecatedState ?? '';
    message.recipient =
      object.recipient !== undefined && object.recipient !== null
        ? Recipient.fromPartial(object.recipient)
        : undefined;
    message.quote =
      object.quote !== undefined && object.quote !== null
        ? Quote.fromPartial(object.quote)
        : undefined;
    message.amountSent =
      object.amountSent !== undefined && object.amountSent !== null
        ? Money.fromPartial(object.amountSent)
        : undefined;
    message.amountReceived =
      object.amountReceived !== undefined && object.amountReceived !== null
        ? Money.fromPartial(object.amountReceived)
        : undefined;
    message.deprecatedSenderId = object.deprecatedSenderId ?? '';
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.lastUpdatedAt =
      object.lastUpdatedAt !== undefined && object.lastUpdatedAt !== null
        ? Timestamp.fromPartial(object.lastUpdatedAt)
        : undefined;
    message.expiresAt =
      object.expiresAt !== undefined && object.expiresAt !== null
        ? Timestamp.fromPartial(object.expiresAt)
        : undefined;
    message.memo = object.memo ?? '';
    message.recipientMessage = object.recipientMessage ?? '';
    message.publicState = object.publicState ?? 0;
    message.recipientAccount =
      object.recipientAccount !== undefined && object.recipientAccount !== null
        ? RecipientAccount.fromPartial(object.recipientAccount)
        : undefined;
    message.flags = Object.entries(object.flags ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.eta =
      object.eta !== undefined && object.eta !== null
        ? ETA.fromPartial(object.eta)
        : undefined;
    message.collectionEta =
      object.collectionEta !== undefined && object.collectionEta !== null
        ? ETA.fromPartial(object.collectionEta)
        : undefined;
    message.accountId = object.accountId ?? '';
    message.type = object.type ?? 0;
    message.fromWallet =
      object.fromWallet !== undefined && object.fromWallet !== null
        ? Wallet.fromPartial(object.fromWallet)
        : undefined;
    message.toWallet =
      object.toWallet !== undefined && object.toWallet !== null
        ? Wallet.fromPartial(object.toWallet)
        : undefined;
    message.summary =
      object.summary !== undefined && object.summary !== null
        ? Summary.fromPartial(object.summary)
        : undefined;
    message.receiptNumber = object.receiptNumber ?? '';
    message.reference = object.reference ?? '';
    message.userViewableDetails =
      object.userViewableDetails?.map((e) =>
        UserViewableDetail.fromPartial(e),
      ) || [];
    message.userId = object.userId ?? '';
    message.paymentSummary =
      object.paymentSummary !== undefined && object.paymentSummary !== null
        ? PaymentSummary.fromPartial(object.paymentSummary)
        : undefined;
    message.purpose = object.purpose ?? '';
    return message;
  },
};

function createBaseTransaction_FlagsEntry(): Transaction_FlagsEntry {
  return { key: '', value: '' };
}

export const Transaction_FlagsEntry = {
  encode(
    message: Transaction_FlagsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Transaction_FlagsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction_FlagsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction_FlagsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: Transaction_FlagsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(base?: DeepPartial<Transaction_FlagsEntry>): Transaction_FlagsEntry {
    return Transaction_FlagsEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<Transaction_FlagsEntry>,
  ): Transaction_FlagsEntry {
    const message = createBaseTransaction_FlagsEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseUserViewableDetail(): UserViewableDetail {
  return { key: '', keyDisplay: '', value: '' };
}

export const UserViewableDetail = {
  encode(
    message: UserViewableDetail,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.keyDisplay !== '') {
      writer.uint32(18).string(message.keyDisplay);
    }
    if (message.value !== '') {
      writer.uint32(26).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserViewableDetail {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserViewableDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.keyDisplay = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserViewableDetail {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      keyDisplay: isSet(object.keyDisplay) ? String(object.keyDisplay) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: UserViewableDetail): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.keyDisplay !== undefined && (obj.keyDisplay = message.keyDisplay);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(base?: DeepPartial<UserViewableDetail>): UserViewableDetail {
    return UserViewableDetail.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<UserViewableDetail>): UserViewableDetail {
    const message = createBaseUserViewableDetail();
    message.key = object.key ?? '';
    message.keyDisplay = object.keyDisplay ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseTransactionUpdate(): TransactionUpdate {
  return { deprecatedState: '', createdAt: undefined, publicState: 0 };
}

export const TransactionUpdate = {
  encode(
    message: TransactionUpdate,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.deprecatedState !== '') {
      writer.uint32(10).string(message.deprecatedState);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(18).fork()).ldelim();
    }
    if (message.publicState !== 0) {
      writer.uint32(24).int32(message.publicState);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionUpdate {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deprecatedState = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.publicState = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransactionUpdate {
    return {
      deprecatedState: isSet(object.deprecatedState)
        ? String(object.deprecatedState)
        : '',
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      publicState: isSet(object.publicState)
        ? publicStateFromJSON(object.publicState)
        : 0,
    };
  },

  toJSON(message: TransactionUpdate): unknown {
    const obj: any = {};
    message.deprecatedState !== undefined &&
      (obj.deprecatedState = message.deprecatedState);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.publicState !== undefined &&
      (obj.publicState = publicStateToJSON(message.publicState));
    return obj;
  },

  create(base?: DeepPartial<TransactionUpdate>): TransactionUpdate {
    return TransactionUpdate.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<TransactionUpdate>): TransactionUpdate {
    const message = createBaseTransactionUpdate();
    message.deprecatedState = object.deprecatedState ?? '';
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.publicState = object.publicState ?? 0;
    return message;
  },
};

function createBaseExternalID(): ExternalID {
  return { providerName: '', providerId: '', customerReference: '' };
}

export const ExternalID = {
  encode(
    message: ExternalID,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.providerName !== '') {
      writer.uint32(10).string(message.providerName);
    }
    if (message.providerId !== '') {
      writer.uint32(18).string(message.providerId);
    }
    if (message.customerReference !== '') {
      writer.uint32(26).string(message.customerReference);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExternalID {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExternalID();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.providerName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.providerId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.customerReference = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExternalID {
    return {
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
      providerId: isSet(object.providerId) ? String(object.providerId) : '',
      customerReference: isSet(object.customerReference)
        ? String(object.customerReference)
        : '',
    };
  },

  toJSON(message: ExternalID): unknown {
    const obj: any = {};
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    message.providerId !== undefined && (obj.providerId = message.providerId);
    message.customerReference !== undefined &&
      (obj.customerReference = message.customerReference);
    return obj;
  },

  create(base?: DeepPartial<ExternalID>): ExternalID {
    return ExternalID.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ExternalID>): ExternalID {
    const message = createBaseExternalID();
    message.providerName = object.providerName ?? '';
    message.providerId = object.providerId ?? '';
    message.customerReference = object.customerReference ?? '';
    return message;
  },
};

function createBaseTransactionReview(): TransactionReview {
  return {
    recipientAccount: undefined,
    clientQuote: undefined,
    isValid: false,
    validationErrors: [],
  };
}

export const TransactionReview = {
  encode(
    message: TransactionReview,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.recipientAccount !== undefined) {
      RecipientAccount.encode(
        message.recipientAccount,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.clientQuote !== undefined) {
      ClientQuote.encode(
        message.clientQuote,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.isValid === true) {
      writer.uint32(24).bool(message.isValid);
    }
    for (const v of message.validationErrors) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionReview {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionReview();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipientAccount = RecipientAccount.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientQuote = ClientQuote.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isValid = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.validationErrors.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransactionReview {
    return {
      recipientAccount: isSet(object.recipientAccount)
        ? RecipientAccount.fromJSON(object.recipientAccount)
        : undefined,
      clientQuote: isSet(object.clientQuote)
        ? ClientQuote.fromJSON(object.clientQuote)
        : undefined,
      isValid: isSet(object.isValid) ? Boolean(object.isValid) : false,
      validationErrors: Array.isArray(object?.validationErrors)
        ? object.validationErrors.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: TransactionReview): unknown {
    const obj: any = {};
    message.recipientAccount !== undefined &&
      (obj.recipientAccount = message.recipientAccount
        ? RecipientAccount.toJSON(message.recipientAccount)
        : undefined);
    message.clientQuote !== undefined &&
      (obj.clientQuote = message.clientQuote
        ? ClientQuote.toJSON(message.clientQuote)
        : undefined);
    message.isValid !== undefined && (obj.isValid = message.isValid);
    if (message.validationErrors) {
      obj.validationErrors = message.validationErrors.map((e) => e);
    } else {
      obj.validationErrors = [];
    }
    return obj;
  },

  create(base?: DeepPartial<TransactionReview>): TransactionReview {
    return TransactionReview.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<TransactionReview>): TransactionReview {
    const message = createBaseTransactionReview();
    message.recipientAccount =
      object.recipientAccount !== undefined && object.recipientAccount !== null
        ? RecipientAccount.fromPartial(object.recipientAccount)
        : undefined;
    message.clientQuote =
      object.clientQuote !== undefined && object.clientQuote !== null
        ? ClientQuote.fromPartial(object.clientQuote)
        : undefined;
    message.isValid = object.isValid ?? false;
    message.validationErrors = object.validationErrors?.map((e) => e) || [];
    return message;
  },
};

function createBaseTransactionsFilter(): TransactionsFilter {
  return {
    recipientCountry: '',
    publicState: 0,
    createdAtTimeRange: undefined,
  };
}

export const TransactionsFilter = {
  encode(
    message: TransactionsFilter,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.recipientCountry !== '') {
      writer.uint32(10).string(message.recipientCountry);
    }
    if (message.publicState !== 0) {
      writer.uint32(16).int32(message.publicState);
    }
    if (message.createdAtTimeRange !== undefined) {
      TimeRange.encode(
        message.createdAtTimeRange,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionsFilter {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionsFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipientCountry = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.publicState = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createdAtTimeRange = TimeRange.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransactionsFilter {
    return {
      recipientCountry: isSet(object.recipientCountry)
        ? String(object.recipientCountry)
        : '',
      publicState: isSet(object.publicState)
        ? publicStateFromJSON(object.publicState)
        : 0,
      createdAtTimeRange: isSet(object.createdAtTimeRange)
        ? TimeRange.fromJSON(object.createdAtTimeRange)
        : undefined,
    };
  },

  toJSON(message: TransactionsFilter): unknown {
    const obj: any = {};
    message.recipientCountry !== undefined &&
      (obj.recipientCountry = message.recipientCountry);
    message.publicState !== undefined &&
      (obj.publicState = publicStateToJSON(message.publicState));
    message.createdAtTimeRange !== undefined &&
      (obj.createdAtTimeRange = message.createdAtTimeRange
        ? TimeRange.toJSON(message.createdAtTimeRange)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<TransactionsFilter>): TransactionsFilter {
    return TransactionsFilter.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<TransactionsFilter>): TransactionsFilter {
    const message = createBaseTransactionsFilter();
    message.recipientCountry = object.recipientCountry ?? '';
    message.publicState = object.publicState ?? 0;
    message.createdAtTimeRange =
      object.createdAtTimeRange !== undefined &&
      object.createdAtTimeRange !== null
        ? TimeRange.fromPartial(object.createdAtTimeRange)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Timestamp {
  if (o instanceof Date) {
    return toTimestamp(o);
  } else if (typeof o === 'string') {
    return toTimestamp(new Date(o));
  } else {
    return Timestamp.fromJSON(o);
  }
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
