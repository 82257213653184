/* eslint-disable */
import { grpc } from '@improbable-eng/grpc-web';
import { BrowserHeaders } from 'browser-headers';
import _m0 from 'protobufjs/minimal';
import { RecipientAccount } from '../../../common/proto/disbursements/types';
import { Money } from '../../../common/proto/fx/types';
import { Name } from '../../../common/proto/kyc/types';
import {
  PublicState,
  publicStateFromJSON,
  publicStateToJSON,
  TransactionType,
  transactionTypeFromJSON,
  transactionTypeToJSON,
} from '../../../common/proto/transactions/types';
import { BannerItem } from '../../../common/proto/users/types';
import { ETA } from '../../../common/proto/utils/types';
import { Wallet } from '../../../common/proto/wallets/types';
import { Timestamp } from '../../../google/protobuf/timestamp';

export const protobufPackage = 'public';

/**
 * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
 * buf:lint:ignore COMMENT_ENUM
 */
export enum TransactionItemAction {
  TRANSACTION_ITEM_ACTION_SENT = 0,
  TRANSACTION_ITEM_ACTION_RECEIVED = 1,
  UNRECOGNIZED = -1,
}

export function transactionItemActionFromJSON(
  object: any,
): TransactionItemAction {
  switch (object) {
    case 0:
    case 'TRANSACTION_ITEM_ACTION_SENT':
      return TransactionItemAction.TRANSACTION_ITEM_ACTION_SENT;
    case 1:
    case 'TRANSACTION_ITEM_ACTION_RECEIVED':
      return TransactionItemAction.TRANSACTION_ITEM_ACTION_RECEIVED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TransactionItemAction.UNRECOGNIZED;
  }
}

export function transactionItemActionToJSON(
  object: TransactionItemAction,
): string {
  switch (object) {
    case TransactionItemAction.TRANSACTION_ITEM_ACTION_SENT:
      return 'TRANSACTION_ITEM_ACTION_SENT';
    case TransactionItemAction.TRANSACTION_ITEM_ACTION_RECEIVED:
      return 'TRANSACTION_ITEM_ACTION_RECEIVED';
    case TransactionItemAction.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
 * buf:lint:ignore COMMENT_ENUM
 */
export enum DeprecatedTransactionState {
  DEPRECATED_TRANSACTION_STATE_WAITING_FOR_CUSTOMER_FUNDS = 0,
  DEPRECATED_TRANSACTION_STATE_CANCELLED = 1,
  DEPRECATED_TRANSACTION_STATE_FAILED = 2,
  DEPRECATED_TRANSACTION_STATE_IN_PROGRESS = 3,
  DEPRECATED_TRANSACTION_STATE_COMPLETED = 4,
  DEPRECATED_TRANSACTION_STATE_WAITING_FOR_PAYMENT = 5,
  UNRECOGNIZED = -1,
}

export function deprecatedTransactionStateFromJSON(
  object: any,
): DeprecatedTransactionState {
  switch (object) {
    case 0:
    case 'DEPRECATED_TRANSACTION_STATE_WAITING_FOR_CUSTOMER_FUNDS':
      return DeprecatedTransactionState.DEPRECATED_TRANSACTION_STATE_WAITING_FOR_CUSTOMER_FUNDS;
    case 1:
    case 'DEPRECATED_TRANSACTION_STATE_CANCELLED':
      return DeprecatedTransactionState.DEPRECATED_TRANSACTION_STATE_CANCELLED;
    case 2:
    case 'DEPRECATED_TRANSACTION_STATE_FAILED':
      return DeprecatedTransactionState.DEPRECATED_TRANSACTION_STATE_FAILED;
    case 3:
    case 'DEPRECATED_TRANSACTION_STATE_IN_PROGRESS':
      return DeprecatedTransactionState.DEPRECATED_TRANSACTION_STATE_IN_PROGRESS;
    case 4:
    case 'DEPRECATED_TRANSACTION_STATE_COMPLETED':
      return DeprecatedTransactionState.DEPRECATED_TRANSACTION_STATE_COMPLETED;
    case 5:
    case 'DEPRECATED_TRANSACTION_STATE_WAITING_FOR_PAYMENT':
      return DeprecatedTransactionState.DEPRECATED_TRANSACTION_STATE_WAITING_FOR_PAYMENT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DeprecatedTransactionState.UNRECOGNIZED;
  }
}

export function deprecatedTransactionStateToJSON(
  object: DeprecatedTransactionState,
): string {
  switch (object) {
    case DeprecatedTransactionState.DEPRECATED_TRANSACTION_STATE_WAITING_FOR_CUSTOMER_FUNDS:
      return 'DEPRECATED_TRANSACTION_STATE_WAITING_FOR_CUSTOMER_FUNDS';
    case DeprecatedTransactionState.DEPRECATED_TRANSACTION_STATE_CANCELLED:
      return 'DEPRECATED_TRANSACTION_STATE_CANCELLED';
    case DeprecatedTransactionState.DEPRECATED_TRANSACTION_STATE_FAILED:
      return 'DEPRECATED_TRANSACTION_STATE_FAILED';
    case DeprecatedTransactionState.DEPRECATED_TRANSACTION_STATE_IN_PROGRESS:
      return 'DEPRECATED_TRANSACTION_STATE_IN_PROGRESS';
    case DeprecatedTransactionState.DEPRECATED_TRANSACTION_STATE_COMPLETED:
      return 'DEPRECATED_TRANSACTION_STATE_COMPLETED';
    case DeprecatedTransactionState.DEPRECATED_TRANSACTION_STATE_WAITING_FOR_PAYMENT:
      return 'DEPRECATED_TRANSACTION_STATE_WAITING_FOR_PAYMENT';
    case DeprecatedTransactionState.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
 * buf:lint:ignore COMMENT_ENUM
 */
export enum DeprecatedFeedItemType {
  DEPRECATED_FEED_ITEM_TYPE_TRANSACTION = 0,
  UNRECOGNIZED = -1,
}

export function deprecatedFeedItemTypeFromJSON(
  object: any,
): DeprecatedFeedItemType {
  switch (object) {
    case 0:
    case 'DEPRECATED_FEED_ITEM_TYPE_TRANSACTION':
      return DeprecatedFeedItemType.DEPRECATED_FEED_ITEM_TYPE_TRANSACTION;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DeprecatedFeedItemType.UNRECOGNIZED;
  }
}

export function deprecatedFeedItemTypeToJSON(
  object: DeprecatedFeedItemType,
): string {
  switch (object) {
    case DeprecatedFeedItemType.DEPRECATED_FEED_ITEM_TYPE_TRANSACTION:
      return 'DEPRECATED_FEED_ITEM_TYPE_TRANSACTION';
    case DeprecatedFeedItemType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum FeedItemType {
  FEED_ITEM_TYPE_INVALID = 0,
  FEED_ITEM_TYPE_TRANSACTION = 1,
  FEED_ITEM_TYPE_BANNER_ITEM = 2,
  UNRECOGNIZED = -1,
}

export function feedItemTypeFromJSON(object: any): FeedItemType {
  switch (object) {
    case 0:
    case 'FEED_ITEM_TYPE_INVALID':
      return FeedItemType.FEED_ITEM_TYPE_INVALID;
    case 1:
    case 'FEED_ITEM_TYPE_TRANSACTION':
      return FeedItemType.FEED_ITEM_TYPE_TRANSACTION;
    case 2:
    case 'FEED_ITEM_TYPE_BANNER_ITEM':
      return FeedItemType.FEED_ITEM_TYPE_BANNER_ITEM;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FeedItemType.UNRECOGNIZED;
  }
}

export function feedItemTypeToJSON(object: FeedItemType): string {
  switch (object) {
    case FeedItemType.FEED_ITEM_TYPE_INVALID:
      return 'FEED_ITEM_TYPE_INVALID';
    case FeedItemType.FEED_ITEM_TYPE_TRANSACTION:
      return 'FEED_ITEM_TYPE_TRANSACTION';
    case FeedItemType.FEED_ITEM_TYPE_BANNER_ITEM:
      return 'FEED_ITEM_TYPE_BANNER_ITEM';
    case FeedItemType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface TransactionItem {
  action: TransactionItemAction;
  transactionId: string;
  contactNumber: string;
  contactName: Name | undefined;
  title: string;
  /** @deprecated */
  deprecatedState: DeprecatedTransactionState;
  sent: Money | undefined;
  received: Money | undefined;
  createdAt: Timestamp | undefined;
  lastUpdatedAt: Timestamp | undefined;
  expiresAt: Timestamp | undefined;
  state: PublicState;
  recipientAccount: RecipientAccount | undefined;
  flags: { [key: string]: string };
  eta: ETA | undefined;
  collectionEta: ETA | undefined;
  type: TransactionType;
  fromWallet: Wallet | undefined;
  toWallet: Wallet | undefined;
}

export interface TransactionItem_FlagsEntry {
  key: string;
  value: string;
}

export interface FeedItem {
  /** @deprecated */
  deprecatedType: DeprecatedFeedItemType;
  type: FeedItemType;
  transaction: TransactionItem | undefined;
  bannerItem: BannerItem | undefined;
  createdAt: Timestamp | undefined;
}

export interface ListItemsRequest {
  beforeToken: string;
  count: number;
  searchTerm: string;
  walletId: string;
}

export interface ListItemsReply {
  items: FeedItem[];
  lastToken: string;
  searchTerm: string;
  walletId: string;
}

function createBaseTransactionItem(): TransactionItem {
  return {
    action: 0,
    transactionId: '',
    contactNumber: '',
    contactName: undefined,
    title: '',
    deprecatedState: 0,
    sent: undefined,
    received: undefined,
    createdAt: undefined,
    lastUpdatedAt: undefined,
    expiresAt: undefined,
    state: 0,
    recipientAccount: undefined,
    flags: {},
    eta: undefined,
    collectionEta: undefined,
    type: 0,
    fromWallet: undefined,
    toWallet: undefined,
  };
}

export const TransactionItem = {
  encode(
    message: TransactionItem,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.action !== 0) {
      writer.uint32(8).int32(message.action);
    }
    if (message.transactionId !== '') {
      writer.uint32(18).string(message.transactionId);
    }
    if (message.contactNumber !== '') {
      writer.uint32(26).string(message.contactNumber);
    }
    if (message.contactName !== undefined) {
      Name.encode(message.contactName, writer.uint32(34).fork()).ldelim();
    }
    if (message.title !== '') {
      writer.uint32(42).string(message.title);
    }
    if (message.deprecatedState !== 0) {
      writer.uint32(48).int32(message.deprecatedState);
    }
    if (message.sent !== undefined) {
      Money.encode(message.sent, writer.uint32(58).fork()).ldelim();
    }
    if (message.received !== undefined) {
      Money.encode(message.received, writer.uint32(66).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(74).fork()).ldelim();
    }
    if (message.lastUpdatedAt !== undefined) {
      Timestamp.encode(
        message.lastUpdatedAt,
        writer.uint32(82).fork(),
      ).ldelim();
    }
    if (message.expiresAt !== undefined) {
      Timestamp.encode(message.expiresAt, writer.uint32(90).fork()).ldelim();
    }
    if (message.state !== 0) {
      writer.uint32(96).int32(message.state);
    }
    if (message.recipientAccount !== undefined) {
      RecipientAccount.encode(
        message.recipientAccount,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    Object.entries(message.flags).forEach(([key, value]) => {
      TransactionItem_FlagsEntry.encode(
        { key: key as any, value },
        writer.uint32(114).fork(),
      ).ldelim();
    });
    if (message.eta !== undefined) {
      ETA.encode(message.eta, writer.uint32(122).fork()).ldelim();
    }
    if (message.collectionEta !== undefined) {
      ETA.encode(message.collectionEta, writer.uint32(130).fork()).ldelim();
    }
    if (message.type !== 0) {
      writer.uint32(136).int32(message.type);
    }
    if (message.fromWallet !== undefined) {
      Wallet.encode(message.fromWallet, writer.uint32(146).fork()).ldelim();
    }
    if (message.toWallet !== undefined) {
      Wallet.encode(message.toWallet, writer.uint32(154).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionItem {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.action = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.contactNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.contactName = Name.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.title = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.deprecatedState = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.sent = Money.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.received = Money.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.lastUpdatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.expiresAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.recipientAccount = RecipientAccount.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          const entry14 = TransactionItem_FlagsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry14.value !== undefined) {
            message.flags[entry14.key] = entry14.value;
          }
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.eta = ETA.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.collectionEta = ETA.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.fromWallet = Wallet.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.toWallet = Wallet.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransactionItem {
    return {
      action: isSet(object.action)
        ? transactionItemActionFromJSON(object.action)
        : 0,
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
      contactNumber: isSet(object.contactNumber)
        ? String(object.contactNumber)
        : '',
      contactName: isSet(object.contactName)
        ? Name.fromJSON(object.contactName)
        : undefined,
      title: isSet(object.title) ? String(object.title) : '',
      deprecatedState: isSet(object.deprecatedState)
        ? deprecatedTransactionStateFromJSON(object.deprecatedState)
        : 0,
      sent: isSet(object.sent) ? Money.fromJSON(object.sent) : undefined,
      received: isSet(object.received)
        ? Money.fromJSON(object.received)
        : undefined,
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      lastUpdatedAt: isSet(object.lastUpdatedAt)
        ? fromJsonTimestamp(object.lastUpdatedAt)
        : undefined,
      expiresAt: isSet(object.expiresAt)
        ? fromJsonTimestamp(object.expiresAt)
        : undefined,
      state: isSet(object.state) ? publicStateFromJSON(object.state) : 0,
      recipientAccount: isSet(object.recipientAccount)
        ? RecipientAccount.fromJSON(object.recipientAccount)
        : undefined,
      flags: isObject(object.flags)
        ? Object.entries(object.flags).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
      eta: isSet(object.eta) ? ETA.fromJSON(object.eta) : undefined,
      collectionEta: isSet(object.collectionEta)
        ? ETA.fromJSON(object.collectionEta)
        : undefined,
      type: isSet(object.type) ? transactionTypeFromJSON(object.type) : 0,
      fromWallet: isSet(object.fromWallet)
        ? Wallet.fromJSON(object.fromWallet)
        : undefined,
      toWallet: isSet(object.toWallet)
        ? Wallet.fromJSON(object.toWallet)
        : undefined,
    };
  },

  toJSON(message: TransactionItem): unknown {
    const obj: any = {};
    message.action !== undefined &&
      (obj.action = transactionItemActionToJSON(message.action));
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    message.contactNumber !== undefined &&
      (obj.contactNumber = message.contactNumber);
    message.contactName !== undefined &&
      (obj.contactName = message.contactName
        ? Name.toJSON(message.contactName)
        : undefined);
    message.title !== undefined && (obj.title = message.title);
    message.deprecatedState !== undefined &&
      (obj.deprecatedState = deprecatedTransactionStateToJSON(
        message.deprecatedState,
      ));
    message.sent !== undefined &&
      (obj.sent = message.sent ? Money.toJSON(message.sent) : undefined);
    message.received !== undefined &&
      (obj.received = message.received
        ? Money.toJSON(message.received)
        : undefined);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.lastUpdatedAt !== undefined &&
      (obj.lastUpdatedAt = fromTimestamp(message.lastUpdatedAt).toISOString());
    message.expiresAt !== undefined &&
      (obj.expiresAt = fromTimestamp(message.expiresAt).toISOString());
    message.state !== undefined &&
      (obj.state = publicStateToJSON(message.state));
    message.recipientAccount !== undefined &&
      (obj.recipientAccount = message.recipientAccount
        ? RecipientAccount.toJSON(message.recipientAccount)
        : undefined);
    obj.flags = {};
    if (message.flags) {
      Object.entries(message.flags).forEach(([k, v]) => {
        obj.flags[k] = v;
      });
    }
    message.eta !== undefined &&
      (obj.eta = message.eta ? ETA.toJSON(message.eta) : undefined);
    message.collectionEta !== undefined &&
      (obj.collectionEta = message.collectionEta
        ? ETA.toJSON(message.collectionEta)
        : undefined);
    message.type !== undefined &&
      (obj.type = transactionTypeToJSON(message.type));
    message.fromWallet !== undefined &&
      (obj.fromWallet = message.fromWallet
        ? Wallet.toJSON(message.fromWallet)
        : undefined);
    message.toWallet !== undefined &&
      (obj.toWallet = message.toWallet
        ? Wallet.toJSON(message.toWallet)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<TransactionItem>): TransactionItem {
    return TransactionItem.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<TransactionItem>): TransactionItem {
    const message = createBaseTransactionItem();
    message.action = object.action ?? 0;
    message.transactionId = object.transactionId ?? '';
    message.contactNumber = object.contactNumber ?? '';
    message.contactName =
      object.contactName !== undefined && object.contactName !== null
        ? Name.fromPartial(object.contactName)
        : undefined;
    message.title = object.title ?? '';
    message.deprecatedState = object.deprecatedState ?? 0;
    message.sent =
      object.sent !== undefined && object.sent !== null
        ? Money.fromPartial(object.sent)
        : undefined;
    message.received =
      object.received !== undefined && object.received !== null
        ? Money.fromPartial(object.received)
        : undefined;
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.lastUpdatedAt =
      object.lastUpdatedAt !== undefined && object.lastUpdatedAt !== null
        ? Timestamp.fromPartial(object.lastUpdatedAt)
        : undefined;
    message.expiresAt =
      object.expiresAt !== undefined && object.expiresAt !== null
        ? Timestamp.fromPartial(object.expiresAt)
        : undefined;
    message.state = object.state ?? 0;
    message.recipientAccount =
      object.recipientAccount !== undefined && object.recipientAccount !== null
        ? RecipientAccount.fromPartial(object.recipientAccount)
        : undefined;
    message.flags = Object.entries(object.flags ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.eta =
      object.eta !== undefined && object.eta !== null
        ? ETA.fromPartial(object.eta)
        : undefined;
    message.collectionEta =
      object.collectionEta !== undefined && object.collectionEta !== null
        ? ETA.fromPartial(object.collectionEta)
        : undefined;
    message.type = object.type ?? 0;
    message.fromWallet =
      object.fromWallet !== undefined && object.fromWallet !== null
        ? Wallet.fromPartial(object.fromWallet)
        : undefined;
    message.toWallet =
      object.toWallet !== undefined && object.toWallet !== null
        ? Wallet.fromPartial(object.toWallet)
        : undefined;
    return message;
  },
};

function createBaseTransactionItem_FlagsEntry(): TransactionItem_FlagsEntry {
  return { key: '', value: '' };
}

export const TransactionItem_FlagsEntry = {
  encode(
    message: TransactionItem_FlagsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): TransactionItem_FlagsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionItem_FlagsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransactionItem_FlagsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: TransactionItem_FlagsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<TransactionItem_FlagsEntry>,
  ): TransactionItem_FlagsEntry {
    return TransactionItem_FlagsEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<TransactionItem_FlagsEntry>,
  ): TransactionItem_FlagsEntry {
    const message = createBaseTransactionItem_FlagsEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseFeedItem(): FeedItem {
  return {
    deprecatedType: 0,
    type: 0,
    transaction: undefined,
    bannerItem: undefined,
    createdAt: undefined,
  };
}

export const FeedItem = {
  encode(
    message: FeedItem,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.deprecatedType !== 0) {
      writer.uint32(8).int32(message.deprecatedType);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.transaction !== undefined) {
      TransactionItem.encode(
        message.transaction,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.bannerItem !== undefined) {
      BannerItem.encode(message.bannerItem, writer.uint32(34).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FeedItem {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeedItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deprecatedType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transaction = TransactionItem.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.bannerItem = BannerItem.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FeedItem {
    return {
      deprecatedType: isSet(object.deprecatedType)
        ? deprecatedFeedItemTypeFromJSON(object.deprecatedType)
        : 0,
      type: isSet(object.type) ? feedItemTypeFromJSON(object.type) : 0,
      transaction: isSet(object.transaction)
        ? TransactionItem.fromJSON(object.transaction)
        : undefined,
      bannerItem: isSet(object.bannerItem)
        ? BannerItem.fromJSON(object.bannerItem)
        : undefined,
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
    };
  },

  toJSON(message: FeedItem): unknown {
    const obj: any = {};
    message.deprecatedType !== undefined &&
      (obj.deprecatedType = deprecatedFeedItemTypeToJSON(
        message.deprecatedType,
      ));
    message.type !== undefined && (obj.type = feedItemTypeToJSON(message.type));
    message.transaction !== undefined &&
      (obj.transaction = message.transaction
        ? TransactionItem.toJSON(message.transaction)
        : undefined);
    message.bannerItem !== undefined &&
      (obj.bannerItem = message.bannerItem
        ? BannerItem.toJSON(message.bannerItem)
        : undefined);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    return obj;
  },

  create(base?: DeepPartial<FeedItem>): FeedItem {
    return FeedItem.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<FeedItem>): FeedItem {
    const message = createBaseFeedItem();
    message.deprecatedType = object.deprecatedType ?? 0;
    message.type = object.type ?? 0;
    message.transaction =
      object.transaction !== undefined && object.transaction !== null
        ? TransactionItem.fromPartial(object.transaction)
        : undefined;
    message.bannerItem =
      object.bannerItem !== undefined && object.bannerItem !== null
        ? BannerItem.fromPartial(object.bannerItem)
        : undefined;
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    return message;
  },
};

function createBaseListItemsRequest(): ListItemsRequest {
  return { beforeToken: '', count: 0, searchTerm: '', walletId: '' };
}

export const ListItemsRequest = {
  encode(
    message: ListItemsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.beforeToken !== '') {
      writer.uint32(10).string(message.beforeToken);
    }
    if (message.count !== 0) {
      writer.uint32(16).int32(message.count);
    }
    if (message.searchTerm !== '') {
      writer.uint32(26).string(message.searchTerm);
    }
    if (message.walletId !== '') {
      writer.uint32(34).string(message.walletId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListItemsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListItemsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.beforeToken = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.count = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.searchTerm = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.walletId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListItemsRequest {
    return {
      beforeToken: isSet(object.beforeToken) ? String(object.beforeToken) : '',
      count: isSet(object.count) ? Number(object.count) : 0,
      searchTerm: isSet(object.searchTerm) ? String(object.searchTerm) : '',
      walletId: isSet(object.walletId) ? String(object.walletId) : '',
    };
  },

  toJSON(message: ListItemsRequest): unknown {
    const obj: any = {};
    message.beforeToken !== undefined &&
      (obj.beforeToken = message.beforeToken);
    message.count !== undefined && (obj.count = Math.round(message.count));
    message.searchTerm !== undefined && (obj.searchTerm = message.searchTerm);
    message.walletId !== undefined && (obj.walletId = message.walletId);
    return obj;
  },

  create(base?: DeepPartial<ListItemsRequest>): ListItemsRequest {
    return ListItemsRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ListItemsRequest>): ListItemsRequest {
    const message = createBaseListItemsRequest();
    message.beforeToken = object.beforeToken ?? '';
    message.count = object.count ?? 0;
    message.searchTerm = object.searchTerm ?? '';
    message.walletId = object.walletId ?? '';
    return message;
  },
};

function createBaseListItemsReply(): ListItemsReply {
  return { items: [], lastToken: '', searchTerm: '', walletId: '' };
}

export const ListItemsReply = {
  encode(
    message: ListItemsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.items) {
      FeedItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.lastToken !== '') {
      writer.uint32(18).string(message.lastToken);
    }
    if (message.searchTerm !== '') {
      writer.uint32(26).string(message.searchTerm);
    }
    if (message.walletId !== '') {
      writer.uint32(34).string(message.walletId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListItemsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListItemsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(FeedItem.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lastToken = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.searchTerm = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.walletId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListItemsReply {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => FeedItem.fromJSON(e))
        : [],
      lastToken: isSet(object.lastToken) ? String(object.lastToken) : '',
      searchTerm: isSet(object.searchTerm) ? String(object.searchTerm) : '',
      walletId: isSet(object.walletId) ? String(object.walletId) : '',
    };
  },

  toJSON(message: ListItemsReply): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) =>
        e ? FeedItem.toJSON(e) : undefined,
      );
    } else {
      obj.items = [];
    }
    message.lastToken !== undefined && (obj.lastToken = message.lastToken);
    message.searchTerm !== undefined && (obj.searchTerm = message.searchTerm);
    message.walletId !== undefined && (obj.walletId = message.walletId);
    return obj;
  },

  create(base?: DeepPartial<ListItemsReply>): ListItemsReply {
    return ListItemsReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ListItemsReply>): ListItemsReply {
    const message = createBaseListItemsReply();
    message.items = object.items?.map((e) => FeedItem.fromPartial(e)) || [];
    message.lastToken = object.lastToken ?? '';
    message.searchTerm = object.searchTerm ?? '';
    message.walletId = object.walletId ?? '';
    return message;
  },
};

export interface Feed {
  /** buf:lint:ignore COMMENT_RPC */
  ListItems(
    request: DeepPartial<ListItemsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListItemsReply>;
}

export class FeedClientImpl implements Feed {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListItems = this.ListItems.bind(this);
  }

  ListItems(
    request: DeepPartial<ListItemsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListItemsReply> {
    return this.rpc.unary(
      FeedListItemsDesc,
      ListItemsRequest.fromPartial(request),
      metadata,
    );
  }
}

export const FeedDesc = { serviceName: 'public.Feed' };

export const FeedListItemsDesc: UnaryMethodDefinitionish = {
  methodName: 'ListItems',
  service: FeedDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListItemsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListItemsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(
              response.statusMessage,
              response.status,
              response.trailers,
            );
            reject(err);
          }
        },
      });
    });
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Timestamp {
  if (o instanceof Date) {
    return toTimestamp(o);
  } else if (typeof o === 'string') {
    return toTimestamp(new Date(o));
  } else {
    return Timestamp.fromJSON(o);
  }
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(
    message: string,
    public code: grpc.Code,
    public metadata: grpc.Metadata,
  ) {
    super(message);
  }
}
