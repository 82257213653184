/* eslint-disable */
import { grpc } from '@improbable-eng/grpc-web';
import { BrowserHeaders } from 'browser-headers';
import _m0 from 'protobufjs/minimal';
import { Name } from '../../../common/proto/kyc/types';
import {
  AuthProvider,
  authProviderFromJSON,
  authProviderToJSON,
  User,
} from '../../../common/proto/users/types';

export const protobufPackage = 'anonymous';

/** start password reset */
export interface StartPasswordResetRequest {
  provider: AuthProvider;
  email: string;
}

export interface StartPasswordResetReply {}

/** signup with email and password */
export interface SignupWithEmailAndPasswordRequest {
  userId: string;
  provider: AuthProvider;
  email: string;
  password: string;
}

export interface SignupWithEmailAndPasswordReply {
  user: User | undefined;
  name: Name | undefined;
}

export interface LinkExternalAuthRequest {
  linkId: string;
  code: string;
}

export interface LinkExternalAuthReply {}

export interface StartExternalAuthLinkingRequest {
  phoneNumber: string;
}

export interface StartExternalAuthLinkingReply {
  linkId: string;
}

function createBaseStartPasswordResetRequest(): StartPasswordResetRequest {
  return { provider: 0, email: '' };
}

export const StartPasswordResetRequest = {
  encode(
    message: StartPasswordResetRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.provider !== 0) {
      writer.uint32(8).int32(message.provider);
    }
    if (message.email !== '') {
      writer.uint32(18).string(message.email);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartPasswordResetRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartPasswordResetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.provider = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartPasswordResetRequest {
    return {
      provider: isSet(object.provider)
        ? authProviderFromJSON(object.provider)
        : 0,
      email: isSet(object.email) ? String(object.email) : '',
    };
  },

  toJSON(message: StartPasswordResetRequest): unknown {
    const obj: any = {};
    message.provider !== undefined &&
      (obj.provider = authProviderToJSON(message.provider));
    message.email !== undefined && (obj.email = message.email);
    return obj;
  },

  create(
    base?: DeepPartial<StartPasswordResetRequest>,
  ): StartPasswordResetRequest {
    return StartPasswordResetRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<StartPasswordResetRequest>,
  ): StartPasswordResetRequest {
    const message = createBaseStartPasswordResetRequest();
    message.provider = object.provider ?? 0;
    message.email = object.email ?? '';
    return message;
  },
};

function createBaseStartPasswordResetReply(): StartPasswordResetReply {
  return {};
}

export const StartPasswordResetReply = {
  encode(
    _: StartPasswordResetReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartPasswordResetReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartPasswordResetReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StartPasswordResetReply {
    return {};
  },

  toJSON(_: StartPasswordResetReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<StartPasswordResetReply>): StartPasswordResetReply {
    return StartPasswordResetReply.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<StartPasswordResetReply>,
  ): StartPasswordResetReply {
    const message = createBaseStartPasswordResetReply();
    return message;
  },
};

function createBaseSignupWithEmailAndPasswordRequest(): SignupWithEmailAndPasswordRequest {
  return { userId: '', provider: 0, email: '', password: '' };
}

export const SignupWithEmailAndPasswordRequest = {
  encode(
    message: SignupWithEmailAndPasswordRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.userId !== '') {
      writer.uint32(10).string(message.userId);
    }
    if (message.provider !== 0) {
      writer.uint32(16).int32(message.provider);
    }
    if (message.email !== '') {
      writer.uint32(26).string(message.email);
    }
    if (message.password !== '') {
      writer.uint32(34).string(message.password);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SignupWithEmailAndPasswordRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignupWithEmailAndPasswordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.provider = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignupWithEmailAndPasswordRequest {
    return {
      userId: isSet(object.userId) ? String(object.userId) : '',
      provider: isSet(object.provider)
        ? authProviderFromJSON(object.provider)
        : 0,
      email: isSet(object.email) ? String(object.email) : '',
      password: isSet(object.password) ? String(object.password) : '',
    };
  },

  toJSON(message: SignupWithEmailAndPasswordRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.provider !== undefined &&
      (obj.provider = authProviderToJSON(message.provider));
    message.email !== undefined && (obj.email = message.email);
    message.password !== undefined && (obj.password = message.password);
    return obj;
  },

  create(
    base?: DeepPartial<SignupWithEmailAndPasswordRequest>,
  ): SignupWithEmailAndPasswordRequest {
    return SignupWithEmailAndPasswordRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<SignupWithEmailAndPasswordRequest>,
  ): SignupWithEmailAndPasswordRequest {
    const message = createBaseSignupWithEmailAndPasswordRequest();
    message.userId = object.userId ?? '';
    message.provider = object.provider ?? 0;
    message.email = object.email ?? '';
    message.password = object.password ?? '';
    return message;
  },
};

function createBaseSignupWithEmailAndPasswordReply(): SignupWithEmailAndPasswordReply {
  return { user: undefined, name: undefined };
}

export const SignupWithEmailAndPasswordReply = {
  encode(
    message: SignupWithEmailAndPasswordReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      Name.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SignupWithEmailAndPasswordReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignupWithEmailAndPasswordReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Name.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignupWithEmailAndPasswordReply {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      name: isSet(object.name) ? Name.fromJSON(object.name) : undefined,
    };
  },

  toJSON(message: SignupWithEmailAndPasswordReply): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    message.name !== undefined &&
      (obj.name = message.name ? Name.toJSON(message.name) : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<SignupWithEmailAndPasswordReply>,
  ): SignupWithEmailAndPasswordReply {
    return SignupWithEmailAndPasswordReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<SignupWithEmailAndPasswordReply>,
  ): SignupWithEmailAndPasswordReply {
    const message = createBaseSignupWithEmailAndPasswordReply();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    message.name =
      object.name !== undefined && object.name !== null
        ? Name.fromPartial(object.name)
        : undefined;
    return message;
  },
};

function createBaseLinkExternalAuthRequest(): LinkExternalAuthRequest {
  return { linkId: '', code: '' };
}

export const LinkExternalAuthRequest = {
  encode(
    message: LinkExternalAuthRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.linkId !== '') {
      writer.uint32(10).string(message.linkId);
    }
    if (message.code !== '') {
      writer.uint32(18).string(message.code);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): LinkExternalAuthRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLinkExternalAuthRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LinkExternalAuthRequest {
    return {
      linkId: isSet(object.linkId) ? String(object.linkId) : '',
      code: isSet(object.code) ? String(object.code) : '',
    };
  },

  toJSON(message: LinkExternalAuthRequest): unknown {
    const obj: any = {};
    message.linkId !== undefined && (obj.linkId = message.linkId);
    message.code !== undefined && (obj.code = message.code);
    return obj;
  },

  create(base?: DeepPartial<LinkExternalAuthRequest>): LinkExternalAuthRequest {
    return LinkExternalAuthRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<LinkExternalAuthRequest>,
  ): LinkExternalAuthRequest {
    const message = createBaseLinkExternalAuthRequest();
    message.linkId = object.linkId ?? '';
    message.code = object.code ?? '';
    return message;
  },
};

function createBaseLinkExternalAuthReply(): LinkExternalAuthReply {
  return {};
}

export const LinkExternalAuthReply = {
  encode(
    _: LinkExternalAuthReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): LinkExternalAuthReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLinkExternalAuthReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): LinkExternalAuthReply {
    return {};
  },

  toJSON(_: LinkExternalAuthReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<LinkExternalAuthReply>): LinkExternalAuthReply {
    return LinkExternalAuthReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<LinkExternalAuthReply>): LinkExternalAuthReply {
    const message = createBaseLinkExternalAuthReply();
    return message;
  },
};

function createBaseStartExternalAuthLinkingRequest(): StartExternalAuthLinkingRequest {
  return { phoneNumber: '' };
}

export const StartExternalAuthLinkingRequest = {
  encode(
    message: StartExternalAuthLinkingRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.phoneNumber !== '') {
      writer.uint32(10).string(message.phoneNumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartExternalAuthLinkingRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartExternalAuthLinkingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartExternalAuthLinkingRequest {
    return {
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : '',
    };
  },

  toJSON(message: StartExternalAuthLinkingRequest): unknown {
    const obj: any = {};
    message.phoneNumber !== undefined &&
      (obj.phoneNumber = message.phoneNumber);
    return obj;
  },

  create(
    base?: DeepPartial<StartExternalAuthLinkingRequest>,
  ): StartExternalAuthLinkingRequest {
    return StartExternalAuthLinkingRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<StartExternalAuthLinkingRequest>,
  ): StartExternalAuthLinkingRequest {
    const message = createBaseStartExternalAuthLinkingRequest();
    message.phoneNumber = object.phoneNumber ?? '';
    return message;
  },
};

function createBaseStartExternalAuthLinkingReply(): StartExternalAuthLinkingReply {
  return { linkId: '' };
}

export const StartExternalAuthLinkingReply = {
  encode(
    message: StartExternalAuthLinkingReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.linkId !== '') {
      writer.uint32(10).string(message.linkId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartExternalAuthLinkingReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartExternalAuthLinkingReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartExternalAuthLinkingReply {
    return { linkId: isSet(object.linkId) ? String(object.linkId) : '' };
  },

  toJSON(message: StartExternalAuthLinkingReply): unknown {
    const obj: any = {};
    message.linkId !== undefined && (obj.linkId = message.linkId);
    return obj;
  },

  create(
    base?: DeepPartial<StartExternalAuthLinkingReply>,
  ): StartExternalAuthLinkingReply {
    return StartExternalAuthLinkingReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<StartExternalAuthLinkingReply>,
  ): StartExternalAuthLinkingReply {
    const message = createBaseStartExternalAuthLinkingReply();
    message.linkId = object.linkId ?? '';
    return message;
  },
};

export interface AnonymousUsers {
  /** buf:lint:ignore COMMENT_RPC */
  StartPasswordReset(
    request: DeepPartial<StartPasswordResetRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartPasswordResetReply>;
  /** buf:lint:ignore COMMENT_RPC */
  SignupWithEmailAndPassword(
    request: DeepPartial<SignupWithEmailAndPasswordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SignupWithEmailAndPasswordReply>;
  /**
   * Link external auth provider to an existing user
   * Need to provide the link_id and the code that was sent to the user by SMS
   */
  LinkExternalAuth(
    request: DeepPartial<LinkExternalAuthRequest>,
    metadata?: grpc.Metadata,
  ): Promise<LinkExternalAuthReply>;
  /**
   * Start external auth linking process
   * If we can find an existing user with the same phone number, we will send a code to the user by SMS
   * that can then be used to link the external auth provider to the existing user
   */
  StartExternalAuthLinking(
    request: DeepPartial<StartExternalAuthLinkingRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartExternalAuthLinkingReply>;
}

export class AnonymousUsersClientImpl implements AnonymousUsers {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.StartPasswordReset = this.StartPasswordReset.bind(this);
    this.SignupWithEmailAndPassword =
      this.SignupWithEmailAndPassword.bind(this);
    this.LinkExternalAuth = this.LinkExternalAuth.bind(this);
    this.StartExternalAuthLinking = this.StartExternalAuthLinking.bind(this);
  }

  StartPasswordReset(
    request: DeepPartial<StartPasswordResetRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartPasswordResetReply> {
    return this.rpc.unary(
      AnonymousUsersStartPasswordResetDesc,
      StartPasswordResetRequest.fromPartial(request),
      metadata,
    );
  }

  SignupWithEmailAndPassword(
    request: DeepPartial<SignupWithEmailAndPasswordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SignupWithEmailAndPasswordReply> {
    return this.rpc.unary(
      AnonymousUsersSignupWithEmailAndPasswordDesc,
      SignupWithEmailAndPasswordRequest.fromPartial(request),
      metadata,
    );
  }

  LinkExternalAuth(
    request: DeepPartial<LinkExternalAuthRequest>,
    metadata?: grpc.Metadata,
  ): Promise<LinkExternalAuthReply> {
    return this.rpc.unary(
      AnonymousUsersLinkExternalAuthDesc,
      LinkExternalAuthRequest.fromPartial(request),
      metadata,
    );
  }

  StartExternalAuthLinking(
    request: DeepPartial<StartExternalAuthLinkingRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartExternalAuthLinkingReply> {
    return this.rpc.unary(
      AnonymousUsersStartExternalAuthLinkingDesc,
      StartExternalAuthLinkingRequest.fromPartial(request),
      metadata,
    );
  }
}

export const AnonymousUsersDesc = { serviceName: 'anonymous.AnonymousUsers' };

export const AnonymousUsersStartPasswordResetDesc: UnaryMethodDefinitionish = {
  methodName: 'StartPasswordReset',
  service: AnonymousUsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StartPasswordResetRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StartPasswordResetReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AnonymousUsersSignupWithEmailAndPasswordDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SignupWithEmailAndPassword',
    service: AnonymousUsersDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SignupWithEmailAndPasswordRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = SignupWithEmailAndPasswordReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const AnonymousUsersLinkExternalAuthDesc: UnaryMethodDefinitionish = {
  methodName: 'LinkExternalAuth',
  service: AnonymousUsersDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LinkExternalAuthRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = LinkExternalAuthReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AnonymousUsersStartExternalAuthLinkingDesc: UnaryMethodDefinitionish =
  {
    methodName: 'StartExternalAuthLinking',
    service: AnonymousUsersDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return StartExternalAuthLinkingRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = StartExternalAuthLinkingReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(
              response.statusMessage,
              response.status,
              response.trailers,
            );
            reject(err);
          }
        },
      });
    });
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(
    message: string,
    public code: grpc.Code,
    public metadata: grpc.Metadata,
  ) {
    super(message);
  }
}
