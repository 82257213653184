import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Persistor } from 'redux-persist';

import AuthRedirect from '../lib/authRedirect';
import { rebootIntercom } from '../lib/intercom';
import FullscreenLoading from './FullscreenLoading';

/**
 * This is *genuinely* the recommended way of passing auth0 hooks outside of components.
 * https://gist.github.com/adamjmcgrath/0ed6a04047aad16506ca24d85f1b2a5c
 */

type AuthProviderProps = {
  persistor: Persistor;
  children: JSX.Element;
};

export enum AuthServiceProvider {
  Google = 'google-oauth2',
  Apple = 'apple',
  Email = 'Username-Password-Authentication',
}

const publicPages = [
  '/auth',
  '/kyc/email/verified',
  '/sign-up',
  '/account/join',
];

export const routeRequiresAuth = (route: string): boolean => {
  const isPublic = publicPages.some((publicPage) =>
    route.startsWith(publicPage),
  );
  return !isPublic;
};

type Auth =
  | {
      ready: false;
      getAccessTokenSilently: null;
      getAccessTokenWithPopup: null;
      logout: null;
    }
  | {
      ready: true;
      getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'];
      getAccessTokenWithPopup: Auth0ContextInterface['getAccessTokenWithPopup'];
      logout: (args?: {
        redirect: boolean;
        logoutTo?: string;
      }) => PromiseLike<void>;
    };

export const auth: Auth = {
  ready: false,
  getAccessTokenSilently: null,
  getAccessTokenWithPopup: null,
  logout: null,
};

const AuthProvider = ({ children, persistor }: AuthProviderProps) => {
  const {
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    logout,
    isLoading,
    isAuthenticated,
  } = useAuth0();
  const router = useRouter();

  const [loggingOut, setLoggingOut] = useState(false);

  useEffect(() => {
    Object.assign(auth, {
      ready: true,
      getAccessTokenSilently,
      getAccessTokenWithPopup,
      logout: async (
        { redirect, logoutTo } = {
          redirect: false,
          logoutTo: '/auth',
        },
      ) => {
        if (router.basePath.startsWith('/auth')) return;
        setLoggingOut(true);
        await persistor.purge();
        rebootIntercom();

        if (redirect === true) {
          AuthRedirect.set(
            `${window.location.pathname}${window.location.search}`,
            { ttl: 60 * 15 },
          );
        } else {
          AuthRedirect.purge();
        }

        logout({
          returnTo: `${window.location.origin}${logoutTo}`,
        });
      },
    });
  }, [getAccessTokenSilently, getAccessTokenWithPopup, logout]);

  useEffect(() => {
    if (
      isLoading === false &&
      isAuthenticated === false &&
      routeRequiresAuth(router.pathname)
    ) {
      (auth as Auth).logout?.();
    }
  }, [auth, isLoading, isAuthenticated, router.pathname]);

  if (isLoading === true) {
    return null;
  }

  if (loggingOut) {
    return <FullscreenLoading message="Loading..." />;
  }

  return children;
};

export default AuthProvider;
